/*
 * @Description: 
 * @Author: 文倩
 * @Date: 2020-06-01 10:25:07
 * @LastEditTime: 2020-06-05 18:32:00
 * @LastEditors: 文倩
 */

 
import axios from 'axios';

export const GET_MENU = 'GET_MENU';
 
// 获取菜单
export const getMenuByUser = (param,history) => dispatch =>{
    axios.post('/capitallease/menuPermisson/getMenuTreeByStaff',param)
    .then(res =>{
        dispatch({
            type: GET_MENU,
            payload: res
        })
    }).catch(err =>{
         console.log('请求失败');
    })
}
