/*
 * @Description: 层级薪资
 * @Autor: 赵娟
 * @Date: 2020-07-13 14:24:33
 * @LastEditors: 赵娟
 * @LastEditTime: 2020-08-06 16:29:51
 */
import React, { Component } from "react";
import { Button } from "antd";

import Table from "../../../../components/table";
import { projectColumns } from "../../contants";

import EditProject from "./EditProject"; //编辑项目
import DownLoadTemp from "./downLoadTemp"; //下载导入模板
import ImportFile from "../../../../components/importBtn/index"; //导入按钮

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      pageNo: 1,
      pageSize: 10,
      loading: false,
      tableList: [],
    };
    this.operateColumns = [
      {
        title: "操作",
        width: 120,
        fixed: "right",
        render: (record) => {
          let { pageNo, tableList } = this.state;
          return (
            <div className="butt">
              <span className="operation-wrap">
                <EditProject formData={record} handleOk={this.queryTableList} />
              </span>
            </div>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.queryTableList();
  }

  /**
   * @description: 查询项目数据
   * @param {type}
   * @return:
   * @author: 赵娟
   */

  queryTableList = (pageNo = this.state.pageNo, pageSize = 10) => {
    const params = {
      page: pageNo,
      pageSize,
    };

    this.setState({
      loading: true,
    });
    this.$http.getProjectList(params).then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          loading: false,
          tableList: res.data.rows,
          total: res.data.total,
          pageNo,
          pageSize,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  };

  render() {
    let { tableList, loading, total, pageNo, pageSize } = this.state;

    return (
      <div className="salary-table operation-table">
        <DownLoadTemp />
        <ImportFile
          title="文件导入"
          text="文件导入"
          importFile={(val) => this.$http.importProjectFile(val)}
          importOk={this.queryTableList}
          downLoadError={(val) => this.$http.importProjectErrorFile(val)}
        />
        <Table
          rowKey="projectId"
          loading={loading}
          scroll={{ x: "100%" }}
          columns={[...projectColumns, ...this.operateColumns]}
          dataSource={tableList}
          total={total}
          current={pageNo}
          pageSize={pageSize}
          isPagination={true}
          handleChangePage={this.queryTableList}
          handleChangePageSize={this.queryTableList}
        />
      </div>
    );
  }
}

export default Project;
