
/*
 * @Description: 账号管理页面
 * @Author: 文倩
 * @Date: 2020-06-02 10:40:39
 * @LastEditTime: 2020-08-05 10:30:27
 * @LastEditor: 文倩
 */

import React, { Component } from 'react';
import { Layout } from 'antd';

import Search from '../../../components/searchForm';
import Table from '../../../components/table/index';
import { searchFormData, accountColumns } from '../contants';

import StopUse from './stopUseAccount/index';    //账户停用/启用
import Update from './updateAccount/index';      //账户修改

import './index.less';

export default class IdleAssets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total:0,
            pageNo: 1,
            pageSize: 10,
            loading: false,
            tableList: [],
            searchValue: {},  //搜索条件
            searchData: searchFormData
        }
        this.operateColumns = [{
            title: '操作',
            width: 170,
            fixed: 'right',
            render: (record) => {
                return (
                    <div className="butt">
                        <span className="operation-wrap">
                            {this.renderOptionBtn(record) || '--'}
                        </span>
                    </div>
                )
            }
        }]
    }

    componentDidMount() {
        this.queryTableList();
        this.queryRoleList();
    }

    //查询角色下拉列表
    queryRoleList = async () => {
        const res = await this.$http.getEnableRole();
        if (res && res.errorCode == 200) {
            searchFormData[1].selectData = res.data;
            this.setState({
                searchData: searchFormData
            });
        }
    }

    /**
    * @description: 根据权限渲染操作按钮
    * @param {String} 
    * @return:
    */

    renderOptionBtn = record => {
        // 定义按钮
        const stopUse =
            <StopUse
                key={`${record.staffId}key`}
                record={record}
                handleSubmit={this.queryTableList}
                {...this.props} />;
        const update =
            <Update
                key={record.staffId}
                formData={record}
                handleSubmit={this.queryTableList}
                {...this.props} />;


        let optionBtn = [];

        if(!record.staffState){
            optionBtn.push(stopUse);
        }else if(record.staffState){
            optionBtn.push(stopUse, update);
        }
        

        if (optionBtn.length == 0) {
            optionBtn.push('-');
        }
        return optionBtn;
    };

    //搜索查询列表
    queryTableList = async (pageNo = this.state.pageNo, pageSize = 10) => {
        const { searchValue } = this.state;
        const query = {
            page: pageNo,
            pageSize,
            roleIds: searchValue && searchValue.roleIds && searchValue.roleIds.split() || null,
            staffName: searchValue && searchValue.staffName || null,
        }
        
        this.setState({
            loading: true
        })

        const res = await this.$http.getAccountList(query);
        if (res && res.errorCode == 200) {
            this.setState({
                tableList: res.data.rows,
                total: res.data.total,
                pageNo,
                pageSize
            });
        }
        this.setState({
            loading: false
        })
    }

    //根据搜索条件查询列表
    querySearch = (searchValue) => {
        this.setState({
            pageNo: 1,
            searchValue
        }, () => this.queryTableList());
    }

    render() {
        const { total, pageNo, pageSize, loading, tableList, searchData } = this.state;
        const tabList = [{ value: "账号管理" }];
        return (
            <Layout>
                <div className="account">
                    <Search
                        tabList={tabList}
                        FormData={searchData}
                        handleSubmit={(values) => { this.querySearch(values) }} />
                    <Table
                        keyName="staffId"
                        columns={[...accountColumns, ...this.operateColumns]}
                        dataSource={tableList}
                        scroll={{ x: "100%" }}
                        loading={loading}
                        total={total}
                        current={pageNo}
                        pageSize={pageSize}
                        isPagination={true}
                        handleChangePage={this.queryTableList}
                        handleChangePageSize={this.queryTableList}
                    />
                </div>
            </Layout>
        );
    }
}

