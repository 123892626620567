/*
 * @Description: 接口
 * @Author: 文倩
 * @Date: 2020-07-08 16:39:04
 * @LastEditTime: 2020-07-29 11:53:19
 * @LastEditor:文倩
 */
import http from './fetch'


import login from './servers/login';             //登录接口配置
import system from './servers/system';          //系统设置接口配置
import InfoMain from './servers/infoMain';      //信息维护接口配置
import StatisticalReport from './servers/StatisticalReport';      //统计报表接口配置
import Workbench from './servers/Workbench';      //我的工作台接口配置
import ProjectList from './servers/ProjectList';      //项目看板接口配置

const baseUrl = process.env.API_URL ? `${process.env.API_URL}/bpo` : '/bpo';


export default {
    ...login,    //登录
    ...system,   //系统设置
    ...InfoMain, //信息维护
    ...StatisticalReport, //统计报表
    ...Workbench, //我的工作台接
    ...ProjectList, //项目看板


    // ---------仓库列表----
    storePageList(param) {
        return http.post(`${baseUrl}/store/storePageList`, param);
    },
    // 机型配置列表
    assetPageList(param) {
        return http.post(`${baseUrl}/asset/assetPageList`, param);
    },
    // --------------- 查询状态下拉 ------------
    /**
     * @函数描述: 查询状态下拉
     * @param {String} RETURN_WAYS 归还方式,
     * @param {String} RETURN_TYPE 归还类型,
     * @param {String} RETURN_STATUS 归还状态,
     * @param {String} INVENTORY_RESULT 盘点结果,
     * @param {String} ASSETS_STATUS 资产状态,
     * @param {String} FREE_STOCK_STATUS 易耗品状态,
     * @param {String} PROC_STATUS 流程状态,
     * @param {String} PURCHASE_APPLY_SUM 低值易耗品金额限制,
     * @return: 
     */
    queryDicCache(param) {
        return http.post(`${baseUrl}/dicCache/queryDicCache`, param);
    },
    // 多参数下拉--返回对象
    queryDicCaches(param) {
        return http.post(`${baseUrl}/dicCache/queryDicCaches`, param);
    },
    // 多参数下拉--返回list（解决顺序错乱问题）
    queryDicCacheList(param) {
        return http.post(`${baseUrl}/dicCache/queryDicCacheList`, param);
    },
    /**
     * @函数描述: 查询流程
     * @param {String} businessId 流程业务编号
     * @param {String} dicGroup 组名称 ， 值如下↓
     * @param {String} PURCHASE_APPLY 采购申请流程,
     * @param {String} RETURN_APPLY   归还申请流程,
     * @return: 
     */
    queryProcTaskDefKey(param) {
        return http.post(`${baseUrl}/dicCache/queryProcTaskDefKey`, param);
    },
    // 获取部门树 -- 一次性全部请求
    queryOrgTree(param) {
        return http.post(`${baseUrl}/org/getOrgTree`, param);
    },

    // 根据登录人获取部门树 -- 一次性全部请求
    queryOrgByUser(param) {
        return http.post(`${baseUrl}/org/queryOrgByUser`, param);
    },

    // 获取成本中心编码
    queryWBSPageList(param) {
        return http.post(`${baseUrl}/WBS/WBSPageList`, param);
    },
    // 获取WBS下拉数据
    WBSListInfo(param) {
        return http.post(`${baseUrl}/bpoWbs/listInfo`, param);
    },
    // 获取申请人下拉数据
    queryStaffList(param) {
        return http.post(`${baseUrl}/staffInfo/findStaffList`, param);
    },
    // 根据部门查人员

    findStaffPageForApplicatio(param) {
        return http.post(`${baseUrl}/staffInfo/findStaffPage`, param);
    },
    // 根据部门查启用账号
    queryStaffStatusList(param) {
        return http.post(`${baseUrl}/staffInfo/findStaffStatusList`, param);
    },
    // 获取组别下拉数据
    GroupInfoList(param) {
        return http.post(`${baseUrl}/bpoGroup/listAllInfo`, param);
    },
    // 层级薪资列表查询
    RankInfoList(param) {
        return http.post(`${baseUrl}/bpoRankSalary/listAllInfo`, param);
    },
    //城市列表查询
    CityList(param) {
        return http.post(`${baseUrl}/bpoCity/listAllInfo`, param);
    },
    //社保公积金成本
    SocialSecurityFundListAll(param) {
        return http.post(`${baseUrl}/bpoSocialSecurityFund/listAllInfo`, param);
    },
}