/*
 * @Description: 统计报表接口配置
 * @Version: 2.0
 * @Autor: 文倩
 * @Date: 2020-07-25 11:50:15
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-10 10:32:26
 */


import http from '../fetch'
const baseUrl = process.env.API_URL ? process.env.API_URL : '';

export default {
    // 获取部门筛选下拉列表 -- 一次性全部请求
    getDeptAndSub(param) {
        return http.get(`${baseUrl}/bpo/org/getDeptAndSub`, param);
    },
    
    // 项目毛利率统计
    pageGrossProfitMarginStatisticsByCondition(param, callbak) {
        return http.post(`${baseUrl}/bpo/grossProfitMarginStatistics/pageGrossProfitMarginStatisticsByCondition`, param)
    },

    // 项目毛利率统计-导出
    exportProject(param, callbak) {
        return `${baseUrl}/bpo/grossProfitMarginStatistics/exportProject`;
    },
    
    // 部门毛利率统计
    getDeparmentList(param, callbak) {
        return http.post(`${baseUrl}/bpo/grossProfitMarginStatistics/pageGrossProfitMarginStatisticsByDept`, param)
    },

    // 部门毛利率统计---导出
    exportDeparment(param, callbak) {
        return http.post(`${baseUrl}/bpo/grossProfitMarginStatistics/exportProjectDept`, param,"",'blob')
    },

    // 人员信息变动
    getPersonInfoList(param, callbak) {
        return http.post(`${baseUrl}/bpo/staffChangeLog/pageStaffChangeLogByCondition`, param)
    },

    // 人员信息变动---导出
    exportPersonInfo(param, callbak) {
        return http.post(`${baseUrl}/bpo/staffChangeLog/export`, param,"",'blob')
    },

    // 入职离职表
    getEntryList(param, callbak) {
        return http.post(`${baseUrl}/bpo/staffResigning/pageStaffResigningByCondition`, param)
    },

    // 入职离职表---导出
    exportEntry(param, callbak) {
        return http.post(`${baseUrl}/bpo/staffResigning/export`, param,"",'blob')
    },

    // 花名册列表
    getRosterList(param, callbak) {
        return http.post(`${baseUrl}/bpo/staffRoster/selectProjectStaffRoster`, param)
    },

    // 花名册列表---导出
    exportRoster(param, callbak) {
        return http.post(`${baseUrl}/bpo/staffRoster/exportRoster`, param,"",'blob')
    },

    // 月度在册人数统计列表
    getMonthResidentStatisList(param, callbak) {
        return http.post(`${baseUrl}/bpo/staffRoster/monthResidentStatis`, param)
    },

    // 月度在册人数统计---导出
    exportMonthResidentStatis(param, callbak) {
        return`${baseUrl}/bpo/staffRoster/exportMonthResidentStatis`;
    },

    // 月度在册人员花名册列表
    getMonthResidentRosterList(param, callbak) {
        return http.post(`${baseUrl}/bpo/staffRoster/monthResidentRoster`, param)
    },

    // 月度在册人员花名册---导出
    exportMonthResidentRoster(param, callbak) {
        return `${baseUrl}/bpo/staffRoster/exportMonthResidentRoster`;
    },

}


