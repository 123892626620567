/*
 * @Description:小型table组件，勿动
 * @Autor: 文倩
 * @Date: 2020-07-16 10:20:10
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-11 17:54:31
 */
import React, { Component } from "react";
import { Table, Form, Button, Pagination } from "antd";

import BaseInput from "../baseInput/index";
import NumberInput from "../baseInput/number";
import CitySelect from "../../pages/InfoMaintenance/BasicInfo/component/citySelect";

import NoData from "../noData";
import edit from "../../assets/image/editButt.png";
import "./index.less";

class TableSmall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isEdit: false, //当前是否为编辑状态
      recordForm: {}, //当前行数据
    };
  }

  /**
   * @description: 切换页数
   * @param {type}
   * @return {type}
   * @author: 文倩
   */
  onChange = (page, size) => {
    this.props.handleChangePage(page, size);
  };

  /**
   * @description: 切换每页显示数据
   * @param {type}
   * @return {type}
   * @author: 文倩
   */
  onShowSizeChange = (page, size) => {
    this.props.handleChangePageSize(page, size);
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.rowSelection &&
      this.props.rowSelection.selectedRowKeys != nextProps.rowSelection &&
      nextProps.rowSelection.selectedRowKeys
    ) {
      this.setState({
        selectedRowKeys: nextProps.rowSelection.selectedRowKeys,
      });
    }
  }

  /**
   * @description: 复选框选择change
   * @param {type}
   * @return:
   * @author: 文倩
   */
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
    this.props.rowSelection.onChange(selectedRowKeys);
  };

  /**
   * @description: 返回表格列
   * @param {type}
   * @return:
   * @author: 文倩
   */
  renderColumns = () => {
    const { columns, rowKey } = this.props;
    const { isEdit, recordForm } = this.state;

    const columnsList = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        title: col.title || null,
        dataIndex: col.dataIndex || null,
        className: col.className || null,
        width: col.width || null,
        editable: col.editable ? true : false,
        render: (text, record) => {
          if (isEdit && record[rowKey] == recordForm[rowKey]) {
            return this.handleForm(col, text, record);
          } else {
            return col.render(text, record);
          }
        },
      };
    });

    return columnsList;
  };

  /**
   * @description: 循环表单配置项
   * @param {type}
   * @return:
   * @author: 文倩
   */
  handleForm = (col, text, record) => {
    const form = this.props.form;
    let html;
    switch (col.type) {
      case "BaseInput":
        html = (
          <BaseInput
            form={form}
            allowClear
            label={null}
            field={col.dataIndex}
            required={col.required}
            message={col.message || "请输入"}
            placeholder={col.placeholder || "请输入"}
            initialValue={text}
            validatorName={col.validatorName}
            maxLength={col.maxLength}
            style={col.style}
          />
        );
        break;
      case "NumberInput":
        html = (
          <NumberInput
            form={form}
            label={null}
            field={col.dataIndex}
            required={col.required}
            placeholder={col.placeholder || "请输入"}
            validatorName={col.validatorName}
            min={col.min}
            max={record.fixedCostName == "税率" ? 9999999.9999 : col.max}
            step={col.step}
            message={
              record.fixedCostName == "税率"
                ? "长度12，包括小数点和四位小数"
                : col.message || "请输入"
            }
            initialValue={text}
          />
        );
        break;
      case "Select":
        html = (
          <CitySelect
            form={form}
            field={col.key}
            required={col.required}
            validatorName={col.validatorName}
            message={col.message || "请选择"}
            initialValue={record[col.key]}
          />
        );
        break;
      default:
        html = (
          <BaseInput
            form={form}
            allowClear
            label={null}
            field={col.dataIndex}
            required={col.required}
            message={col.message || "请输入"}
            placeholder={col.placeholder || "请输入"}
            initialValue={text}
            validatorName={col.validatorName}
            maxLength={col.maxLength}
            style={col.style}
          />
        );
        break;
    }
    return html;
  };

  /**
   * @description: 操作列返回
   * @param {type}
   * @return:
   * @author: 文倩
   */
  renderOperate = () => {
    const { rowKey, operateClassName, width } = this.props;
    const { isEdit, recordForm } = this.state;

    return {
      width: width,
      className: isEdit ? operateClassName : "",
      fixed: operateClassName != "null" ? null : "right",
      render: (record) => {
        return (
          <div className="butt">
            {isEdit && record[rowKey] === recordForm[rowKey] ? (
              <span className="operation-wrapper">
                <Button
                  type="primary"
                  onClick={() => this.handleAddSure(record)}
                >
                  确定
                </Button>
                <Button onClick={this.handleCancel}>取消</Button>
              </span>
            ) : (
              <span className="operation-wrap">
                {!isEdit && (
                  <img src={edit} alt="" onClick={() => this.isEdit(record)} />
                )}
              </span>
            )}
          </div>
        );
      },
    };
  };

  /**
   * @description: 是否编辑
   * @param {type}
   * @return:
   * @author: 文倩
   */
  isEdit = (record) => {
    this.setState({
      isEdit: true,
      recordForm: record,
    });
  };

  /**
   * @description: 取消编辑，返回非编辑状态
   * @param {type}
   * @return:
   * @author: 文倩
   */
  handleCancel = (e) => {
    this.setState({
      isEdit: false,
    });
  };

  /**
   * @description: 保存该行数据
   * @param {type}
   * @return:
   * @author: 文倩
   */
  handleAddSure = (record) => {
    const { validateFields } = this.props.form;

    validateFields((err, values) => {
      if (!err) {
        this.props.handleSubmit({ ...record, ...values }).then((res) => {
          if (res && res.errorCode == 200) {
            this.handleCancel();
            this.props.handleOk();
          }
        });
      }
    });
  };

  render() {
    const {
      rowKey,
      dataSource,
      rowSelection,
      showHeader,
      bordered,
      loading,
      className,
      isPagination,
      current,
      total,
      pageSize,
      right,
    } = this.props;
    const { selectedRowKeys, isEdit } = this.state;

    return (
      <div className={`table-small ${className}`}>
        <Table
          rowKey={(r) => r[rowKey ? rowKey : "id"]}
          showHeader={showHeader}
          loading={loading}
          pagination={false}
          bordered={bordered}
          columns={[...this.renderColumns(), this.renderOperate()]}
          dataSource={dataSource}
          rowSelection={
            rowSelection
              ? {
                  selectedRowKeys,
                  onChange: this.onSelectChange,
                }
              : null
          }
          locale={{
            emptyText: (
              <div className="noData" style={{ width: "100%" }}>
                <NoData width={200} />
              </div>
            ),
          }}
        />
        {isPagination && dataSource.length > 0 && (
          <div
            className="paginationBox"
            style={isEdit ? { paddingRight: right + "px" } : {}}
          >
            <Pagination
              // hideOnSinglePage={true}
              current={current}
              total={total}
              pageSize={pageSize}
              showQuickJumper={true}
              showSizeChanger={true}
              pageSizeOptions={["10", "20", "50"]}
              onChange={this.onChange}
              onShowSizeChange={this.onShowSizeChange}
            />
            {total > 0 && (
              <span>
                共<font>{total}</font>条记录
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Form.create()(TableSmall);
