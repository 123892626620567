/*
 * @Description: 月度在册人员统计
 * @Version: 2.0
 * @Autor: 赵娟
 * @Date: 2020-11-24 10:24:24
 */

import React, { Component } from "react";
import { Button } from "antd";
import moment from "moment";

import "../StatisticalReport.less";
import Search from "../../../components/searchForm";
import Table from "../../../components/table/index";
import ExportBtn from "../../../components/exportBtn"; // 导出
import { searchFormDataApply, columns } from "./data";
class MonthResidentStatis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      loading: false,
      visible: false,
      page: 1,
      pageSize: 10,
      searchData: searchFormDataApply,
      dataSource: [],
      type: true,
      searchValue: {},
    };
  }
  componentDidMount() {
    this.queryTableList();
    this.queryDeptAndSub();
  }
  /**
   * @description: 列表数据请求
   * @author: 赵娟
   */
  queryTableList = (
    page = this.state.page,
    pageSize = this.state.pageSize,
    type = this.state.type
  ) => {
    const { searchValue } = this.state;
    if (type) {
      const myDate = new Date();
      const tYear = myDate.getFullYear();
      const tMonth = myDate.getMonth() + 1;
      searchValue.month = tYear + "-" + tMonth;
    }
    searchValue.month =
      searchValue && searchValue.month
        ? moment(searchValue.month)
            .subtract(1, "months")
            .format("YYYY-MM")
        : null;
    const query = {
      page,
      pageSize,
      ...searchValue,
    };
    this.setState({ loading: true });
    this.$http
      .getMonthResidentStatisList(query)
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.errorCode == 200) {
          this.setState({
            dataSource: res.data.rows,
            total: res.data.total,
            page,
            pageSize,
          });
        }
      });
  };

  /**
   * @description: 部门数据请求
   * @author: 赵娟
   */

  queryDeptAndSub = async () => {
    const res = await this.$http.getDeptAndSub();
    if (res && res.errorCode == 200) {
      searchFormDataApply[1].selectData = res.data;
      this.setState({
        searchData: searchFormDataApply,
      });
    }
  };

  /**
   * @description: 搜索
   * @param {type}
   * @return {type}
   * @author: 赵娟
   */
  querySearch = (v) => {
    if (v && v.month) v.month = v.month.format("YYYY-MM");
    this.setState(
      {
        searchValue: v ? v : {},
        dataSource: [],
        page: 1,
        type: v ? false : true,
      },
      () => {
        this.queryTableList();
      }
    );
  };
  render() {
    const {
      total,
      page,
      pageSize,
      searchData,
      loading,
      dataSource,
      searchValue,
    } = this.state;
    return (
      <div className="ProjectListBox StatisticalReport">
        <div className="account">
          <div className="searchbox">
            <Search
              tabList={[{ value: "月度在册人员统计" }]}
              FormData={searchData}
              handleSubmit={(values) => {
                this.querySearch(values);
              }}
            />
          </div>

          <div className="operation-table">
            <div className="Space8">
              {this.isArray(dataSource) && (
                <ExportBtn
                  text={"导出列表"}
                  params={{ ...searchValue }}
                  url={this.$http.exportMonthResidentStatis()}
                />
              )}
            </div>
            <Table
              keyName="registeredStaffId"
              columns={columns}
              dataSource={dataSource}
              scroll={{ x: "100%" }}
              loading={loading}
              total={total}
              current={page}
              pageSize={pageSize}
              isPagination={true}
              handleChangePage={this.queryTableList}
              handleChangePageSize={this.queryTableList}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MonthResidentStatis;
