/*
 * @文档描述: 层级薪资 --- 新增
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-08-12 18:11:23
 * @最后修改人: 文倩
 */
import React, { Component, Fragment } from "react";
import { Modal, Form, Button, Select } from "antd";

import BaseInput from "../../../../../components/baseInput/index";
import NumberInput from "../../../../../components/baseInput/number";

import { baseInput4, baseInput7 } from "../../../../../utils/validate";

const FormItem = Form.Item;
const Option = Select.Option;

class AddSalary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 编辑弹框显隐
      citySelectList: [], //城市下拉数据
      serverSelectList: {}, //服务性质下拉数据
    };
  }

  /**
   * @description: 查询城市列表下拉数据
   * @param {type}
   * @return:
   * @author: 文倩
   */
  getCityNameList = () => {
    this.$http.getAllCityList().then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          citySelectList: res.data,
        });
      }
    });
  };

  /**
   * @description: 获取服务性质的下拉数据
   * @param {type}
   * @return {type}
   * @author: 文倩
   */
  getServerList = () => {
    this.$http.queryDicCache({ dicGroup: "SERVE_NATURE" }).then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          serverSelectList: res.data,
        });
      }
    });
  };

  /**
   * @函数描述: 点击显示编辑弹框
   * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
   * @return:
   */
  handlePassBtnShow = () => {
    this.setState(
      {
        visible: true,
      },
      () => {
        this.getCityNameList();
        this.getServerList();
      }
    );
  };

  /**
   * @函数描述: 取消弹框显示
   * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
   * @return: 记得重置表单呦
   */
  handlePassBtnCancle = () => {
    this.setState(
      {
        visible: false,
      },
      () => {
        this.props.form.resetFields();
      }
    );
  };
  /**
   * @函数描述: 确定编辑成功
   * @param {String} passMessage  审批意见
   * @param {String} id
   * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
   * @return: 记得重置表单呦，重新表格当前页数据
   */
  handleAddSure = (e) => {
    e.preventDefault();
    const { validateFieldsAndScroll } = this.props.form;
    const { type, formData } = this.props;

    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const query = {
          ...values,
        };
        const params = {
          rankSalaryId: formData && formData.rankSalaryId,
          ...values,
        };
        if (type == "list") {
          this.$http.updateSalary(params).then((res) => {
            if (res && res.errorCode == 200) {
              this.handlePassBtnCancle();
              this.props.handleOk();
            }
          });
        } else {
          this.$http.addSalary(query).then((res) => {
            if (res && res.errorCode == 200) {
              this.handlePassBtnCancle();
              this.props.handleOk();
            }
          });
        }
      }
    });
  };

  render() {
    const { form, type, formData } = this.props;
    const { visible, citySelectList, serverSelectList } = this.state;

    return (
      <Fragment key="passBtn">
        {type == "list" ? (
          <span onClick={this.handlePassBtnShow}>修改</span>
        ) : (
          <Button onClick={this.handlePassBtnShow} type="primary">
            新增
          </Button>
        )}
        <Modal
          className="reviseModal"
          title={type == "list" ? "修改" : "新增"}
          visible={visible}
          width={518}
          onCancel={this.handlePassBtnCancle}
          onOk={this.handleAddSure}
          maskClosable={false}
          okText="提交"
        >
          <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }}>
            <FormItem label="城市名称">
              {this.props.form.getFieldDecorator("cityId", {
                initialValue: formData && formData.cityId,
                rules: [
                  {
                    required: true,
                    message: "请选择城市名称",
                  },
                ],
              })(
                <Select
                  placeholder="请选择城市名称"
                  allowClear
                  disabled={type == "list" ? true : false}
                >
                  {citySelectList.map((res) => {
                    return <Option key={res.cityId}>{res.cityName}</Option>;
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label="服务性质">
              {this.props.form.getFieldDecorator("serveNature", {
                initialValue: formData && formData.serveNature,
                rules: [
                  {
                    required: true,
                    message: "请选择服务性质",
                  },
                ],
              })(
                <Select
                  placeholder="请选择服务性质"
                  allowClear
                  disabled={type == "list" ? true : false}
                >
                  {Object.keys(serverSelectList).map((res) => {
                    return (
                      <Option key={res}>{serverSelectList[res] || "-"}</Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <BaseInput
              form={form}
              label="层级"
              field="rank"
              required={true}
              message={"长度为10，可输入中文、数字、英文、特殊符号"}
              placeholder="请输入所属层级"
              initialValue={formData && formData.rank}
              validatorName={baseInput7}
              maxLength={10}
              disabled={type == "list" ? true : false}
            />
            <NumberInput
              form={form}
              label="采购价"
              field="purchasePrice"
              required={true}
              message={"长度为10，包括小数点和两位小数"}
              placeholder="请输入采购价"
              initialValue={formData && formData.purchasePrice}
              validatorName={baseInput4}
              min={0}
              max={9999999.99}
              step={0.01}
              maxLength={10}
            />
            <NumberInput
              form={form}
              label="薪资上限"
              field="purchaseSalaryCeil"
              required={true}
              message={"长度为10，包括小数点和两位小数"}
              placeholder="请输入薪资上限"
              initialValue={formData && formData.purchaseSalaryCeil}
              validatorName={baseInput4}
              min={0}
              max={9999999.99}
              step={0.01}
              maxLength={10}
            />
          </Form>
        </Modal>
      </Fragment>
    );
  }
}
export default Form.create()(AddSalary);
