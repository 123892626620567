/*
 * @Description: 项目转移申请
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:18:49
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-08-24 16:36:03
 */ 


import React, { Component } from 'react';
import { Button, Row, Col, Form,Modal,Spin, message } from 'antd';
import './Components.less'
import TooltipComponent from '../../../../../components/TooltipComponent'; // 文本溢出提示
import TextFieldInput from '../../../../../components/sceneInput/TextAreaInput';
import DepartmentSelect from '../../../../../components/departmentSelect'; // 部门选择框
class ProjectTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 3,
            visible:false,
            loading: false,
            pageNo: 1,
            pageSize: 10,
            isCard: false,
            searchValue: {},
            detailData: {},
            selectedRowKeys:[],
            projectInfo:{},
            btnLoading:false
        }
    }
    /**
     * @description: 项目转移申请发起
     * @author: 陈金金
     */
    handleAddSure=()=>{
        const {projectInfo}=this.state;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if(!err){
                const params={
                    // handoverBeforeGroupId:'002',
                    projectId:projectInfo.projectId,
                    ...values
                }
                this.setState({btnLoading:true})
                this.$http.addProjectAdjust({...params}).then(res=>{
                    this.setState({btnLoading:false})
                    if(res&&res.errorCode==200){
                        message.destroy();
                        message.success(res.message)
                        this.props.closeModal();
                        this.setState({visible:false});
                    }
                })
            }
        });
    }
   
    handleReturnStockBtnCancle=()=>{
        this.props.form.resetFields();
        this.setState({visible:false})
    }
       /**
   * @description: 自动回显数据
   * @return:
   */
  renderDetailKeyHTML = () => {
    const { projectInfo } = this.state;
    return [
        {name:'项目名称：',key:'projectName'},
        {name:'项目类型：',key:'projectTypeStr'},
        {name:'业务类型：',key:'businessTypeStr'}
    ].map(item => {
        return (
            <Col span={24} key={item.key}>
                <div className="detailbox">
                    <span className="label">{item.name}</span>
                    <div className="value" >
                        {
                            projectInfo[item.key] ?
                                <TooltipComponent
                                    text={projectInfo[item.key]}
                                    width={200}
                                /> : '--'
                        }
                    </div>
                </div>
            </Col>
        );
    });
};
openModal=()=>{
    const {projectId}=this.props;
    this.setState({visible:true,Loading:true},()=>{
          this.$http.queryProjectEchoById({projectId}).then(res=>{
              this.setState({Loading:false})
              if(res&&res.errorCode==200){
                  this.setState({
                    projectInfo:res.data
                  })
              }
          })  
    })
}
    render() {
        const { visible,Loading,btnLoading } = this.state;
        return (
            <>
                <Button type="primary"  onClick={()=>this.openModal()}>项目调整</Button>
               {visible&&
               <Modal
                    className="returnStockModal"
                    title="项目调整申请"
                    visible={visible}
                    width={480}
                    footer={[
                        <div  key='returnStockModal'>
                            <Button key="cancle" onClick={() => this.handleReturnStockBtnCancle()}>取消</Button>
                            <Button key="sure" onClick={this.handleAddSure} loading={btnLoading} type="primary" >提交 </Button>
                        </div>
                    ]}
                    onCancel={this.handleReturnStockBtnCancle}
                    maskClosable={false}
                    okText="提交"
                >
                    <Spin spinning={Loading}>
                    <Row>
                        {this.renderDetailKeyHTML()}
                    </Row>
                    <Form >
                    <DepartmentSelect
                        form={this.props.form}
                        label={'项目交接目标组'}
                        field={'handoverAfterGroupId'}
                        placeholder="请选择交接项目组"
                        required={true}
                    />
                    <TextFieldInput
                        form={this.props.form}
                        showLength={true}
                        label="项目调整原因说明"
                        placeholder="请输入"
                        required={true}
                        allowClear
                        field="remarks"
                        maxLength={500}
                        autoSize={{ minRows: 4, maxRows: 5 }}
                        />
                     
                    </Form>
                    </Spin>
                </Modal>
                } 
            </>
        );
    }
}

export default Form.create()(ProjectTransfer);