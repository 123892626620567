/*
 * @Description: 组织架构树
 * @Author: 文倩
 * @Date: 2020-07-02 10:40:39
 * @LastEditTime: 2020-08-24 11:32:38
 * @LastEditors: 文倩
 */

import React, { Component } from "react";
import { Layout, Tree, Icon } from "antd";

import "./index.less";

const { TreeNode } = Tree;

export default class ArchiteTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addTreeData: [], //treeData新增时，新增空数据
      isAddOrgan: false, //是否新增新组织
      selectedKeys: [],
    };
  }

  componentDidMount() {
    const { treeData, selectedKeys } = this.props;
    if (this.$common.isArray(treeData)) {
      this.setState({
        addTreeData: treeData,
        selectedKeys,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isAddOrg) {
      this.setState({
        isAddOrgan: false,
      });
    }
    if (this.props.treeData != nextProps.treeData) {
      this.setState({
        addTreeData: nextProps.treeData,
        isAddOrgan: false,
        selectedKeys: nextProps.selectedKeys,
      });
    }
  }

  //返回树节点数据
  renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            title={item.organizationName}
            key={item.organizationCode}
            icon={
              !this.state.isAddOrgan ? (
                <Icon
                  type="plus-circle"
                  className="plus"
                  onClick={(e) => this.addOrgan(e, item.organizationCode)}
                />
              ) : (
                <Icon type="plus-circle" className="plusNo" />
              )
            }
            dataRef={item}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.organizationCode}
          title={item.organizationName}
          icon={
            item.parentOrgCode == "0" ||
            (item.parentOrgCode == "001" &&
              (!this.state.isAddOrgan ? (
                <Icon
                  type="plus-circle"
                  className="plus"
                  onClick={(e) => this.addOrgan(e, item.organizationCode)}
                />
              ) : (
                <Icon type="plus-circle" className="plusNo" />
              )))
          }
          disabled={item.organizationCode == "null" ? true : false}
          {...item}
        />
      );
    });

  /**
   * @description: 点击树节点回调并且查看详情
   * @param {type}
   * @return:
   * @author: 文倩
   */
  handleClick = (selectedKeys, e) => {
    this.props.handleChangeTree(selectedKeys);
  };

  /**
   * @description: 点击添加icon
   * @param {type}
   * @return:
   * @author: 文倩
   */
  addOrgan = (e, organizationCode) => {
    e.stopPropagation();
    const { addTreeData } = this.state;
    if (this.$common.isArray(addTreeData)) {
      addTreeData.map((item) => {
        if (item.children) {
          item.children.push({
            organizationName: "组织信息",
            organizationCode: "null",
          });
        }
      });
    }
    this.setState(
      {
        addTreeData,
        isAddOrgan: true,
        selectedKeys: organizationCode,
      },
      () => {
        this.props.isAddOrganCallBack(this.state);
      }
    );
  };

  render() {
    const { addTreeData, selectedKeys } = this.state;
    return (
      <Layout>
        <div className="treeDataArch">
          <h4 style={{ marginLeft: 5 }}>
            <Icon type="apartment" />
            <font>组织架构树</font>
          </h4>
          <div className="treeContent">
            {this.$common.isArray(addTreeData) ? (
              <Tree
                defaultExpandAll
                defaultSelectedKeys={selectedKeys}
                onSelect={this.handleClick}
                showIcon
                showLine={true}
              >
                {this.renderTreeNodes(addTreeData)}
              </Tree>
            ) : (
              ""
            )}
          </div>
        </div>
      </Layout>
    );
  }
}
