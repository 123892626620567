/*
 * @文档描述: WBS --- 删除
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-08-11 11:35:37
 * @最后修改人: 文倩
 */
import React, { Component, Fragment } from 'react';
import { message, Button } from 'antd';

import PopconfirmTip from '../../../../../components/Popconfirm'; // 删除二次确认


class Remove extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDisabled: false,
            rowkeys: [],   //选中要删除的数据id
        }
    }

    componentDidMount() {
        const { rowkeys } = this.props;
        this.setState({
            rowkeys
        });
    }

    componentWillReceiveProps(nextProps){
        if(this.props.rowkeys != nextProps.rowkeys){
            this.setState({
                rowkeys:nextProps.rowkeys,
                isDisabled:false
            });
        }
    }

    /**
     * @函数描述: 删除组别
     * @param {String} 
     *
     */
    handleRevoke = () => {
        const { rowkeys } = this.state;
        const query = {
            wbsIds: rowkeys
        }

        this.$http.removeWBS(query).then(res => {
            if (res && res.errorCode == 200) {
                this.setState({
                    isDisabled: true
                });
                message.destroy();
                message.success(res.message);
                this.props.handleOk();
            }
        })
    };
    render() {
        const { isDisabled, rowkeys } = this.state;

        return (
            <Fragment>
                {!this.$common.isArray(rowkeys) || isDisabled ?
                    <Button disabled>删除</Button>
                    :
                    <PopconfirmTip
                        key="revokeBtn"
                        title={`确定要删除该条数据吗？`}
                        onConfirm={this.handleRevoke}
                    >
                        <Button>删除</Button>
                    </PopconfirmTip>
                }
            </Fragment>
        );
    }
}
export default Remove;
