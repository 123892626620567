/*
 * @Description: 人员信息变动数据
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-17 10:40:26
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-11 10:33:48
 */ 

import React from 'react';
import TooltipComponent from '../../../components/TooltipComponent'; // 文本溢出提示

import {baseInput1,baseInput7} from '../../../utils/validate';

/**
 * @description: 搜索配置项
 * @param {type} 
 * @return {type} 
 * @author: 文倩
 */
const calssNameList = [];

export const searchFormDataApply =(res)=> [ {
    label: '统计时间',
    name: 'month',
    type: 'rangeTime',
    placeholder:['开始时间','结束时间']
}, {
    label: '工号',
    name: 'staffCode',
    type: 'input',
    validatorName:baseInput1,
    placeholder:"请输入员工工号",
    maxLength:18
}, {
    label: '姓名',
    name: 'staffName',
    type: 'input',
    validatorName:baseInput7,
    placeholder:"请输入员工姓名",
    maxLength:18
}]


/**
 * @description: 列表表头配置项
 * @param {type} 
 * @return {type} 
 * @author: 文倩
 */
export const columns = [ {
    title: '序号',
    width:60,
    fixed:"left",
    className:'',
    render:(text,record,index)=> <div style={{height:record.height, verticalAlign: 'middle', lineHeight:record.height}}>{index+1}</div>,
  }, {
    title: '工号',
    dataIndex: 'staffCode',
    key: 'staffCode',
    width:160,
    fixed:"left",
    className:"tableStaff",
    render:t=>t?<TooltipComponent width={130}  text={t}/>:'-'
},
{
    title: '姓名',
    key: 'staffName',
    dataIndex: 'staffName',
    width:150,
    render:t=>t?<TooltipComponent width={140}  text={t}/>:'-', 
}, {
    title: '当前所属项目',
    key: 'projectName',
    dataIndex: 'projectName',
    width:160,
    render:t=>t?<TooltipComponent width={150}  text={t}/>:'-',
}, {
    title: '变更类型',
    key: 'changeName',
    dataIndex: 'changeName',
    width:130,
    render:t=>t?<TooltipComponent width={120} text={t}/>:'-',
}, {
    title: '细项',
    key: 'procName',
    dataIndex: 'procName',
    width:160,
    render:t=>t?<TooltipComponent width={150}  text={t}/>:'-',
},
{
    title: '调整内容',
    key: 'fieldValue',
    dataIndex: 'fieldValue',
    width:160,
    render:t=>t?<TooltipComponent width={150}  text={t}/>:'-',
},
{
    title: '变更前数值',
    key: 'adjustBefore',
    dataIndex: 'adjustBefore',
    width:200,
    render: (text,record,index)=>record.adjustBefore?<TooltipComponent width={190} isLine={record.line > 1 ? true : false} text={record.adjustBefore}/>:'-' ,
},
{
    title: '变更后数值',
    key: 'adjustAfter',
    dataIndex: 'adjustAfter',
    width:200,
    render:(text,record,index)=>record.adjustAfter?<TooltipComponent width={190} isLine={record.line > 1 ? true : false} text={record.adjustAfter}/>:'-',
},{
    title: '变更原因说明',
    key: 'remarks',
    dataIndex: 'remarks',
    width:140,
    render:t=>t?<TooltipComponent width={130}  text={t}/>:'-',
}, {
    title: '生效时间',
    key: 'effectiveTime',
    dataIndex: 'effectiveTime',
    width:160,
    render:t=>t?<TooltipComponent width={150}  text={t}/>:'-',
},{
    title: '审批意见1',
    key: 'operDescription1',
    dataIndex: 'operDescription1',
    width:140,
    render:t=>t?<TooltipComponent width={130}  text={t}/>:'-',
},{
    title: '审批人1&时间',
    key: 'operUser1',
    dataIndex: 'operUser1',
    width:190,
    render:(text,record,index)=> record.operDescription1?<TooltipComponent width={180}  text={record.operUser1+' & '+record.operTime1}/>:'-',
},{
    title: '审批意见2',
    key: 'operDescription2',
    dataIndex: 'operDescription2',
    width:140,
    render:t=>t?<TooltipComponent width={130}  text={t}/>:'-',
},{
    title: '审批人2&时间',
    key: 'operUser2',
    dataIndex: 'operUser2',
    width:190,
    render:(text,record,index)=> record.operDescription2?<TooltipComponent width={180}  text={record.operUser2+' & '+record.operTime2}/>:'-',
},{
    title: '审批意见3',
    key: 'operDescription3',
    dataIndex: 'operDescription3',
    width:140,
    render:t=>t?<TooltipComponent width={130}  text={t}/>:'-',
},{
    title: '审批人3&时间',
    key: 'operUser3',
    dataIndex: 'operUser3',
    width:190,
    render:(text,record,index)=> record.operDescription3?<TooltipComponent width={180}  text={record.operUser3+' & '+record.operTime3}/>:'-',
}]
