
/*
 * @Description: 角色管理页面
 * @Author: 文倩
 * @Date: 2020-06-02 10:40:39
 * @LastEditTime: 2020-08-11 10:10:32
 * @LastEditor:文倩 
 */

import React, { Component } from 'react';
import { Layout } from 'antd';

import Search from '../../../components/searchForm';
import Table from '../../../components/table/index';
import api from '../../../api/servers/system';
import { roleColumns, searchRoleFormData } from '../contants';

import AddRole from './addRole/index';   //添加/修改角色
import RemoveRole from './removeRole/index';  //删除角色
import './index.less';

export default class IdleAssets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            pageNo: 1,
            pageSize: 10,
            loading: false,
            tableList: [],
            searchValue: {}
        }
        this.operateColumns = [{
            title: '操作',
            width: 170,
            fixed: "right",
            render: (record) => {
                return (
                    <div className="butt">
                        <span className="operation-wrap">
                            {!record.isSuperAdmin ? this.renderOptionBtn(record) : "-"}
                        </span>
                    </div>
                )
            }
        }]
    }

    componentDidMount() {
        this.queryTableList();
    }


    /**
    * @description: 根据权限渲染操作按钮
    * @param {String} 
    * @return:
    */

    renderOptionBtn = record => {
        const { pageNo, tableList } = this.state;
        // 定义按钮
        const stopUse =
            <RemoveRole
                key={record.roleId}
                text={record.roleStatus ? "停用" : "启用"}
                record={record}
                type="enadle"
                handleSubmit={this.queryTableList}
            />;

        const update =
            <AddRole
                key={`${record.roleId}key`}
                type='list'
                title='修改'
                formData={record}
                handleSubmit={this.queryTableList} />;
        const remove =
            <RemoveRole
                key={`${record.roleId}remove`}
                text="删除"
                type="remove"
                record={record}
                handleSubmit={() => {
                    if (pageNo > 1 && tableList.length === 1) {
                        pageNo = pageNo - 1;
                    }
                    this.queryTableList(pageNo);
                }} />;


        let optionBtn = [];

        if (record.roleStatus) {
            optionBtn.push(stopUse, update);
        } else if (!record.roleStatus) {
            optionBtn.push(stopUse, remove);
        }



        if (optionBtn.length == 0) {
            optionBtn.push('-');
        }

        return optionBtn;
    };

    //搜索查询列表
    queryTableList = async (pageNo = this.state.pageNo, pageSize = 10) => {
        const { searchValue } = this.state;
        const query = {
            page: pageNo,
            pageSize,
            ...searchValue
        }
        this.setState({
            loading: true
        })
        const res = await api.getRoleList(query);
        if (res && res.errorCode == 200) {
            this.setState({
                tableList: res.data.rows,
                total: res.data.total,
                pageNo,
                pageSize
            });
        }
        this.setState({
            loading: false
        })

    }

    //根据搜索条件查询列表
    querySearch = (searchValue) => {
        this.setState({
            pageNo: 1,
            searchValue
        }, () => this.queryTableList());
    }

    render() {
        const { total, pageNo,pageSize, loading, tableList } = this.state;

        return (
            <Layout>
                <div className="roleTable">
                    <Search
                        FormData={searchRoleFormData}
                        handleSubmit={(values) => this.querySearch(values)} />
                    <div className="operation-table">
                        <div className="button">
                            <AddRole title='新增角色'
                                handleSubmit={this.queryTableList} />
                        </div>
                        <Table
                            keyName="roleId"
                            columns={[...roleColumns, ...this.operateColumns]}
                            dataSource={tableList}
                            scroll={{ x: "100%" }}
                            loading={loading}
                            total={total}
                            current={pageNo}
                            pageSize={pageSize}
                            isPagination={true}
                            handleChangePageSize={this.queryTableList}
                            handleChangePage={this.queryTableList}
                        />
                    </div>
                </div>
            </Layout>
        );
    }
}

