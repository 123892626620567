/*
 * @Description: 项目信息调整
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 11:40:59
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-08-24 17:19:32
 */

import React, { Component } from "react";
import { Button, Spin, Form, Modal, message } from "antd";
import BaseInput from "../../../../../components/baseInput";
import InputNumber from "../../../../../components/baseInput/number";
import {
  baseInput4,
  baseInput7,
  _isEmoji,
  baseInput1,
} from "../../../../../utils/validate.js";
import StatusSelect from "../../../../../components/statusSelect"; //导入枚举类型下拉框
import BaseInput2 from "../../../../../components/baseInput2";

class ProjectInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 3,
      visible: false,
      loading: false,
      pageNo: 1,
      pageSize: 10,
      isCard: false,
      searchValue: {},
      detailData: {},
      selectedRowKeys: [],
      projectInfo: {},
      btnLoading: false,
    };
  }
  /**
   * @description: 人员搜索
   * @author: 陈金金
   */
  handleAddSure = () => {
    const { projectId } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const param = {
          projectId,
          ...values,
        };
        this.setState({ btnLoading: true });
        this.$http.updateProjectInfo(param).then((res) => {
          this.setState({ btnLoading: false });
          if (res && res.errorCode == 200) {
            message.destroy();
            message.success(res.message);
            this.props.closeModal();
            this.setState({
              visible: false,
            });
          }
        });
      }
    });
  };

  handleReturnStockBtnCancle = () => {
    this.props.form.resetFields();
    this.setState({ visible: false });
  };
  openModal = () => {
    const { projectId } = this.props;
    this.setState({ visible: true, Loading: true }, () => {
      this.$http.queryProjectEchoById({ projectId }).then((res) => {
        this.setState({ Loading: false });
        if (res && res.errorCode == 200) {
          this.setState({
            projectInfo: res.data,
          });
        }
      });
    });
  };
  render() {
    const { visible, Loading, projectInfo, btnLoading } = this.state;
    const { form } = this.props;
    return (
      <>
        <Button type="primary" onClick={() => this.openModal()}>
          项目信息调整
        </Button>
        {visible && (
          <Modal
            className="returnStockModal"
            title="项目信息调整"
            visible={visible}
            width={480}
            onCancel={this.handleReturnStockBtnCancle}
            footer={[
              <div key="returnStockModal">
                <Button
                  key="cancle"
                  onClick={() => this.handleReturnStockBtnCancle()}
                >
                  取消
                </Button>
                <Button
                  key="sure"
                  onClick={this.handleAddSure}
                  loading={btnLoading}
                  type="primary"
                >
                  提交{" "}
                </Button>
              </div>,
            ]}
            maskClosable={false}
            okText="提交"
          >
            <Spin spinning={Loading}>
              <Form>
                {/* <BaseInput
                  form={form}
                  label="项目名称"
                  required={true}
                  initialValue={projectInfo && projectInfo.projectName}
                  placeholder="请输入"
                  validatorName={baseInput7}
                  field="projectName"
                  maxLength={30}
                  message="请输入中文、字母或数字。"
                /> */}
                <BaseInput2
                  form={form}
                  label="项目名称"
                  required={true}
                  initialValue={projectInfo && projectInfo.projectName}
                  placeholder="请输入"
                  validatorName={_isEmoji}
                  field="projectName"
                  maxLength={30}
                  message="长度为30，可输入中文、数字、英文、特殊符号"
                />
                <StatusSelect
                  form={form}
                  label="项目类型"
                  dicGroup="PROJECT_TYPE"
                  initialValue={projectInfo && projectInfo.projectType}
                  placeholder="请输入"
                  field="projectType"
                />
                <StatusSelect
                  form={form}
                  label="业务类型"
                  dicGroup="BUSINESS_TYPE"
                  initialValue={projectInfo && projectInfo.businessType}
                  placeholder="请输入"
                  field="businessType"
                />

                <InputNumber
                  form={form}
                  label="当月核准后项目收入"
                  // required={true}
                  initialValue={projectInfo && projectInfo.approvalIncome}
                  allowClear
                  placeholder="请输入"
                  validatorName={baseInput4}
                  field="approvalIncome"
                  // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  maxLength={10}
                  // min={0}
                  step={0.01}
                  max={9999999.99}
                  style={{ width: 432 }}
                  message="请输入数字,最多保留两位小数"
                />
                <InputNumber
                  form={form}
                  label="当月核准后项目成本"
                  // required={true}
                  initialValue={projectInfo && projectInfo.approvalCost}
                  allowClear
                  // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder="请输入"
                  validatorName={baseInput4}
                  field="approvalCost"
                  maxLength={10}
                  // min={0}
                  step={0.01}
                  max={9999999.99}
                  style={{ width: 432 }}
                  message="请输入数字,最多保留两位小数"
                />
              </Form>
            </Spin>
          </Modal>
        )}
      </>
    );
  }
}

export default Form.create()(ProjectInfo);
