/*
 * @Author: 陈金金
 * @Date: 2020-07-14 18:32:55
 * @LastEditTime: 2021-02-20 14:31:25
 * @LastEditors: 陈金金
 * @Description: 项目看板
 * @FilePath: \BPO\src\pages\workbench\index.jsx
 */
import React, { Component } from "react";
import { Button, Icon, Row, Col, Pagination, Spin } from "antd";
import "./index.less";
import Search from "../../components/searchForm";
import NoData from "../../components/noData";
import Table from "../../components/table/index";
import { searchFormDataApply, columns } from "./data";
import TooltipComponent from "../../components/TooltipComponent";
import ExportBtn from "../../components/exportBtn"; // 导出
import CreateProject from "./Components/CreateProject.jsx";
import ListIcon from "../../assets/image/ListIcon.png";
import CardIconCur from "../../assets/image/CardIconCur.png";
import ListIconCur from "../../assets/image/ListIconCur.png";
import CardIcon from "../../assets/image/CardIcon.png";
import { isArray } from "../../utils/common";
class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      dataCardList: [],
      tableList: [],
      loading: false,
      visible: false,
      page: 1,
      pageSize: 10,
      isCard: true,
      searchData: searchFormDataApply(),
      searchValue: {},
      sortParam: { projectIncomeOrder: "DESC" }, //项目收入倒序
    };
  }
  componentDidMount() {
    this.setIsCard();
  }
  /**
   * @description: 判断本地是否缓存卡片状态
   * @param {type}
   * @return {type}
   * @author: 陈金金
   */
  setIsCard = () => {
    this.setState(
      {
        isCard: this.$utils.get("isCard") ? false : true,
      },
      () => {
        this.queryTableList();
      }
    );
  };
  /**
   * @description: 列表卡片切换同时状态缓存本地，保留之前的状态
   * @param {type}
   * @return {type}
   * @author: 陈金金
   */
  setShowCard = (status) => {
    const { sortParam } = this.state;
    this.setState(
      {
        isCard: status,
        sortParam:
          Object.keys(sortParam).length > 0
            ? sortParam
            : { projectIncomeOrder: "DESC" },
        pageNo: 1,
        pageSize: 10,
      },
      () => {
        this.$utils.set("isCard", !status);
        this.queryTableList();
      }
    );
  };
  /**
   * @description: 列表数据请求
   * @author: 陈金金
   */
  queryTableList = (page = this.state.page, pageSize = this.state.pageSize) => {
    const { searchValue, sortParam } = this.state;
    this.setState({ loading: true });
    this.$http
      .pageProjectInfoByCondition({
        page,
        pageSize,
        ...searchValue,
        ...sortParam,
      })
      .then((res) => {
        console.log("人员列表 --->", res);
        this.setState({ loading: false });
        if (res && res.errorCode == 200) {
          this.setState({
            tableList: res.data.rows,
            total: res.data.total,
            page,
            pageSize,
          });
        }
      });
  };
  // 搜索
  querySearch = (v = {}) => {
    v.keyWord = v && v.staff;
    v && v.staff && delete v.staff;
    this.setState(
      {
        searchValue: v,
        tableList: [],
        page: 1,
      },
      () => {
        this.queryTableList();
      }
    );
  };
  // 判断箭头方向
  checkNum = (num) => {
    let arrow = "";
    switch (this.$common.isPositiveNum(num)) {
      case true:
        arrow = "tTOP";
        break;
      case false:
        arrow = "tBottom";
        break;
      default:
        arrow = "";
        break;
    }
    return arrow;
  };
  dataCard = (data) => {
    return (
      <div className="dataCard">
        <Row gutter={10}>
          <Col span={8}>
            <div className="cardContent">
              <div className="num">
                <TooltipComponent
                  width={"100%"}
                  placement="top"
                  text={this.$common.isPositiveIncome(data.projectIncome)}
                />
              </div>
              <div className="cardtype">
                项目收入
                <span className={this.checkNum(data.projectIncomePer)}>
                  {data.projectIncomePer == 0
                    ? " - "
                    // : Math.abs(data.projectIncomePer) + "%"}
                    :(Math.abs(data.projectIncomePer)*100).toFixed(2) + "%"}
                  <i
                    className="tLine"
                    style={{
                      bottom:
                        this.checkNum(data.projectIncomePer) == "tBottom"
                          ? "5px"
                          : "3px",
                    }}
                  ></i>
                </span>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="cardContent">
              <div className="num">
                <TooltipComponent
                  width={"100%"}
                  placement="top"
                  text={this.$common.isPositiveIncome(data.perCapitaCost)}
                />
              </div>
              <div className="cardtype">
                人均成本
                <span className={this.checkNum(data.perCapitaCostPer)}>
                  {data.perCapitaCostPer == 0
                    ? " - "
                    // : Math.abs(data.perCapitaCostPer) + "%"}
                    :(Math.abs(data.perCapitaCostPer)*100).toFixed(2) + "%"}
                  <i
                    className="tLine"
                    style={{
                      bottom:
                        this.checkNum(data.perCapitaCostPer) == "tBottom"
                          ? "5px"
                          : "3px",
                    }}
                  ></i>
                </span>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="cardContent">
              <div className="num">
                <TooltipComponent
                  width={"100%"}
                  placement="top"
                  text={this.$common.isPositiveIncome(data.grossProfit)}
                />
              </div>
              <div className="cardtype">
                项目毛利
                <span className={this.checkNum(data.grossProfitPer)}>
                  {data.grossProfitPer == 0
                    ? " - "
                    // : Math.abs(data.grossProfitPer) + "%"}
                    :(Math.abs(data.grossProfitPer)*100).toFixed(2) + "%"}
                  <i
                    className="tLine"
                    style={{
                      bottom:
                        this.checkNum(data.grossProfitPer) == "tBottom"
                          ? "5px"
                          : "3px",
                    }}
                  ></i>
                </span>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="cardContent">
              <div className="num">
                <TooltipComponent
                  width={"100%"}
                  placement="top"
                  text={data.projectPeopleNum}
                />
              </div>
              <div className="cardtype">
                项目人数
                <span className={this.checkNum(data.projectPeoplePer)}>
                  {data.projectPeoplePer == 0
                    ? " - "
                    // : Math.abs(data.projectPeoplePer) + "%"}
                    :(Math.abs(data.projectPeoplePer)*100).toFixed(2)+'%'}
                  <i
                    className="tLine"
                    style={{
                      bottom:
                        this.checkNum(data.projectPeoplePer) == "tBottom"
                          ? "5px"
                          : "3px",
                    }}
                  ></i>
                </span>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="cardContent">
              <div className="num">
                <TooltipComponent
                  width={"100%"}
                  placement="top"
                  text={
                    data.grossProfitMargin == 0
                      ? 0 + "%"
                      // : (Math.abs(data.grossProfitMargin)*100).toFixed(2)+ "%"
                      : this.$common.accMul(Math.abs(data.grossProfitMargin), 1) + "%"
                      
                  }
                />
              </div>
              <div className="cardtype">
                毛利率
                <span className={this.checkNum(data.grossProfitMarginPer)}>
                  {data.grossProfitMarginPer == 0
                    ? " - "
                     : (Math.abs(data.grossProfitMarginPer)*100).toFixed(2) + "%"}
                  <i
                    className="tLine"
                    style={{
                      bottom:
                        this.checkNum(data.grossProfitMarginPer) == "tBottom"
                          ? "5px"
                          : "3px",
                    }}
                  ></i>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  onChange = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.queryTableList();
      }
    );
  };
  renderCardHtml = () => {
    const { total, tableList, page, pageSize, loading } = this.state;
    return (
      <div
        className="renderCardHtml"
        style={{ marginBottom: !isArray(tableList) && "20px" }}
      >
        <Spin spinning={loading}>
          {isArray(tableList) ? (
            <Row gutter={20}>
              {tableList.map((v) => {
                return (
                  <Col span={12} key={v.projectId}>
                    <div className="card pb24">
                      <div className="pjtop">
                        <div>
                          <TooltipComponent text={v.projectName} width={400} />
                        </div>
                        <div>
                          <span
                            className="eye"
                            onClick={() =>
                              this.props.history.push(
                                "/Project/List/projectDetail?projectId=" +
                                  v.projectId
                              )
                            }
                          >
                            <Icon
                              type="eye"
                              theme="filled"
                              style={{ color: "#32C5FF" }}
                            />
                          </span>
                          <span
                            onClick={() =>
                              this.props.history.push(
                                "/Project/List/projectEdit?projectId=" +
                                  v.projectId
                              )
                            }
                          >
                            <Icon
                              type="edit"
                              theme="filled"
                              style={{ color: "#32C5FF" }}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="pjTitle">
                        {v.projectTypeStr + " | "} {v.businessTypeStr + " | "}{" "}
                        {v.organizationName}
                      </div>
                      <div className="pjCard">{this.dataCard(v)}</div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <NoData width={200} />
          )}
        </Spin>
        {total > 0 && (
          <div className="pagebox">
            <Pagination
              showSizeChanger
              showQuickJumper
              pageSize={pageSize}
              pageSizeOptions={["10", "20", "50"]}
              current={page}
              total={total}
              onChange={(p, s) => this.onChange(p, s)}
              onShowSizeChange={(p, s) => this.onChange(p, s)}
            />
            <div className="pagetotal">
              共<span>{total}</span>条数据
            </div>
          </div>
        )}
      </div>
    );
  };
  openModal = () => {
    this.setState({
      visible: true,
    });
  };
  closeModal = (e) => {
    if (e) this.queryTableList();
    this.setState({
      visible: false,
    });
  };
  // 表格操作
  action = () => {
    return {
      title: "操作",
      width: 160,
      key: "operation",
      fixed: "right",
      render: (t, r, i) => {
        return (
          <span className="operation-wrap">
            <span
              onClick={() =>
                this.props.history.push(
                  "/Project/List/projectDetail?projectId=" + r.projectId
                )
              }
            >
              查看
            </span>
            <span
              onClick={() =>
                this.props.history.push(
                  "/Project/List/projectEdit?projectId=" + r.projectId
                )
              }
            >
              编辑
            </span>
          </span>
        );
      },
    };
  };
  /**
   * @description: 排序
   * @param {type}   1.ascend 正序 2.descend 倒序
   * @author: 陈金金
   */
  handleChange = (p, f, s) => {
    if (s && s.order && s.order == "ascend") s.order = "ASC";
    if (s && s.order && s.order == "descend") s.order = "DESC";
    const obj = {};
    obj[
      `${s.field === "organizationName" ? "organizationNameOrder" : s.field}`
    ] = s.order;
    this.setState(
      {
        sortParam: obj,
      },
      () => {
        this.queryTableList();
      }
    );
  };
  render() {
    const {
      total,
      page,
      pageSize,
      sortParam,
      searchData,
      loading,
      isCard,
      visible,
      tableList,
      searchValue,
    } = this.state;
    return (
      <div className="ProjectListBox">
        <div className="account">
          <div className="searchbox">
            <Search
              tabList={[{ value: "项目看板" }]}
              FormData={searchData}
              handleSubmit={(values) => {
                this.querySearch(values);
              }}
            />
            <div className="switchIcon">
              <img
                src={isCard ? ListIcon : ListIconCur}
                alt=""
                onClick={() => this.setShowCard(false)}
              />
              <img
                src={isCard ? CardIconCur : CardIcon}
                alt=""
                onClick={() => this.setShowCard(true)}
              />
            </div>
          </div>

          <div className="table" style={{ paddingBottom: isCard ? 0 : "" }}>
            <div className="Space8">
              <Button type="primary" onClick={() => this.openModal()}>
                新建项目
              </Button>
              {this.isArray(tableList) && (
                <ExportBtn
                  text={"导出列表"}
                  className="btnColor"
                  params={{ ...searchValue, ...sortParam }}
                  url={this.$http.exportProjectInfoByCondition()}
                />
              )}
            </div>
            {isCard ? (
              this.renderCardHtml()
            ) : (
              <Table
                keyName={"projectId"}
                columns={[...columns, this.action()]}
                dataSource={tableList}
                scroll={{ x: "100%" }}
                loading={loading}
                total={total}
                current={page}
                pageSize={pageSize}
                isPagination={true}
                onChange={(p, f, s, e) => this.handleChange(p, f, s, e)}
                handleChangePage={this.queryTableList}
                handleChangePageSize={this.queryTableList}
              />
            )}
          </div>
        </div>
        {visible && (
          <CreateProject
            visible={visible}
            closeModal={(e) => this.closeModal(e)}
          />
        )}
      </div>
    );
  }
}

export default ProjectList;
