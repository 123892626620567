/*
 * @Description: 组织架构页面
 * @Author: 文倩
 * @Date: 2020-06-02 10:40:39
 * @LastEditTime: 2020-08-04 17:12:38
 * @LastEditors: 文倩
 */

import React, { Component } from "react";
import { Layout, Spin } from "antd";
import _ from "lodash";

import Tree from "./component/ArchiteTree"; //架构树
import OrgInfo from "./component/OrganContent"; //组织信息

import NoData from "../../../components/noData";

import "./index.less";

export default class Architecture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      loading: false,
      isAddOrg: false, //是否新增组织信息
      selectedKeys: ["001"], //点击的树节点id
      orgInfo: {}, //组织信息详情
    };
  }

  componentDidMount() {
    this.getOrganization();
  }

  /**
   * @description: 查询树节点数据
   * @param {type}
   * @return:
   * @author: 文倩
   */
  getOrganization = () => {
    this.setState({
      loading: true,
    });
    this.$http.getOrgTree().then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          treeData: res.data,
          loading: false,
          isAddOrg: false,
        });
      } else {
        this.setState({
          loading: false,
          isAddOrg: false,
        });
      }
      console.log("选中树节点回调---->", this.setState);
      this.getDetail();
    });
  };

  /**
   * @description: 根据部门code查询详情
   * @param {type}
   * @return:
   * @author: 文倩
   */
  getDetail = () => {
    const { selectedKeys } = this.state;
    console.log("selectedKeys-------------------->", selectedKeys);
    const query = {
      organizationCode: this.$common.isArray(selectedKeys)
        ? _.head(selectedKeys)
        : selectedKeys,
    };
    if (selectedKeys.length > 0) {
      this.$http.getOrgTreeDetail(query).then((res) => {
        if (res && res.errorCode == 200) {
          this.setState({
            orgInfo: res.data,
          });
        }
      });
    }
  };

  /**
   * @description: 点击新增icon后的回调
   * @param {type}
   * @return:
   * @author: 文倩
   */
  handleAddSure = (data) => {
    this.setState({
      isAddOrg: data.isAddOrgan,
      selectedKeys: data.selectedKeys,
    });
  };

  /**
   * @description: 选中树节点回调
   * @param {type}
   * @return:
   * @author: 文倩
   */
  handleChangeTree = (selectedKeys) => {
    this.setState(
      {
        selectedKeys,
        isAddOrg: false,
      },
      () => {
        console.log("选中树节点回调---->", selectedKeys);
        this.getDetail();
      }
    );
  };

  /**
   * @description: 删除成功后的回调
   * @param {type}
   * @return {type} * @author: 文倩
   */
  handleRemove = () => {
    this.setState(
      {
        selectedKeys: ["001"],
      },
      () => {
        this.getOrganization();
      }
    );
  };

  /**
   * @description: 添加时点击取消回调
   * @param {type}
   * @return {type}
   * @author: 文倩
   */
  handleCancel = (data) => {
    this.getOrganization();
  };

  render() {
    const { treeData, loading, isAddOrg, orgInfo, selectedKeys } = this.state;

    return (
      <Layout>
        <div className="card treeData">
          <Spin spinning={loading} />
          {this.$common.isArray(treeData) && (
            <div className="tree-content">
              <h3>组织架构管理</h3>
              <div className="content">
                <div className="tree">
                  <Tree
                    key="organizationCode"
                    selectedKeys={selectedKeys}
                    treeData={treeData}
                    isAddOrg={isAddOrg}
                    handleChangeTree={this.handleChangeTree}
                    isAddOrganCallBack={(data) => this.handleAddSure(data)}
                  />
                </div>
                <div className="orgInfo">
                  <OrgInfo
                    isAddorg={isAddOrg}
                    detailCon={orgInfo}
                    selectedKeys={selectedKeys}
                    handleCancel={this.handleCancel}
                    handleRemove={this.handleRemove}
                    handleOk={this.getOrganization}
                  />
                </div>
              </div>
            </div>
          )}
          {!this.$common.isArray(treeData) ? <NoData width={200} /> : ""}
        </div>
      </Layout>
    );
  }
}
