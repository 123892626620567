/*
 * @Description: 列表气泡组件，溢出省略
 * @Author: 金金
 * @Date: 2020-06-01 10:15:00
 * @LastEditTime: 2020-10-22 11:43:18
 * @LastEditors: 陈金金
 */

/**
 * @description:
 * @param {Number} props.width
 * @param {String} props.text
 * @return:
 */

import React from "react";
import { Tooltip } from "antd";
export default (props) => {
  const { placement, line } = props;
  return (
    <Tooltip
      placement={placement ? placement : "topLeft"}
      title={
        props.isLine
          ? (props.text || []).map((item) => {
              return <div>{item}</div>;
            })
          : props.text
      }
      overlayClassName="value-tooltip"
      // style={{width:props.width}}
    >
      <div
        className={line ? "MultiLine" : null}
        style={
          !line
            ? {
                maxWidth: props.maxWidth || props.width,
                width: props.width && props.width,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }
            : {
                width: props.width || "400",
                WebkitBoxOrient: "vertical",
              }
        }
      >
        {props.isLine
          ? (props.text || []).map((item) => {
              return <div>{item}</div>;
            })
          : props.text}
      </div>
    </Tooltip>
  );
};
