/*
 * @文档描述: 角色管理 --- 新增
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-08-11 11:23:13
 * @最后修改人: 文倩
 */
import React, { Component, Fragment } from 'react';
import { Modal, Form, Button, message, TreeSelect } from 'antd';
import _ from 'lodash';

import BaseInput from '../../../../components/baseInput/index';


import { baseInput7 } from '../../../../utils/validate';
import './index.less';

const FormItem = Form.Item;
const { TreeNode } = TreeSelect;

class AddRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false, // 编辑弹框显隐
            powerSelectList: []  //权限下拉数据
        };
    }

    /**
     * @函数描述: 查询权限的下拉数据
     * @return:
     */
    getPowerList = async () => {
        const res = await this.$http.getPowerList();
        if (res && res.errorCode == 200) {
            this.setState({
                powerSelectList: res.data
            });
        }
    }
    /**
     * @函数描述: 点击显示编辑弹框
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return:
     */
    handlePassBtnShow = () => {
        this.setState({
            visible: true
        }, () => {
            this.getPowerList();
        });
    };
    /**
     * @函数描述: 取消弹框显示
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦
     */
    handlePassBtnCancle = () => {
        this.setState(
            {
                visible: false
            },
            () => {
                this.props.form.resetFields();
            }
        );
    };
    /**
     * @函数描述: 确定编辑成功
     * @param {String} passMessage  审批意见
     * @param {String} id
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦，重新表格当前页数据
     */
    handleAddSure = e => {
        e.preventDefault();
        const { validateFieldsAndScroll } = this.props.form;
        const { formData, type } = this.props;
        const { powerSelectList } = this.state;


        validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                
                let arr = [];
                let list = [];
                if (values.permissionIds && values.permissionIds.length > 0) {
                    values.permissionIds.map(v => {
                        return this.getParentId(powerSelectList, v, arr);
                    })
                }
                arr.map(i => {
                    list.push(i.split('-'));
                })
                const query = {
                    roleName: values && values.roleName,
                    permissionIds: _.uniq(_.flattenDeep(list))
                    //  values && values.permissionIds
                }
                const param = {
                    roleId: formData && formData.roleId,
                    roleName: values && values.roleName,
                    permissionIds:  _.uniq(_.flattenDeep(list))
                    // values && values.permissionIds
                }

                let res;
                if (type && type == 'list') {
                    res = await this.$http.updateRole(param);
                } else {
                    res = await this.$http.addRole(query);
                }
                if (res && res.errorCode == 200) {
                    message.destroy();
                    message.success(res.message);
                    this.handlePassBtnCancle();
                    this.props.handleSubmit();
                }
            }
        });
    };


    //整理选中权限的父级id
    getParentId = (list, v, arr) => {
        if (list.length > 0) {
            list.map(k => {
                if (v == k.permissionId) {
                    arr.push(k.permissionPath)
                } else {
                    if (k.nextLevel && k.nextLevel.length > 0) {
                        this.getParentId(k.nextLevel, v, arr)
                    }
                }
            })
        }
        
        return arr;
    }

    //查询树子节点
    renderTreeNodes = data =>
        data.map(item => {
            if (item.nextLevel) {
                return (
                    <TreeNode
                        title={item.permissionName}
                        key={item.permissionId}
                        value={item.permissionId}
                        disabled={this.$common.isArray(item.nextLevel) ? true : false}
                        dataRef={item}>
                        {this.renderTreeNodes(item.nextLevel)}
                    </TreeNode>
                );
            }
            return <TreeNode
                key={item.permissionId}
                value={item.permissionId}
                title={item.permissionName}
                {...item} />;
        });


    //回显权限
    queryPowerValue = (list) => {
        let arr = [];
        if (this.$common.isArray(list)) {
            list.map(item => {
                arr.push(item && item.permissionId);
            });
        }
        return arr;
    }


    //移除父节点并回填到权限
    queryParentId = (list, newList, arr) => {
        list.map(item => {
            if (this.$common.isArray(item.nextLevel)) {
                this.queryParentId(item.nextLevel, newList, arr);
            }

            if (this.$common.isArray(newList)) {
                newList.map(ite => {
                    if (item && ite) {
                        if (!this.$common.isArray(item.nextLevel) && (item.permissionId == ite.permissionId)) {
                            arr.push(item.permissionId);
                        }
                    }
                });
            }
        });

        return arr;
    }


    /**
     * @description: 选中树节点回调
     * @param {type} 
     * @return {type} 
     * @author: 文倩
     */
    treeChange = (value, label, extra) => {
        console.log(value, label, extra,6666);
        const {setFieldsValue}=this.props.form;
        const { powerSelectList } = this.state;
        let list = [];
        let newList=[];
        let arr=[];
        if (this.$common.isArray(value)) {
            value.map(item => {
                arr=this.getParentId(powerSelectList, item, list);
            });
        }
        if(arr.length>0){
            arr.map(val=>{
                newList.push(val.split('-'));
            });
        }
        
        setFieldsValue({
            permissionIds:[],
        });
        // _.uniq(_.flattenDeep(newList))
    }

    render() {
        const { form, type, title, formData } = this.props;
        const { visible, powerSelectList } = this.state;

        return (
            <Fragment key="passBtn">
                {type == 'list' ?
                    <span onClick={this.handlePassBtnShow}>
                        修改
                    </span>
                    :
                    <Button onClick={this.handlePassBtnShow} type="primary">
                        新增角色
                    </Button>
                }
                <Modal
                    className="reviseModal"
                    title={title}
                    visible={visible}
                    width={518}
                    onCancel={this.handlePassBtnCancle}
                    onOk={this.handleAddSure}
                    maskClosable={false}
                    okText="提交"
                >
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }}>
                        <BaseInput
                            form={form}
                            label="角色名称"
                            field='roleName'
                            required={true}
                            message={"长度20个字，可输入中文、数字、英文、特殊符号"}
                            placeholder='请输入角色名称'
                            initialValue={formData && formData.roleName}
                            validatorName={baseInput7}
                            maxLength={20}
                        />
                        <FormItem label='角色权限'>
                            {this.props.form.getFieldDecorator('permissionIds', {
                                initialValue: formData &&this.queryParentId(powerSelectList,formData.permissionPOList,[]),
                                rules: [{ required: false, message: "请选择角色权限" }]
                            })(
                                <TreeSelect
                                    placeholder="请选择角色权限"
                                    multiple
                                    treeDefaultExpandAll
                                    allowClear
                                    showLine={true}
                                    dropdownStyle={{
                                        height: 300
                                    }}
                                    showCheckedStrategy={TreeSelect.SHOW_ALL}
                                    // onChange={this.treeChange}
                                >
                                    {this.renderTreeNodes(powerSelectList)}
                                </TreeSelect>
                            )}
                        </FormItem>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}
export default Form.create()(AddRole);
