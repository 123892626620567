
/*
 * @Description: 
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-14 18:52:11
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-07-17 11:11:36
 */ 

//我的工作台
import WorkbenchList from '../../pages/Workbench'; 
import ProjectList from '../../pages/ProjectList'; 
import ProjectDetail from '../../pages/ProjectList/subPage/detail/detail'; 
import ProjectEdit from '../../pages/ProjectList/subPage/Edit/Edit'; 
// 统计报表
import DepartmentIncomeRate from '../../pages/StatisticalReport/DepartmentIncomeRate'; 
import ProjectIncomeRate from '../../pages/StatisticalReport/ProjectIncomeRate'; 
import StaffInfoChange from '../../pages/StatisticalReport/StaffInfoChange'; 
import EntryLeave from '../../pages/StatisticalReport/EntryLeave'; 
import Roster from '../../pages/StatisticalReport/Roster'; 
import MonthResidentStatis from '../../pages/StatisticalReport/MonthResidentStatis'; 
import MonthResidentRoster from '../../pages/StatisticalReport/MonthResidentRoster'; 
export default [
    {
        name: '我的工作台',
        path: '/Workbench/list', // 我的工作台
        exact: true,
        openAccessMenu: true,
        component: WorkbenchList
    },
    {
        name: '项目看板',
        path: '/Project/List', // 项目看板
        exact: true,
        openAccessMenu: true,
        component: ProjectList
    },
    {
        name: '项目看板-项目详情',
        path: '/Project/List/projectDetail', // 项目看板-查看
        exact: true,
        openAccessMenu: true,
        component: ProjectDetail
    },
    {
        name: '项目看板-项目详情',
        path: '/Project/List/projectEdit', // 项目看板-编辑
        exact: true,
        openAccessMenu: true,
        component: ProjectEdit
    },
    {
        name: '统计报表-项目毛利率统计',
        path: '/StatisticalReport/ProjectIncomeRate', // 项目毛利率统计
        exact: true,
        openAccessMenu: true,
        component: ProjectIncomeRate
    },
    {
        name: '统计报表-部门毛利率统计',
        path: '/StatisticalReport/DepartmentIncomeRate', // 部门毛利率统计
        exact: true,
        openAccessMenu: true,
        component: DepartmentIncomeRate
    },
    {
        name: '统计报表-人员信息变动表',
        path: '/StatisticalReport/StaffInfoChange', // 人员信息变动表
        exact: true,
        openAccessMenu: true,
        component: StaffInfoChange
    },
    {
        name: '统计报表-入职离职表',
        path: '/StatisticalReport/EntryLeave', // 入职离职表
        exact: true,
        openAccessMenu: true,
        component: EntryLeave
    },
    {
        name: '统计报表-花名册',
        path: '/StatisticalReport/Roster', // 花名册
        exact: true,
        openAccessMenu: true,
        component: Roster
    },
    {
        name: '统计报表-月度在册人数统计',
        path: '/StatisticalReport/MonthResidentStatis', // 月度在册人数统计
        exact: true,
        openAccessMenu: true,
        component: MonthResidentStatis
    },
    {
        name: '统计报表-月度在册人员花名册',
        path: '/StatisticalReport/MonthResidentRoster', // 月度在册人员花名册
        exact: true,
        openAccessMenu: true,
        component: MonthResidentRoster
    }
];