/*
 * @Description: 新增人员
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 14:41:03
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-08-26 11:58:07
 */

import React, { Component } from "react";
import {
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  Form,
  Modal,
  message,
  Input,
} from "antd";
import _ from "lodash";
import moment from "moment";
import { isArray } from "../../../../../utils/common";
import TextFieldInput from "../../../../../components/sceneInput/TextAreaInput";
import BaseInput from "../../../../../components/baseInput";
import InputNumber from "../../../../../components/baseInput/number";
import IdentityInput from "../../../../../components/sceneInput/identityInput";
import MobileNoInput from "../../../../../components/sceneInput/mobileNoInput";
import {
  baseInput1,
  baseInput4,
  baseInput7,
  isMobile,
  isIdentityInput,
} from "../../../../../utils/validate.js";
import { isEqual } from "lodash";
const { Option } = Select;
class AddStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusList: [],
      total: 3,
      visible: false,
      loading: false,
      pageNo: 1,
      pageSize: 10,
      isCard: false,
      searchValue: {},
      detailData: {},
      selectedRowKeys: [],
      WBSList: [],
      CityList: [],
      RankInfoList: [],
      GroupInfoList: [],
      serveList: [],
      cityId: "", //城市主键
      serveNature: "", //服务性质
      rank: "", //层级
      btnLoading: false,
      purchasePrice: null, //采购价
      payType: "",
      WBSvalue: "", //wbs值
      allSalaryTypes: {}, // 全部薪资类型
    };
  }
  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.detailInfo, nextProps.detailInfo)) {
      this.getWbsList(nextProps.detailInfo.groupId);
    }
  }
  /**
   * @description: 人员新增
   * @author: 陈金金
   */
  handleAddSure = () => {
    const { projectId, detailInfo } = this.props;
    console.log("detailInfo ---> ", detailInfo);
    const { groupId } = detailInfo;
    this.props.form.validateFieldsAndScroll((err, values) => {
      console.log("values --->", values);
      if (!err) {
        this.setState({ btnLoading: true });
        delete values.groupId;
        if (values.onBoardingTime)
          values.onBoardingTime = values.onBoardingTime.format("YYYY-MM-DD");
        if (values.levelSalaryId)
          values.levelSalaryId = values.levelSalaryId.key;
        values.groupId = groupId;
        this.$http
          .addProjectStaffRoster({ ...values, projectId })
          .then((res) => {
            console.log("AddStaff --->", res);
            this.setState({ btnLoading: false });
            if (res && res.errorCode == 200) {
              message.destroy();
              message.success(res.message);
              this.props.closeModal();
              this.setState({
                visible: false,
              });
            }
          });
      }
    });
  };

  handleReturnStockBtnCancle = () => {
    this.props.form.resetFields();
    this.setState({ visible: false });
  };
  /**
   * @description: 获取下拉枚举值
   * @param {type}
   * @return:
   * @author: 陈金金
   */
  getDicCacheList = () => {
    this.$http
      .queryDicCacheList(["PAY_TYPE", "SEX", "IDENTITY", "SERVE_NATURE"])
      .then((res) => {
        if (res && res.errorCode == 200) {
          this.setState({
            statusList: res.data,
          });
        }
      });
  };
  /**
   * @description: 获取wbs,层级，办公城市，组别
   * @param {type}
   * @return:
   * @author: 陈金金
   */
  getSelectList = (api, type) => {
    this.$http[`${api}`]({ page: 1, pageSize: 999 }).then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          [type]: api == "WBSListInfo" ? res.data.rows : res.data,
        });
      }
    });
  };

  /**
   * @method 获取wbs自动回显值
   */
  getWbsDefaultValue = () => {
    const { WBSList } = this.state;
    const WBSListLength = WBSList.length;
    return WBSListLength && WBSListLength == 1 ? WBSList[0].wbsId : undefined;
  };
  /**
   * @method 根据组别获取wbs
   */
  getWbsList = (groupId) => {
    this.$http[`WBSListInfo`]({ groupId }).then((res) => {
      console.log("res --->", res);
      if (res && res.errorCode == 200) {
        this.setState({
          WBSList: res.data.rows,
        });
      }
    });
  };

  openModal = () => {
    //打开弹窗获取wbs和城市列表
    this.setState({ visible: true }, () => {
      this.getDicCacheList();

      [
        { api: "CityList", type: "CityList" },
        // {api:'RankInfoList',type:'RankInfoList'},
        // {api:'GroupInfoList',type:'GroupInfoList'}
      ].map((v) => {
        return this.getSelectList(v.api, v.type);
      });
    });
  };
  payTypeChange = (e) => {
    const info = this.props.form.getFieldsValue();
    this.setState({
      payType: e,
    });
    if (e == "NON_PAY") {
      this.props.form.setFields({
        ["purchasePrice"]: {
          value: 0,
        },
      });
    } else {
      if (this.state.purchasePrice != null) {
        this.props.form.setFields({
          ["purchasePrice"]: {
            value: this.state.purchasePrice,
          },
        });
      } else {
        this.props.form.setFields({
          ["purchasePrice"]: {
            value: undefined,
          },
        });
      }
    }
  };
  linkageChange = (param, type) => {
    const { payType } = this.state;
    const { getFieldValue, setFields } = this.props.form;
    if (type == "serveList") {
      //选择办公城市联动
      setFields(
        {
          ["serveNature"]: {
            value: undefined,
          },
          ["rank"]: {
            value: undefined,
          },
          ["levelSalaryId"]: {
            value: undefined,
          },
          ["purchasePrice"]: {
            value: undefined,
          },
          ["purchaseSalaryCeil"]: {
            value: undefined,
          },
          ["socialSecurityFundId"]: {
            value: undefined,
          },
          ["socialSecurityFundCost"]: {
            value: undefined,
          },
        },
        () => {
          // 获取社保公积金成本
          // getFieldValue("identity") === "INTERNSHIP"
          console.log(
            'getFieldValue("identity") === ',
            getFieldValue("identity")
          );
          this.$http.SocialSecurityFundListAll(param).then((res) => {
            if (res && res.errorCode == 200) {
              setFields({
                ["socialSecurityFundId"]: {
                  value: res.data[0].socialSecurityFundId,
                },
                ["socialSecurityFundCost"]: {
                  value:
                    getFieldValue("identity") === "INTERNSHIP"
                      ? 0
                      : res.data[0].socialSecurityFundCost,
                },
              });
            }
          });
        }
      );
      this.setState({
        RankInfoList: [],
      });
    }
    if (type == "RankInfoList") {
      //选择服务性质联动
      setFields({
        ["rank"]: {
          value: undefined,
        },
        ["levelSalaryId"]: {
          value: undefined,
        },
        ["purchasePrice"]: {
          value: undefined,
        },
        ["purchaseSalaryCeil"]: {
          value: undefined,
        },
      });
      this.setState({
        RankInfoList: [],
      });
    }
    this.$http.RankInfoList(param).then((res) => {
      if (res && res.errorCode == 200) {
        if (type != "rank") {
          this.setState({
            [type]: res.data,
            ...param,
          });
        } else {
          // 暂存采购价
          this.setState({
            purchasePrice: res.data[0].purchasePrice,
          });
          setFields({
            ["purchasePrice"]: {
              value: payType == "PAY" ? res.data[0].purchasePrice : 0,
            },
            ["purchaseSalaryCeil"]: {
              value: res.data[0].purchaseSalaryCeil,
            },
          });
        }
      }
    });
  };

  /**
   * @method 使用人员身份更新社保公积金成本
   * @param { Number } value 更新的值
   * @param { String } type 身份
   */
  useIdentityUpdateSocialSecurityFundCost = (type, value = 0) => {
    const { setFieldsValue, getFieldValue } = this.props.form;
    console.log("type === ", type);
    // cityId
    // 实习生
    if (type === "INTERNSHIP") {
      setFieldsValue({
        ["socialSecurityFundCost"]: value,
      });
    }
    // 正式员工
    if (type === "OFFICIAL" && !getFieldValue("cityId")) {
      setFieldsValue({
        ["socialSecurityFundCost"]: undefined,
      });
    } else {
      this.linkageChange({ cityId: getFieldValue("cityId") }, "serveList");
    }
  };
  /**
   * @函数描述: 计算合计
   * @param {Number} 转正系统薪资 turnSalary
   * @param {Number} 绩效考核基准 performanceStandard
   * @return:
   */
  calculation = (type, e) => {
    // console.log(e.target.value)
    const { getFieldsValue, setFieldsValue } = this.props.form;
    const info = getFieldsValue();
    const { turnSalary, performanceStandard } = info;
    console.log(turnSalary, performanceStandard);
    if (
      (turnSalary || turnSalary == 0) &&
      (performanceStandard || performanceStandard == 0)
    ) {
      const value = this.addition(
        Number(turnSalary),
        Number(performanceStandard)
      );
      setFieldsValue({
        ["salaryTotal"]: this.$common.isRealNum(value) ? value : undefined,
      });
    } else {
      setFieldsValue({
        ["salaryTotal"]: undefined,
      });
    }
  };
  addition = (a, b) => {
    return (a + b).toFixed(2);
  };

  /**
   * @method 计算薪资合计
   * @param { String } type 薪资类型
   */
  calculateSalary = (type) => {
    const { setFieldsValue, getFieldValue, getFieldsError } = this.props.form;
    const { cloneDeep, toArray, compact, isArray, size, round } = _;
    const cloneAllSalaryTypes = cloneDeep(this.state.allSalaryTypes);
    cloneAllSalaryTypes[type] = getFieldValue(type);
    this.setState({ allSalaryTypes: cloneAllSalaryTypes }, () => {
      const { allSalaryTypes } = this.state;
      console.log("allSalaryTypes --->", allSalaryTypes);
      const allSalaryValues = toArray(allSalaryTypes);
      console.log("allSalaryValues --->", allSalaryValues);
      const totalSalary =
        isArray(allSalaryValues) &&
        size(allSalaryValues) &&
        allSalaryValues.reduce((a, b) => a + b);
      const errors = getFieldsError([
        "nonSystemFixedSalaryIncrease",
        "personalPerformanceBenchmarks",
        "projectPerformancePoolBenchmarks",
        "turnSalary",
      ]);
      const isErrors = compact(toArray(errors));
      setFieldsValue({
        ["salaryTotal"]:
          allSalaryValues.length > 3 && !isErrors.length
            ? round(totalSalary, 2)
            : undefined,
      });
    });
  };

  // cityId:'',//城市主键
  // serveNature:'',//服务性质
  // rank:'',//层级
  render() {
    const {
      cityId,
      serveNature,
      btnLoading,
      rank,
      visible,
      statusList,
      WBSList,
      serveList,
      CityList,
      RankInfoList,
      GroupInfoList,
    } = this.state;
    const { form, detailInfo } = this.props;
    const { groupName } = detailInfo;
    const { getFieldDecorator } = this.props.form;
    const info = this.props.form.getFieldsValue();
    const { salaryTotal, purchaseSalaryCeil } = info;
    const wbsDefaultValue = this.getWbsDefaultValue();
    // console.log("wbsDefaultValue --->", wbsDefaultValue);
    return (
      <>
        <Button type="primary" onClick={() => this.openModal()}>
          新增人员
        </Button>

        {visible && (
          <Modal
            className="AddStaffModal"
            title="新增人员"
            visible={visible}
            width={950}
            footer={[
              <div key="AddStaffModal">
                <Button
                  key="cancle"
                  onClick={() => this.handleReturnStockBtnCancle()}
                >
                  取消
                </Button>
                <Button
                  key="sure"
                  onClick={this.handleAddSure}
                  loading={btnLoading}
                  type="primary"
                >
                  提交{" "}
                </Button>
              </div>,
            ]}
            onCancel={this.handleReturnStockBtnCancle}
            // onOk={this.handleAddSure}
            maskClosable={false}
          >
            <Form>
              <Row gutter={50}>
                <Col span={12}>
                  <BaseInput
                    form={form}
                    label="姓名"
                    required={true}
                    placeholder="请输入员工姓名"
                    validatorName={baseInput7}
                    field="staffName"
                    maxLength={20}
                    message="请输入中文、字母或数字。"
                  />
                </Col>
                <Col span={12}>
                  <BaseInput
                    form={form}
                    label="工号"
                    required={true}
                    placeholder="请输入员工工号"
                    validatorName={baseInput1}
                    field="staffCode"
                    maxLength={20}
                    message="请输入中文、字母或数字。"
                  />
                </Col>
                <Col span={12}>
                  <Form.Item label="入职时间">
                    {getFieldDecorator("onBoardingTime", {
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: "请选择入职时间",
                        },
                      ],
                    })(
                      <DatePicker
                        placeholder="请选择入职时间"
                        disabledDate={(e) => e && e > moment()}
                        format="YYYY-MM-DD"
                        style={{ width: "100%" }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="性别">
                    {getFieldDecorator("sex", {
                      rules: [{ required: true, message: "请选择性别" }],
                    })(
                      <Select placeholder="请选择性别" allowClear>
                        {statusList["SEX"] &&
                          statusList["SEX"].map((item) => {
                            return <Option key={item.key}>{item.value}</Option>;
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <MobileNoInput
                    form={form}
                    label="联系电话"
                    required={true}
                    placeholder="请输入联系电话"
                    validatorName={isMobile}
                    field="contactNumber"
                    maxLength={11}
                    message="请输入正确的手机号码"
                  />
                </Col>
                <Col span={12}>
                  <IdentityInput
                    form={form}
                    label="身份证号码"
                    required={true}
                    placeholder="请输入身份证号码"
                    validatorName={isIdentityInput}
                    field="idNumber"
                    maxLength={18}
                    message="请输入正确的身份证号码"
                  />
                </Col>
                <Col span={12}>
                  {/* <DepartmentSelect
                                    form={this.props.form}
                                    label={'组别'}
                                    field={'groupId'}
                                    required={true}
                                /> */}
                  {/* <Form.Item label="组别">
                    {getFieldDecorator("groupId", {
                      rules: [{ required: true }],
                    })(<Input disabled defaultValue={"hahahhaa"} />)}
                  </Form.Item> */}
                  <BaseInput
                    form={form}
                    label="组别"
                    required={true}
                    disabled={true}
                    initialValue={groupName}
                    validatorName={baseInput7}
                    field="groupId"
                  />
                </Col>
                <Col span={12}>
                  <Form.Item label="WBS">
                    {getFieldDecorator("wbsId", {
                      rules: [{ required: true, message: "请选择WBS" }],
                      initialValue: wbsDefaultValue,
                    })(
                      <Select
                        placeholder="请选择WBS"
                        allowClear
                        // onFocus={
                        //   // (e) => this.getSelectList("WBSListInfo", "WBSList")
                        //   (e) => this.getWbsList()
                        // }
                      >
                        {isArray(WBSList) &&
                          WBSList.map((v) => {
                            console.log(v);
                            return (
                              <Option key={v.wbsId} value={v.wbsId}>
                                {v.wbsName}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="付费类型">
                    {getFieldDecorator("payType", {
                      rules: [{ required: true, message: "请选择付费类型" }],
                    })(
                      <Select
                        placeholder="请选择付费类型"
                        allowClear
                        onChange={(e) => this.payTypeChange(e)}
                      >
                        {statusList["PAY_TYPE"] &&
                          statusList["PAY_TYPE"].map((v) => {
                            return (
                              <Option key={v.key} value={v.key}>
                                {v.value}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="人员身份">
                    {getFieldDecorator("identity", {
                      rules: [{ required: true, message: "请选择人员身份" }],
                    })(
                      <Select
                        placeholder="请选择人员身份"
                        allowClear
                        onChange={(e) =>
                          this.useIdentityUpdateSocialSecurityFundCost(e, 0)
                        }
                      >
                        {statusList["IDENTITY"] &&
                          statusList["IDENTITY"].map((v) => {
                            return (
                              <Option key={v.key} value={v.key}>
                                {v.value}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="办公城市">
                    {getFieldDecorator("cityId", {
                      rules: [{ required: true, message: "请选择所属城市" }],
                    })(
                      <Select
                        placeholder="请选择办公城市"
                        allowClear
                        onChange={(e) =>
                          this.linkageChange({ cityId: e }, "serveList")
                        }
                      >
                        {isArray(CityList) &&
                          CityList.map((v) => {
                            return (
                              <Option key={v.cityId} value={v.cityId}>
                                {v.cityName}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="服务性质">
                    {getFieldDecorator("serveNature", {
                      rules: [{ required: true, message: "请选择服务性质" }],
                    })(
                      <Select
                        placeholder="请选择服务性质"
                        allowClear
                        onChange={(e) =>
                          this.linkageChange(
                            { serveNature: e, cityId: cityId },
                            "RankInfoList"
                          )
                        }
                      >
                        {isArray(serveList) &&
                          _.uniqBy(serveList, "serveNature")
                            // statusList['SERVE_NATURE']&&statusList['SERVE_NATURE']
                            .map((v) => {
                              return (
                                <Option
                                  key={v.serveNature}
                                  value={v.serveNature}
                                >
                                  {v.serveNatureValue}
                                </Option>
                              );
                            })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="层级">
                    {getFieldDecorator("levelSalaryId", {
                      rules: [{ required: true, message: "请选择层级" }],
                    })(
                      <Select
                        placeholder="请选择层级"
                        allowClear
                        labelInValue
                        onChange={(e) =>
                          this.linkageChange(
                            {
                              rank: e.label,
                              serveNature: serveNature,
                              cityId: cityId,
                            },
                            "rank"
                          )
                        }
                      >
                        {isArray(RankInfoList) &&
                          RankInfoList.map((v) => {
                            return (
                              <Option
                                key={v.rankSalaryId}
                                value={v.rankSalaryId}
                              >
                                {v.rank}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <InputNumber
                    form={form}
                    label="采购价"
                    required={true}
                    allowClear
                    disabled
                    placeholder="此处自动带出"
                    validatorName={baseInput4}
                    field="purchasePrice"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    // maxLength={20}
                    style={{ width: 432 }}
                    message="请输入数字,只能保留小数点后2位"
                  />
                </Col>
                <Col span={12}>
                  <InputNumber
                    form={form}
                    label="社保公积金成本"
                    required={true}
                    allowClear
                    disabled
                    placeholder="此处自动带出"
                    validatorName={baseInput4}
                    field="socialSecurityFundCost"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    // maxLength={20}
                    style={{ width: 432 }}
                    message="请输入数字"
                  />
                  {getFieldDecorator("socialSecurityFundId")}
                </Col>
                <Col span={12}>
                  <InputNumber
                    form={form}
                    label="转正系统薪资"
                    field="turnSalary"
                    required={true}
                    message={"长度为20，包括小数点和两位小数"}
                    placeholder="请输入转正系统薪资"
                    validatorName={baseInput4}
                    maxLength={10}
                    min={0}
                    max={9999999.99}
                    step={0.01}
                    onBlur={() => this.calculateSalary("turnSalary")}
                  />
                </Col>
                <Col span={12}>
                  {/* <InputNumber
                    form={form}
                    label="绩效考核基准"
                    required={true}
                    allowClear
                    min={0}
                    step={0.01}
                    max={9999999.99}
                    placeholder="请输入绩效考核基准"
                    validatorName={baseInput4}
                    field="performanceStandard"
                    maxLength={10}
                    style={{ width: 432 }}
                    message="请输入数字，最多保留2位小数"
                    onBlur={(e) => this.calculation("performanceStandard", e)}
                    // onBlur={this.calculation.bind(this)}
                  /> */}
                  <InputNumber
                    form={form}
                    label="非系统固定涨薪"
                    required={true}
                    allowClear
                    min={0}
                    step={0.01}
                    max={9999999.99}
                    placeholder="请输入非系统固定涨薪"
                    validatorName={baseInput4}
                    field="nonSystemFixedSalaryIncrease"
                    maxLength={10}
                    style={{ width: 432 }}
                    message="请输入数字，最多保留2位小数"
                    onBlur={() =>
                      this.calculateSalary("nonSystemFixedSalaryIncrease")
                    }
                    // onBlur={this.calculation.bind(this)}
                  />
                </Col>
                <Col span={12}>
                  <InputNumber
                    form={form}
                    label="个人绩效基准值"
                    required={true}
                    allowClear
                    min={0}
                    step={0.01}
                    max={9999999.99}
                    placeholder="请输入个人绩效基准值"
                    validatorName={baseInput4}
                    field="personalPerformanceBenchmarks"
                    maxLength={10}
                    style={{ width: 432 }}
                    message="请输入数字，最多保留2位小数"
                    onBlur={() =>
                      this.calculateSalary("personalPerformanceBenchmarks")
                    }
                    // onBlur={this.calculation.bind(this)}
                  />
                </Col>
                <Col span={12}>
                  <InputNumber
                    form={form}
                    label="项目绩效池基准值"
                    required={true}
                    allowClear
                    min={0}
                    step={0.01}
                    max={9999999.99}
                    placeholder="请输入项目绩效池基准值"
                    validatorName={baseInput4}
                    field="projectPerformancePoolBenchmarks"
                    maxLength={10}
                    style={{ width: 432 }}
                    message="请输入数字，最多保留2位小数"
                    onBlur={() =>
                      this.calculateSalary("projectPerformancePoolBenchmarks")
                    }
                    // onBlur={this.calculation.bind(this)}
                  />
                </Col>
                <Col span={12}>
                  <InputNumber
                    form={form}
                    label="薪资合计"
                    required={true}
                    allowClear
                    disabled
                    placeholder="此处自动带出"
                    validatorName={baseInput4}
                    field="salaryTotal"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    // maxLength={20}
                    style={{ width: 432 }}
                    message="请输入数字"
                  />
                </Col>
                <Col span={12}>
                  <InputNumber
                    form={form}
                    label="薪资上限参考值"
                    required={true}
                    allowClear
                    disabled
                    placeholder="此处自动带出"
                    validatorName={baseInput4}
                    field="purchaseSalaryCeil"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    // maxLength={20}
                    style={{ width: 432 }}
                    message="请输入数字"
                  />
                </Col>
                <Col span={12}>
                  <TextFieldInput
                    form={this.props.form}
                    showLength={true}
                    label="超上限备注说明"
                    placeholder="请输入"
                    required={Number(salaryTotal) > Number(purchaseSalaryCeil)}
                    allowClear
                    field="remarks"
                    maxLength={500}
                    autoSize={{ minRows: 4, maxRows: 5 }}
                  />
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </>
    );
  }
}

export default Form.create()(AddStaff);
