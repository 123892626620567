/*
 * @文档描述: 组别 --- 新增
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-08-22 09:47:57
 * @最后修改人: 文倩
 */
import React, { Component, Fragment } from "react";
import { Modal, Form, Button } from "antd";
import BaseInput from "../../../../../components/baseInput/index";

import { _isEmoji } from "../../../../../utils/validate";
import DynamicFieldSet from "../../../../../components/DynamicFieldSet";
import _ from "lodash";

class AddGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 编辑弹框显隐
      formData: null, //父组件列表数据
    };
    this.wbsRef = React.createRef();
  }

  /**
   * @函数描述: 点击显示编辑弹框
   * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
   * @return:
   */
  handlePassBtnShow = () => {
    const { formData } = this.props;
    this.setState({
      visible: true,
      formData: formData || {},
    });
  };

  /**
   * @函数描述: 取消弹框显示
   * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
   * @return: 记得重置表单呦
   * @描述: 取消弹框调用WBS赋值方法，根据取消，确认进行传参赋值
   * @最后修改人: 赵娟
   */
  handlePassBtnCancle = (webList) => {
    // this.wbsRef.current.setWbsList();
    const { formData } = this.state;
    this.setState(
      {
        visible: false,
      },
      () => {
        this.wbsRef.current.setWbsList(
          webList || formData.webList
        );
        this.props.form.resetFields();
      }
    );
  };
  /**
   * @函数描述: 确定编辑成功
   * @param {String} passMessage  审批意见
   * @param {String} id
   * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
   * @return: 记得重置表单呦，重新表格当前页数据
   * @描述: 添加wbsList参数
   * @最后修改人: 赵娟
   */
  handleAddSure = (e) => {
    e.preventDefault();
    const { validateFieldsAndScroll } = this.props.form;
    const { type, formData } = this.props;
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        values.wbsList = (values.wbsList || [])
          .map((val) => {
            return val.value;
          })
          .filter((val) => val !== null);
        const query = {
          ...values,
        };
        if (type == "list") {
          query.groupId = formData.groupId;
          this.$http.updateGroup(query).then((res) => {
            if (res && res.errorCode == 200) {
              this.props.handleOk();
              this.handlePassBtnCancle(values.wbsList);
            }
          });
        } else {
          this.$http.addGroup(query).then((res) => {
            if (res && res.errorCode == 200) {
              this.props.handleOk();
              this.handlePassBtnCancle();
            }
          });
        }
      }
    });
  };

  render() {
    const { form, type } = this.props;
    const { visible, formData } = this.state;
    const groupNameNum =
      form.getFieldValue("groupName") || "";
    return (
      <Fragment key="passBtn">
        {type == "list" ? (
          <span onClick={this.handlePassBtnShow}>修改</span>
        ) : (
          <Button
            onClick={this.handlePassBtnShow}
            type="primary"
          >
            新增
          </Button>
        )}
        <Modal
          className="reviseModal"
          title={type == "list" ? "修改" : "新增"}
          visible={visible}
          width={518}
          onCancel={() => this.handlePassBtnCancle()}
          onOk={this.handleAddSure}
          maskClosable={false}
          okText="提交"
        >
          <Form>
            <BaseInput
              form={form}
              label="组别"
              field="groupName"
              required={true}
              message={
                "长度为50，可输入中文、数字、英文、特殊符号"
              }
              placeholder="请输入组别"
              initialValue={formData && formData.groupName}
              validatorName={_isEmoji}
              maxLength={50}
              addonAfter={
                <span>{groupNameNum.length}/50</span>
              }
            />
            <DynamicFieldSet
              form={form}
              label="WBS"
              ref={this.wbsRef}
              count={10}
              message={
                "长度为50，可输入中文、数字、英文、特殊符号"
              }
              placeholder="请输入WBS"
              maxLength={50}
              required={true}
              validatorName={_isEmoji}
              initialValue={
                (formData && formData.wbsList) || [""]
              }
            />
          </Form>
        </Modal>
      </Fragment>
    );
  }
}
export default Form.create()(AddGroup);
