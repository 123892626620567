/**
 * @description 文件导入
 * @author zxz
 * @version v1.2
 */

import React from "react";
import { Button, Upload, message, Modal } from "antd";
import PropTypes from "prop-types";
import ModalAlert from "../../../../../components/importBtn/importAlert";
import "./Components.less";

class FileImport extends React.Component {
  state = {
    isUploading: false,
    visible: false,
    importType: "importing", //importing // 导入中   imported //导入成功  importerror//导入失败
    importId: "",
    successTotal: 0,
    errorTotal: 0,
  };

  handleUpload = async ({ file }) => {
    console.log("file --->", file);
    const { projectId, onSuccess } = this.props;
    if (file && this.$common.limitUploadxlsx(file)) {
      let fileData = new FormData(); //创建文件流
      fileData.append("file", file);
      fileData.append("projectId", projectId);
      console.log("fileData --->", fileData);
      // 发送上传请求
      try {
        const res = await this.$http.batchImportOfProjectPersonnel(fileData);
        console.log("res --->", res);
        if (res && res.request && res.request.responseType == "blob") {
          let reader = new FileReader();
          reader.onload = () => {
            // 返回值
            let content = JSON.parse(reader.result);
            console.log("content --- >", content);
            const { errorCode, message: messageCode, data } = content;
            this.setState({
              isUploading: false,
            });
            if (errorCode == 200) {
              const { successTotal, failTotal, importId } = data;
              onSuccess(data);
              let importTypeValue;

              failTotal != 0
                ? (importTypeValue = "importerror")
                : (importTypeValue = "imported");

              this.setState(
                {
                  importType: importTypeValue,
                  importId: importId,
                  successTotal: successTotal,
                  errorTotal: failTotal,
                },
                () => {
                  this.setState({ visible: true });
                }
              );
              // message.success("导入成功");
            } else {
              message.warning(messageCode);
            }
          };
          reader.readAsText(res.data);
        } else {
          this.setState({
            isUploading: false,
          });
        }
      } catch (error) {
        this.setState({ isUploading: false });
        message.error(error);
      }
    } else {
      message.warning("不支持的类型");
    }
  };

  _beforeUpload = () => {
    this.setState({ isUploading: true });
    return true;
  };

  _onCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const params = {
      accept: ".xlsx,.xls",
      beforeUpload: this._beforeUpload,
      customRequest: this.handleUpload,
      showUploadList: false,
    };
    const {
      visible,
      importType,
      successTotal,
      errorTotal,
      importId,
    } = this.state;
    return (
      <>
        <Upload {...params}>
          <Button
            type="primary"
            style={this.props.style}
            loading={this.state.isUploading}
          >
            文件导入
          </Button>
        </Upload>
        <ModalAlert
          visible={visible}
          type={importType}
          successNum={successTotal}
          errorNum={errorTotal}
          importId={importId}
          downLoadError={(val) =>
            this.$http.failedDataDownloadOfNewPersonnelImport(val)
          }
          onCancel={this._onCancel}
          onOk={this._onCancel}
        />
      </>
    );
  }
}

FileImport.defaultProps = {};

FileImport.propTypes = {
  style: PropTypes.object,
  /**项目id */
  projectId: PropTypes.string.isRequired,
  /**导入成功执行 */
  onSuccess: PropTypes.func.isRequired,
};

export default FileImport;
