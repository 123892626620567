/*
 * @Description: 权限管理字段配置
 * @Author: 文倩
 * @Date: 2020-06-02 11:39:06
 * @LastEditTime: 2020-09-01 16:07:08
 * @LastEditor: 文倩
 */
import React from 'react'
import TooltipComponent from '../../components/TooltipComponent';
import { isArray } from '../../utils/common';
import {baseInput7} from '../../utils/validate';

//账号管理
export const accountColumns = [
    {
        title: '账号名称',
        dataIndex: 'staffName',
        width:100,
        render: text => text ? <TooltipComponent placement="topLeft" text={text} /> : '-'
    },
    {
        title: '域账号',
        dataIndex: 'sloginName',
        width:100,
        render: text => text ? <TooltipComponent placement="topLeft" text={text} /> : '-'
    },
    {
        title: '角色',
        dataIndex: 'roleVOS',
        width:500,
        render: text => {
            let roleNameList = [];
            let roleName;
            if (isArray(text)) {
                text.map(item => {
                    roleNameList.push(item.roleName);
                });
                roleName = roleNameList.join('，');
            }
            return isArray(text) ? <TooltipComponent placement="topLeft" text={roleName} /> : '-'
        }
    },
    {
        title: '状态',
        dataIndex: 'staffState',
        width:100,
        render: text => (text ? <span className="enable">活跃</span> : <span className="stopUse">已停用</span>)
    }
]

//账号管理搜索字段配置
export const searchFormData = [
    {
        label: '账号名称',
        name: 'staffName',
        type: 'input',
        validatorName:baseInput7,
        placeholder:"请输入账号名称",
        maxLength:20,
    }, {
        label: '账号角色',
        name: 'roleIds',
        type: 'select',
        placeholder: '请选择账号角色',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: []
    },
    // {
    //     label: '账号状态',
    //     name: 'roleId',
    //     type: 'select',
    //     placeholder: '请选择',
    //     mapping: {
    //         name: 'role',
    //         value: 'role'
    //     },
    //     selectData: []
    // }
]

//角色管理
export const roleColumns = [
    {
        title: '角色名称',
        dataIndex: 'roleName',
        width:100,
        render: text => text ? <TooltipComponent placement="topLeft" text={text} /> : '-'
    }, {
        title: '权限',
        dataIndex: 'permissionNames',
        width:600,
        render: text => {
            let roleName;
            let roleNameList=[];
            if(isArray(text)){
                text.map(item=>{
                    if(item != null){
                        roleNameList.push(item);
                    }
                });
            }
            roleName = roleNameList.join('，');
            return isArray(roleNameList) ? <TooltipComponent placement="topLeft" text={roleName} /> : '-'
        }
    },{
        title: '状态',
        dataIndex: 'roleStatus',
        width:80,
        render: text => (text ? <span className="enable">活跃</span> : <span className="stopUse">已停用</span>)
    }
]

//角色搜索配置项
export const searchRoleFormData=[
    {
        label: '角色名称',
        name: 'roleName',
        type: 'input',
        placeholder:"请输入角色名称",
        validatorName:baseInput7,
        maxLength:20
    }
]



