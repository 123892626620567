/*
 * @Description: 城市选择下拉框
 * @Autor: 文倩
 * @Date: 2020-07-27 15:24:33
 * @LastEditors: 文倩
 * @LastEditTime: 2020-07-27 15:40:05
 */
import React, { Component } from 'react';
import { Form, Select } from 'antd';
const Option = Select.Option;

class CitySelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectData: []
        }
    }

    componentDidMount() {
        this.getCitySelectList();
    }


    /**
     * @description: 查询城市下拉框数据
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    getCitySelectList = () => { 
        this.$http.getAllCityList().then(res=>{
            if(res && res.errorCode == 200){
                this.setState({
                    selectData:res.data
                });
            }else{
                this.setState({
                    selectData:[]
                });
            }
        })
    }


    render() {
        const { field, required, message,initialValue,mode } = this.props;
        const { selectData } = this.state;

        return (
            <Form>
                <Form.Item label={null}>
                    {this.props.form.getFieldDecorator(field, {
                        initialValue: initialValue || undefined,
                        rules: [{
                            required: required,
                            message: message || "请输入"
                        }]
                    })(
                        <Select
                            placeholder='请选择'
                            allowClear
                            mode={mode || ""}
                        >
                            {this.$common.isArray(selectData) && selectData.map(res => {
                                return <Option key={res.cityId}>{res.cityName}</Option>;
                            })}
                        </Select>
                    )}
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create()(CitySelect);