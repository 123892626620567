/*
 * @Description: 
 * @Author: 文倩
 * @Date: 2020-06-01 10:25:07
 * @LastEditTime: 2020-06-05 18:32:00
 * @LastEditors: 文倩
 */


import * as types from "../actions/index";

const initialState = {
    authBtn: []
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.GET_MENU:
            return Object.assign({}, state, { authBtn: action.payload });
        default:
            return state;
    }
}
