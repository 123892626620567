/*
 * @文档描述: 项目 --- 编辑
 * @author: 赵娟
 * @Date: 2020-11-24 20:08:26
 */
import React, { Component, Fragment } from "react";
import { Modal, Form, Button, Select } from "antd";

const FormItem = Form.Item;
const Option = Select.Option;

class EditProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 编辑弹框显隐
      groupList: [], //组别下拉数据
      projectTypeList: [], //项目类型下拉数据
      businessTypeList: [], //业务类型下拉数据
    };
  }

  /**
   * @description: 查询城市列表下拉数据
   * @return:
   * @author: 赵娟
   */
  getEditInfo = () => {
    this.$http.getEditInfo().then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          groupList: res.data.groups || [],
          projectTypeList: res.data.projectType || [],
          businessTypeList: res.data.businessType || [],
        });
      }
    });
  };

  /**
   * @函数描述: 点击显示编辑弹框
   * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
   * @return:
   */
  handlePassBtnShow = () => {
    this.setState(
      {
        visible: true,
      },
      () => {
        this.getEditInfo();
      }
    );
  };

  /**
   * @函数描述: 取消弹框显示
   * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
   * @return: 记得重置表单呦
   */
  handlePassBtnCancle = () => {
    this.setState(
      {
        visible: false,
      },
      () => {
        this.props.form.resetFields();
      }
    );
  };
  /**
   * @函数描述: 确定编辑成功
   * @param {String} passMessage  审批意见
   * @param {String} id
   * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
   * @return: 记得重置表单呦，重新表格当前页数据
   */
  handleEditProject = (e) => {
    e.preventDefault();
    const { validateFieldsAndScroll } = this.props.form;
    const { formData } = this.props;

    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const query = {
          ...values,
        };
        const params = {
          projectId: formData && formData.projectId,
          ...values,
        };
        console.log(params);
        this.$http.updateProject(params).then((res) => {
          if (res && res.errorCode == 200) {
            this.handlePassBtnCancle();
            this.props.handleOk();
          }
        });
      }
    });
  };

  render() {
    const { form, formData } = this.props;
    const {
      visible,
      projectTypeList,
      businessTypeList,
      groupList,
    } = this.state;
    return (
      <Fragment key="passBtn">
        <span onClick={this.handlePassBtnShow}>修改</span>
        <Modal
          className="reviseModal"
          title="修改"
          visible={visible}
          width={518}
          onCancel={this.handlePassBtnCancle}
          onOk={this.handleEditProject}
          maskClosable={false}
          okText="提交"
        >
          <Form>
            <FormItem
              label="项目名称"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 17 }}
            >
              <span>{formData && formData.projectName}</span>
            </FormItem>
            <FormItem label="项目类型">
              {this.props.form.getFieldDecorator("projectType", {
                initialValue: formData && formData.projectType,
                rules: [
                  {
                    required: true,
                    message: "请选择项目类型",
                  },
                ],
              })(
                <Select placeholder="请选择项目类型" allowClear>
                  {projectTypeList.map((res) => {
                    return <Option key={res.key}>{res.val || "-"}</Option>;
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label="业务类型">
              {this.props.form.getFieldDecorator("businessType", {
                initialValue: formData && formData.businessType,
                rules: [
                  {
                    required: true,
                    message: "请选择业务类型",
                  },
                ],
              })(
                <Select placeholder="请选择业务类型" allowClear>
                  {businessTypeList.map((res) => {
                    return <Option key={res.key}>{res.val || "-"}</Option>;
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label="组别">
              {this.props.form.getFieldDecorator("groupId", {
                initialValue: formData && formData.groupId,
                rules: [
                  {
                    required: true,
                    message: "请选择组别",
                  },
                ],
              })(
                <Select placeholder="请选择组别" allowClear>
                  {groupList.map((res) => {
                    return (
                      <Option key={res.groupId}>{res.groupName || "-"}</Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
          </Form>
        </Modal>
      </Fragment>
    );
  }
}
export default Form.create()(EditProject);
