/*
 * @Description: 结项申请
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:46:51
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-09-28 14:58:00
 */



import React, { Component } from 'react';
import { Button, Row, Col, Form, Modal, DatePicker, message } from 'antd';
import './Components.less';
import moment from 'moment';
import Table from '../../../../../components/table/index';
import TooltipComponent from '../../../../../components/TooltipComponent'; // 文本溢出提示
import TextFieldInput from '../../../../../components/sceneInput/TextAreaInput';
class EndProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 3,
            visible: false,
            loading: false,
            pageNo: 1,
            pageSize: 10,
            courierDate: '',
            isCard: false,
            searchValue: {},
            detailData: {},
            projectInfo: {},
            dataSource: [],
            selectedRowKeys: [],
            btnLoading: false,
            dataList: []
        }
    }
    /**
     * @description: 结项申请发起
     * @author: 陈金金
     */
    handleAddSure = () => {
        const { projectId } = this.props;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const param = {
                    postProjectTime: values.postProjectTime.format('YYYY-MM-DD'),
                    projectId,
                    remarks: values.remarks,
                    resigningStaffList: (values.resigningStaffList||[]).map(v => {
                        v.resigningTime = v.resigningTime.format('YYYY/MM/DD')
                        return v
                    })
                }
                this.setState({ btnLoading: true })
                this.$http.addPostProject(param).then(res => {
                    this.setState({ btnLoading: false })
                    if (res&&res.errorCode == 200) {
                        message.destroy();
                        message.success(res.message);
                        this.props.closeModal();
                        this.setState({
                            visible: false
                        })
                    }
                })
            }
        });
    }

    handleReturnStockBtnCancle = () => {
        this.props.form.resetFields();
        this.setState({ visible: false })
    }
    /**
* @description: 自动回显数据
* @return:
*/
    renderDetailKeyHTML = () => {
        const { projectInfo } = this.state;
        return [
            { name: '项目名称：', key: 'projectName' },
            { name: '项目类型：', key: 'projectTypeStr' },
            { name: '业务类型：', key: 'businessTypeStr' }
        ].map(item => {
            return (
                <Col span={8} key={item.key}>
                    <div className="detailbox">
                        <span className="label">{item.name}</span>
                        <div className="value" >
                            {
                                projectInfo[item.key] ?
                                    <TooltipComponent
                                        text={projectInfo[item.key]}
                                        width={120}
                                    /> : '--'
                            }
                        </div>
                    </div>
                </Col>
            );
        });
    };
    handleModeChange = e => {
        this.setState({
            courierDate: e.format('YYYY-MM-DD')
        });
        console.log(222);
        return e;
    };
    openModal = () => {
        const { projectId, detailInfo } = this.props;
        this.setState({ visible: true, Loading: true }, () => {
            this.$http.pageStaffRosterByProjectId({ projectId }).then(res => {
                this.setState({ Loading: false })
                if (res&&res.errorCode == 200) {
                    this.setState({
                        dataList: res.data,
                        projectInfo: detailInfo
                    })
                }
            })
        })
    }
    render() {
        const { visible, dataSource, courierDate, btnLoading, dataList } = this.state;
        // const { form, type, record,dataList } = this.props;
        const { getFieldDecorator } = this.props.form;
        const columns = [{
            title: '序号',
            width: 80,
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: '工号',
            dataIndex: 'staffCode',
            key: 'staffCode',
            width: 120,
            render: t => t ? <TooltipComponent width={100} text={t} /> : '-',
        },
        {
            title: '姓名',
            dataIndex: 'staffName',
            key: 'staffName',
            width: 120,
            render: t => t ? <TooltipComponent width={100} text={t} /> : '-',
        }, {
            title: '入职时间',
            dataIndex: 'onBoardingTime',
            key: 'onBoardingTime',
            width: 120,
            render: t => t ? <TooltipComponent width={100} text={t} /> : '-',
        },
        {
            title: '离职时间',
            dataIndex: 'leaseStartTime',
            key: 'leaseStartTime',
            width: 180,
            render: (t, r, i) => {
                return (
                    <Form.Item
                        key={i}
                        wrapperCol={{ span: 24 }}
                        style={{ marginBottom: 0 }}
                    >
                        {
                            getFieldDecorator(`resigningStaffList[${i}].staffId`, { initialValue: dataList[i].staffId || undefined }),
                            getFieldDecorator(`resigningStaffList[${i}].staffName`, { initialValue: dataList[i].staffName || undefined }),
                            getFieldDecorator(`resigningStaffList[${i}].staffCode`, { initialValue: dataList[i].staffCode || undefined }),
                            getFieldDecorator(`resigningStaffList[${i}].onBoardingTime`, { initialValue: dataList[i].onBoardingTime || undefined })
                            
                        }
                        {getFieldDecorator(`resigningStaffList[${i}].resigningTime`, {
                            rules: [{ required: true, message: '请选择离职时间' }]
                        })(
                            <DatePicker
                                disabled={courierDate == '' ? true : false}
                                style={{ width: '100%' }}
                                placeholder="请选择离职时间"
                                disabledDate={e => e && (e < moment(courierDate).subtract(1, "months") || e > moment(courierDate).subtract(-1, "days"))}
                            />
                        )}
                    </Form.Item>

                )
            },
        }]

        return (
            <>
                <Button type="primary" onClick={() => this.openModal()}>结项申请</Button>
                {visible&&<Modal
                    className="EndProjectModal"
                    title="结项申请"
                    visible={visible}
                    width={700}
                    onCancel={this.handleReturnStockBtnCancle}
                    footer={[
                        <div key='EndProjectModal'>
                            <Button key="cancle" onClick={() => this.handleReturnStockBtnCancle()}>取消</Button>
                            <Button key="sure" onClick={this.handleAddSure} loading={btnLoading} type="primary" >提交 </Button>
                        </div>
                    ]}
                    maskClosable={false}
                >
                    <Row>
                        {this.renderDetailKeyHTML()}
                    </Row>
                    <Form >
                        <Form.Item label="结项时间">
                            {getFieldDecorator('postProjectTime', {
                                getValueFromEvent: this.handleModeChange,
                                rules: [
                                    {
                                        type: 'object',
                                        required: true,
                                        message: '请选择结项时间'
                                    }
                                ]
                            })(
                                <DatePicker
                                    disabledDate={e => e && e < moment().subtract(1, "days")}
                                    format="YYYY-MM-DD"
                                    style={{ width: '60%' }}
                                    placeholder="请选择结项时间"
                                />
                            )}
                            <span className="DateSpan"> 员工离职时间不得大于项目结项时间</span>
                        </Form.Item>

                        {/* 人员列表 */}
                        <div className="tableHeight">
                            <Table
                                rowKey="staffId"
                                className="leaseApplyList"
                                pagination={false}
                                columns={[...columns]}
                                dataSource={dataList}
                            />
                        </div>
                        

                        {/* 项目结项说明 */}

                        <TextFieldInput
                            form={this.props.form}
                            showLength={true}
                            label="项目结项说明"
                            placeholder="请输入"
                            required={true}
                            allowClear
                            field="remarks"
                            maxLength={500}
                            autoSize={{ minRows: 4, maxRows: 5 }}
                        />
                    </Form>
                </Modal>
                }
            </>
        );
    }
}

export default Form.create()(EndProject);