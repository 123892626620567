/*
 * @Description: 部门毛利率统计数据
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-17 10:40:26
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-13 18:28:39
 */

import React from 'react';
import TooltipComponent from '../../../components/TooltipComponent'; // 文本溢出提示

import { isPositiveIncome } from '../../../utils/common';
import { baseInput7 } from '../../../utils/validate';
import moment from 'moment';

/**
 * @description: 搜索配置项
 * @author: 文倩
 */
const myDate = new Date();
const tYear = myDate.getFullYear();
const tMonth = myDate.getMonth() + 1;
export const searchFormDataApply = [
    {
        label: '统计时间',
        name: 'month',
        type: 'MonthPicker',
        format: "YYYY-MM",
        initialValue: moment(tYear + '-' + tMonth, 'YYYY-MM'),
        placeholder: "请选择统计时间"
    }, {
        label: '统计部门',
        name: 'deptIds',
        type: 'treeSelect',
        selectData: [],
        placeholder: "全部"
    }
]


/**
 * @description: 列表配置项
 * @author: 文倩
 */
export const columns = [{
    title: '序号',
    width: 60,
    fixed: "left",
    render: (text, record, index) => `${index + 1}`,
}, {
    title: '部门名称',
    dataIndex: 'deptName',
    key: 'deptName',
    width: 160,
    fixed: "left",
    render: t => t ? <TooltipComponent width={130} text={t} /> : '-'
},
{
    title: '相对收入',
    key: 'purchaseTotal',
    dataIndex: 'purchaseTotal',
    width: 150,
    render: t => t ? <TooltipComponent width={140} text={isPositiveIncome(t)} /> : '-',
}, {
    title: '税后金额',
    key: 'afterTax',
    dataIndex: 'afterTax',
    width: 120,
    render: t => t ? <TooltipComponent width={120} text={isPositiveIncome(t)} /> : '-',
}, {
    title: '当月薪资',
    key: 'salary',
    dataIndex: 'salary',
    width: 130,
    render: t => t ? <TooltipComponent width={120} text={isPositiveIncome(t)} /> : '-',
}, {
    title: '社保公积金成本',
    key: 'socialSecurityFund',
    dataIndex: 'socialSecurityFund',
    width: 160,
    render: t => t ? <TooltipComponent width={150} text={isPositiveIncome(t)} /> : '-',
},
{
    title: '年终奖分摊',
    key: 'yearEndBonusAverage',
    dataIndex: 'yearEndBonusAverage',
    width: 160,
    render: t => t ? <TooltipComponent width={150} text={isPositiveIncome(t)} /> : '-',
},
{
    title: '工位费',
    key: 'officeCubicleMoney',
    dataIndex: 'officeCubicleMoney',
    width: 140,
    render: t => t ? <TooltipComponent width={130} text={isPositiveIncome(t)} /> : '-',
},
{
    title: '软件分摊',
    key: 'softwareMoney',
    dataIndex: 'softwareMoney',
    width: 140,
    render: t => t ? <TooltipComponent width={130} text={isPositiveIncome(t)} /> : '-',
}, {
    title: '资产损耗',
    key: 'assetLoss',
    dataIndex: 'assetLoss',
    width: 140,
    render: t => t ? <TooltipComponent width={130} text={isPositiveIncome(t)} /> : '-',
}, {
    title: '费用合计',
    key: 'addUpTo',
    dataIndex: 'addUpTo',
    width: 140,
    render: t => t ? <TooltipComponent width={130} text={isPositiveIncome(t)} /> : '-',
}, {
    title: '毛利',
    key: 'grossMargin',
    dataIndex: 'grossMargin',
    width: 140,
    render: t => t ? <TooltipComponent width={130} text={isPositiveIncome(t)} /> : '-',
}, {
    title: '毛利率',
    key: 'grossProfitRate',
    dataIndex: 'grossProfitRate',
    width: 140,
    render: t => t ? <TooltipComponent width={130} text={t + '%'} /> : '-',
}]


/**
 * @description: 统计汇总字段
 * @author: 赵娟
 */
export const statisticsText = [
{
    title: '相对收入',
    key: 'purchaseTotal'
}, {
    title: '税后金额',
    key: 'afterTax'
}, {
    title: '当月薪资',
    key: 'salary'
}, {
    title: '社保公积金成本',
    key: 'socialSecurityFund'
},
{
    title: '年终奖分摊',
    key: 'yearEndBonusAverage'
},
{
    title: '工位费',
    key: 'officeCubicleMoney'
},
{
    title: '软件分摊',
    key: 'softwareMoney'
}, {
    title: '资产损耗',
    key: 'assetLoss'
}, {
    title: '费用合计',
    key: 'addUpTo'
}, {
    title: '毛利',
    key: 'grossMargin'
}, {
    title: '毛利率',
    key: 'grossProfitRate'
}]