
/*
 * @Description: 流程配置页面
 * @Author: 文倩
 * @Date: 2020-06-02 10:40:39
 * @LastEditTime: 2020-07-30 12:25:39
 * @LastEditor: 文倩
 */

import React, { Component, Fragment } from 'react';
import { Table, Spin } from 'antd';

import NoData from '../../../components/noData';
import { processColumns } from '../contants';

import TableConfig from './component/tableConfig';

import Revise from './revise/index';
import './index.less';

export default class IdleAssets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tableList: []   //列表全部数据
        }
    }

    componentDidMount() {
        this.queryTableList();
    }


    //搜索查询列表
    queryTableList = async () => {
        this.setState({
            loading: true
        })
        let list = [];
        const res = await this.$http.getProcessList();
        if (res && res.errorCode == 200) {
            if (this.$common.isArray(res.data)) {
                res.data.map(item => {

                    list.push({
                        procDefValue: item.procDefValue,
                        procDefKey: item.procDefKey,
                        procConfigVOS: this.checkProcessData(item.procConfigVOS)
                    });
                });
            }


            this.setState({
                tableList: list
            });
        }
        this.setState({
            loading: false
        })
    }


    //整理流程配置数据
    checkProcessData = (list) => {
        
        let newList = [];
        let tableObj = {
            ...list[0]
        };
        if (this.$common.isArray(list)) {
            list.map((item, i) => {
                
                eval(`tableObj.taskDefKey${i + 1}="${String(item.taskDefKey)}"`);
                eval(`tableObj.taskDefValue${i + 1}="${String(item.taskDefValue)}"`);
                eval(`tableObj.id${i + 1}="${String(item.id)}"`);
                eval(`tableObj.groupId${i + 1}="${String(item.groupId)}"`);
                eval(`tableObj.groupName${i + 1}="${String(item.groupName)}"`);
            });
            newList.push(tableObj);
        }
        
        return newList;
    }

    //返回列表数据
    returnTablistArray = (key, tableList) => {
        let list = [];
        tableList.map(ite => {
            if (key == ite.procDefKey) {
                list = ite.procConfigVOS;
            }
        })
        return list;
    }

    //操作列返回
    operateColumns = () => {
        return {
            title: '操作',
            width: 170,
            className: "operateconfig",
            fixed: "right",
            render: (record) => {
                return (
                    <div className="butt">
                        <Revise
                            formData={record}
                            handleSubmit={this.queryTableList}
                        />
                    </div>
                )
            }
        }
    }

    render() {
        const { loading, tableList } = this.state;

        return (
            <Fragment>
                <div className={loading ? "spinLoading" : ""}><Spin spinning={loading} /></div>
                <div className="process">
                    <h4>流程配置管理</h4>
                    {processColumns.map((item, i) => {
                        return (
                            <div className="card" key={i}>
                                <h3 className="card-title"><span />{item.name}</h3>
                                <Table
                                    rowKey="procDefKey"
                                    columns={[...item.columns, this.operateColumns()]}
                                    pagination={false}
                                    scroll={{ x: '100%' }}
                                    dataSource={
                                        this.returnTablistArray(item.key, tableList)
                                    }
                                    locale={{
                                        emptyText: (
                                            <div className="noData" style={{ width: '100%' }}>
                                                <NoData width={200} />
                                            </div>
                                        )
                                    }}
                                />
                                {/* <TableConfig
                                    columns={[...item.columns, this.operateColumns()]}
                                    dataSource={
                                        this.returnTablistArray(item.key, tableList)
                                    } /> */}
                            </div>
                        )
                    })}
                </div>
            </Fragment>
        );
    }
}

