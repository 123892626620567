/*
 * @Description: 导入提示弹窗
 * @Author: 文倩
 * @Date: 2020-06-02 10:40:39
 * @LastEditTime: 2020-08-07 17:13:45
 * @LastEditors: 文倩
 */
import React, { Component } from "react";
import { Modal, Button, message, Icon } from "antd";

import "./index.less";

/*
 * @Description: 组件所需传入参数
 * @type:String               importing // 导入中   imported //导入成功  importerror//导入失败
 * @visible:Boolean           false  //控制弹窗显隐
 * @currentType:String        ""  当前是否是闲置下载    //选传
 * @importNum:String          '2'  //导入错误时，错误的数量
 * @onCancel:function         ()=>{}   //点击关闭弹窗
 * @downLoadError:function    ()=>{}   //导入失败时，下载原因的回调  只需传入方法和参数
 *                            ps:()=>{this.download({id:id})}
 */

class ImportAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //错误数据导出
  downLoadErrorFile = async () => {
    const query = {
      importId: this.props.importId,
    };

    const res = await this.props.downLoadError(query);

    if (res && res.request && res.request.responseType == "blob") {
      var reader = new FileReader();
      reader.onload = () => {
        try {
          var content = JSON.parse(reader.result); //内容就在这里
          if (content && content.code != 200) {
            message.warning(content.message);
          }
        } catch {
          this.$common.getExcel(res);
        }
      };
      reader.readAsText(res.data);
    }
  };

  render() {
    const { type, visible, onCancel, onOk, successNum, errorNum } = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
        wrapClassName="alertContent"
      >
        <div className="modalContent">
          <Icon type="close-circle" />
          <div>
            <p>
              成功{successNum}条，失败{errorNum}条
            </p>
            {type == "importerror" && (
              <Button type="link" onClick={this.downLoadErrorFile}>
                下载失败文件
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default ImportAlert;
