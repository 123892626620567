/*
 * @Description: 
 * @Author: 文倩
 * @Date: 2020-06-01 10:25:07
 * @LastEditTime: 2020-06-05 18:32:00
 * @LastEditors: 文倩
 */

import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import reducer from '../src/reducers/index';

export const initStore = (initialState) => {
  return createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)))
}