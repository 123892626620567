import React from "react";
import noData from "../assets/image/noData2x.png";
export default (props) => {
  return (
    <div style={{ width: props.width, margin: "0 auto", textAlign: "center" }}>
      <img src={noData} alt="" style={{ width: "100%" }} />
      <p style={{ color: "#717E99", fontSize: props.fontSize }}>暂无数据</p>
    </div>
  );
};
