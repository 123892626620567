/*
 * @文档描述: 层级薪资 --- 下载导入模板
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-07-29 14:09:21
 * @最后修改人: 文倩
 */
import React, { Component } from 'react';
import { Button, message } from 'antd';


class DownLoad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isImportLoading: false
        }
    }
    /**
     * @函数描述: 下载导入模板
     */
    handleDownload = async () => {
        this.setState({
            isImportLoading: true
        });
        const res = await this.$http.downLoadTemp();
        if (res && res.request && res.request.responseType == 'blob') {
            var reader = new FileReader();
            reader.onload = () => {
                try {
                    var content = JSON.parse(reader.result);//内容就在这里
                    if (content && content.errorCode != 200) {
                        message.warning(content.message);
                    }
                } catch{
                    this.$common.getExcel(res);
                }
            };
            reader.readAsText(res.data);
        }
        if (res) {
            this.setState({
                isImportLoading: false
            });
        }
    }

    render() {
        const { isImportLoading } = this.state;

        return (
            <Button
                onClick={this.handleDownload}
                loading={isImportLoading}
            >
                模板下载
            </Button>
        );
    }
}
export default DownLoad;
