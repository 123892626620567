/*
 * @Description: 入职离职表
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-17 10:24:24
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-25 17:43:37
 */

import React, { Component } from 'react';
import { Button, message } from 'antd';
import moment from 'moment';

import Search from '../../../components/searchForm';
import Table from '../../../components/table/index';
import { searchFormDataApply, columns } from './data';

import '../StatisticalReport.less'
class EntryLeave extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            loading: false,
            isExportShow: false,
            pageNo: 1,
            pageSize: 10,
            searchData: searchFormDataApply,
            searchValue: {},
            dataSource: []
        }
    }

    componentDidMount() {
        this.queryTableList();
    }

    /**
     * @description: 查询列表数据
     * @param {type} 
     * @return {type} 
     * @author: 文倩
     */
    queryTableList = (page = this.state.pageNo, pageSize = this.state.pageSize) => {
        const { searchValue } = this.state;
        const query = {
            page,
            pageSize,
            ...searchValue
        }
        this.setState({
            loading: true
        });
        this.$http.getEntryList(query).then(res => {
            if (res && res.errorCode == 200) {
                this.setState({
                    dataSource: res.data.rows,
                    total: res.data.total,
                    pageNo: page,
                    pageSize,

                })
            }
            if (res) {
                this.setState({
                    loading: false
                });
            }
        })
    }


    /**
     * @description: 查询搜索列表数据
     * @param {type} 
     * @return {type} 
     * @author: 文倩
     */
    querySearch = (val) => {
        const params = {
            staffName: val && val.staffName || null,
            staffCode: val && val.staffCode || null,
            timeType: val && val.timeType != 'null' ? val.timeType : null,
            timeStart: val && val.month && moment(val.month[0]).format('YYYY-MM-DD 00:00:00') || null,
            timeEnd: val && val.month && moment(val.month[1]).format('YYYY-MM-DD 23:59:59') || null,
        }
        this.setState({
            searchValue: params,
            pageNo: 1
        }, () => {
            this.queryTableList();
        })
    }


    /**
     * @description: 导出入职离职列表
     * @param {type} 
     * @return {type} 
     * @author: 文倩
     */
    exportExcel = () => {
        const { searchValue } = this.state;
        const query = {
            ...searchValue
        }
        this.setState({
            isExportShow: true
        });
        this.$http.exportEntry(query).then(res => {
            if (res && res.request && res.request.responseType == 'blob') {
                var reader = new FileReader();
                reader.onload = () => {
                    try {
                        var content = JSON.parse(reader.result);//内容就在这里
                        if (content && content.code != 200) {
                            message.warning(content.message);
                        }
                    } catch{
                        this.$common.getExcel(res);
                    }
                };
                reader.readAsText(res.data);
            }
            if (res) {
                this.setState({
                    isExportShow: false
                });
            }
        })
    }


    render() {
        const { total, pageNo, pageSize, searchData, loading, dataSource, isExportShow } = this.state;
        return (
            <div className="ProjectListBox StatisticalReport">
                <div className="account">
                    <div className="searchbox">
                        <Search
                            tabList={[{ value: "入职离职表" }]}
                            FormData={searchData}
                            handleSubmit={(values) => { this.querySearch(values) }} />
                    </div>

                    <div className="operation-table">
                        {this.$common.isArray(dataSource) &&
                            <div className="Space8">
                                <Button
                                    className="btnColor"
                                    loading={isExportShow}
                                    onClick={this.exportExcel}
                                >
                                    导出列表
                            </Button>
                            </div>
                        }
                        <Table
                            keyName="staffResigningId"
                            columns={columns}
                            dataSource={dataSource}
                            scroll={{ x: "100%" }}
                            loading={loading}
                            total={total}
                            current={pageNo}
                            pageSize={pageSize}
                            isPagination={true}
                            handleChangePage={this.queryTableList}
                            handleChangePageSize={this.queryTableList}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default EntryLeave;