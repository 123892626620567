/*
 * @Description: 项目看板详情表头数据
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-15 10:07:35
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-10-22 09:58:51
 */

import React from "react";
import TooltipComponent from "../../../../../components/TooltipComponent"; // 文本溢出提示

import { isPositiveIncome } from "../../../../../utils/common";
/**
 * @description: 核准金额历史数据表头信息
 * @author: 陈金金
 */
export const ApprovedColumns = [
  {
    title: "时间",
    dataIndex: "approvalTime",
    key: "approvalTime",
    width: 130,
    render: (t) => (t ? <TooltipComponent width={120} text={t} /> : "-"),
  },
  {
    title: "核准后项目收入",
    key: "approvalIncome",
    dataIndex: "approvalIncome",
    width: 180,
    render: (t) =>
      t ? <TooltipComponent width={170} text={isPositiveIncome(t)} /> : "-",
  },
  {
    title: "核准后项目成本",
    key: "approvalCost",
    dataIndex: "approvalCost",
    width: 120,
    render: (t) =>
      t ? <TooltipComponent width={120} text={isPositiveIncome(t)} /> : "-",
  },
];

/**
 * @description: 历史变更记录表头信息
 * @author: 陈金金
 */
export const changeColumns = [
  {
    title: "变更时间",
    dataIndex: "changeTime",
    key: "changeTime",
    width: 130,
    render: (t) => (t ? <TooltipComponent width={120} text={t} /> : "-"),
  },
  {
    title: "变更项",
    key: "changeFieldStr",
    dataIndex: "changeFieldStr",
    width: 160,
    render: (t) => (t ? <TooltipComponent width={170} text={t} /> : "-"),
  },
  {
    title: "变更内容",
    key: "changeContent",
    dataIndex: "changeContent",
    width: 120,
    render: (t) => (t ? <TooltipComponent width={120} text={t} /> : "-"),
  },
];
/**
 * @description: 人员信息详情信息
 * @author: 陈金金
 */
export const userInfoKey = [
  {
    key: "staffName",
    name: "姓名：",
    before: "",
    after: "",
  },
  {
    key: "staffCode",
    name: "工号：",
    before: "",
    after: "",
  },
  {
    key: "onBoardingTime",
    name: "入职时间：",
    before: "",
    after: "",
  },
  {
    key: "beyondWorkingAge",
    name: "博彦司龄：",
    before: "",
    after: "",
  },
  {
    key: "sexStr",
    name: "性别：",
    before: "",
    after: "",
  },
  {
    key: "contactNumber",
    name: "联系电话：",
    before: "",
    after: "",
  },
  {
    key: "idNumber",
    name: "身份证号码：",
    before: "",
    after: "",
  },
  {
    key: "groupName",
    name: "组别：",
    before: "",
    after: "",
  },
  {
    key: "wbsName",
    name: "WBS：",
    before: "",
    after: "",
  },
  {
    key: "identityStr",
    name: "人员身份：",
    before: "",
    after: "",
  },
  {
    key: "payTypeStr",
    name: "付费类型：",
    before: "",
    after: "",
  },
  {
    key: "cityName",
    name: "办公城市：",
    before: "",
    after: "",
  },
  {
    key: "serveNatureName",
    name: "服务性质：",
    before: "",
    after: "",
  },
  {
    key: "rank",
    name: "层级：",
    before: "",
    after: "",
  },
  {
    key: "socialSecurityFundCost",
    name: "社保公积金成本：",
    before: "¥",
    after: "",
  },
  {
    key: "purchasePrice",
    name: "采购价：",
    before: "¥",
    after: "",
  },
  {
    key: "turnSalary",
    name: "转正系统薪资：",
    before: "¥",
    after: "",
  },
  //   {
  //     key: "performanceStandard",
  //     name: "绩效考核基准：",
  //     before: "¥",
  //     after: "",
  //   },
  {
    key: "nonSystemFixedSalaryIncrease",
    name: "非系统固定涨薪：",
    before: "¥",
    after: "",
  },
  {
    key: "personalPerformanceBenchmarks",
    name: "个人绩效基准值：",
    before: "¥",
    after: "",
  },
  {
    key: "projectPerformancePoolBenchmarks",
    name: "项目绩效池基准值：",
    before: "¥",
    after: "",
  },
  {
    key: "salaryTotal",
    name: "薪资合计：",
    before: "¥",
    after: "",
  },
  {
    key: "purchaseSalaryCeil",
    name: "薪资上限参考值：",
    before: "¥",
    after: "",
  },
  {
    key: "remarks",
    name: "超上限备注说明：",
    before: "",
    after: "",
  },
];
