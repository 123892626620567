/*
 * @Description: 
 * @Author: 文倩
 * @Date: 2020-07-08 15:46:25
 * @LastEditTime: 2020-07-14 19:05:06
 * @LastEditor: 文倩
 */

import jinjinRouter from './jinjin/router';
//权限管理
import AccountManagement from '../pages/systemManagement/AccountManagement'; //账号管理
import RoleManagement from '../pages/systemManagement/RoleManagement'; //角色管理

//信息维护
import Architecture from '../pages/InfoMaintenance/Architecture'; //组织架构
import RosterInfo from '../pages/InfoMaintenance/RosterInfo';   //花名册信息维护
import BasicInfo from '../pages/InfoMaintenance/BasicInfo';    //基础信息维护
import ProcessConfig from '../pages/InfoMaintenance/ProcessConfig'; //流程配置

export default [
    {
        name: '权限管理-账号管理',
        path: '/SystemManagement/AccountManagement', // 账号管理
        exact: true,
        openAccessMenu: true,
        component: AccountManagement
    },
    {
        name: '权限管理-角色管理',
        path: '/SystemManagement/RoleManagement', // 角色管理
        exact: true,
        openAccessMenu: true,
        component: RoleManagement
    },
    {
        name: '信息维护-组织架构管理',
        path: '/infoMaintenance/Architecture', //组织架构
        exact: true,
        openAccessMenu: true,
        component: Architecture
    },
    {
        name: '信息维护-花名册信息维护',
        path: '/infoMaintenance/RosterInfo', //花名册信息维护
        exact: true,
        openAccessMenu: true,
        component: RosterInfo
    },
    {
        name: '信息维护-基础信息维护',
        path: '/infoMaintenance/BasicInfo', //基础信息维护
        exact: true,
        openAccessMenu: true,
        component: BasicInfo
    },
    {
        name: '信息维护-流程配置管理',
        path: '/infoMaintenance/ProcessConfig', // 流程配置
        exact: true,
        openAccessMenu: true,
        component: ProcessConfig
    },
    ...jinjinRouter
];