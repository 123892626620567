/*
 * @Author: 文倩
 * @Date: 2020-07-08 16:21:50
 * @LastEditTime: 2020-07-08 16:24:18
 * @LastEditor:文倩
 * @Description: 无需权限控制的路由配置
 */ 

//登录
import Login from "../pages/Login";

//无权限
import Page403 from '../pages/StaticPages/Page403';

export default [
    {
        title: '登录',
        name:"login",
        path: '/',           //登录
        exact: true,
        component: Login
    },
    {
        title: '登录',
        name:"Page403",
        path: '/Page403',           //无权限页面
        exact: true,
        component: Page403
    },
]