/*
 * @Description: 花名册页面
 * @Autor: 文倩
 * @Date: 2020-07-13 14:24:33
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-28 13:49:49
 */
import React, { Component, Fragment } from "react";


import Search from '../../../components/searchForm';
import Table from '../../../components/table';
import { rosterTab, rosterColumns } from '../contants';

import DownLoadRoster from './downLoadTemp';  //花名册信息---下载导入模板
import ImportRoster from './importFile'; //花名册信息--导入

import './index.less';

class RosterInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            pageNo: 1,
            pageSize: 10,
            loading: false,
            currentStatus: "1",
            tableList: [],
        }
        this.operateColumns = [{
            title: '操作',
            width: 120,
            fixed: 'right',
            render: (record) => {
                return (
                    <div className="butt">
                        <span className="operation-wrap">
                            {record.failedNum > 0 &&
                                <span><a href={record.fileKeyUrl}>下载失败文件</a></span>
                                }
                            {record.successNum == record.sumNum &&
                                <span>{record.result}</span>
                                }
                        </span>
                    </div>
                )
            }
        }]
    }

    componentDidMount() {
        this.queryTableList();
    }


    /**
     * @description: 查询表格数据
     * @param {type} 
     * @return: 
     * @author: 文倩
     */

    queryTableList = (pageNo = this.state.pageNo, pageSize = 10) => {
        const { currentStatus } = this.state;
        const params = {
            page: pageNo,
            pageSize,
            userType: currentStatus
        };

        this.setState({
            loading: true
        })
        this.$http.getRosterInfoList(params).then(res => {
            if (res && res.errorCode == 200) {
                this.setState({
                    tableList: res.data.rows,
                    total: res.data.total,
                    pageNo,
                    pageSize
                });
            }
            if (res) {
                this.setState({
                    loading: false,
                });
            }
        });
    }


    /**
     * @description: 切换列表tabs
     * @param {type} key:"0"
     * @return: 
     * @author: 文倩
     */
    hansleChangeTabs = key => {
        this.setState({
            pageNo: 1,
            currentStatus: key,
        }, () => this.queryTableList(1, 10));
    };


    /**
     * @description: 导入不同文件
     * @param {type} 
     * @return {type} 
     * @author: 文倩
     */
    importHttp = (val) => {
        const { currentStatus } = this.state;
        switch (currentStatus) {
            case "1":
                return this.$http.importRoster1(val);
            case "2":
                return this.$http.importRoster2(val);
            case "3":
                return this.$http.importRoster3(val);
            default:
                return null;
        }

    }

    render() {
        const { tableList, loading, total, pageNo, pageSize, currentStatus } = this.state;

        return (
            <Fragment>
                <Search
                    tabList={rosterTab}
                    hansleChangeTabs={this.hansleChangeTabs} />
                <div className="roster-table operation-table">
                    <DownLoadRoster
                        template={currentStatus} />
                    <ImportRoster
                        title="文件导入"
                        text="文件导入"
                        importFile={val => this.importHttp(val)}
                        importOk={this.queryTableList} />
                    <Table
                        rowKey="asyncResultId"
                        loading={loading}
                        scroll={{ x: "100%" }}
                        columns={[...rosterColumns, ...this.operateColumns]}
                        dataSource={tableList}
                        total={total}
                        current={pageNo}
                        pageSize={pageSize}
                        isPagination={true}
                        handleChangePage={this.queryTableList}
                        handleChangePageSize={this.queryTableList} />
                </div>
            </Fragment>
        );
    }
}

export default RosterInfo;