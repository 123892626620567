/*
 * @Description: 人员基础信息调整
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:18:49
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-09-08 11:50:51
 */ 


import React, { Component } from 'react';
import { Button, Select, Row, Col, Input, Form,Modal,message } from 'antd';
import './Components.less';
import _ from 'lodash';
import TooltipComponent from '../../../../../components/TooltipComponent'; // 文本溢出提示
import TextFieldInput from '../../../../../components/sceneInput/TextAreaInput';
import BaseInput from '../../../../../components/baseInput';
import { baseInput1} from '../../../../../utils/validate.js';
import { isArray } from '../../../../../utils/common';
const {Option}=Select;
class BasicInfoChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 3,
            visible:false,
            loading: false,
            pageNo: 1,
            pageSize: 10,
            isCard: false,
            searchValue: {},
            detailData: {},
            selectedRowKeys:[],
            statusList:{},
            statusKey:'',
            CityList:[],
            serveList:[],
            serveNature:'',
            cityId:-1,
            RankInfoList:[],
            btnLoading:false
        }
    }
    /**
     * @description: 人员搜索
     * @author: 陈金金
     */
    handleAddSure=()=>{
        const {row,projectid}=this.props;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if(!err){
                const param={
                    staffId:row.staffId,
                    staffCode:row.staffCode,
                    staffName:row.staffName,
                    projectId:projectid,
                    remarks:values.remarks,
                    adjustAfter:values.adjustAfter,
                    adjustBefore:values.adjustBefore,
                    adjustType:values.adjustType.key
                  }
                  this.setState({btnLoading:true})
                  this.$http.addStaffInfoAdjust(param).then(res=>{
                      this.setState({btnLoading:false})
                    if(res&&res.errorCode==200){
                      message.destroy();
                      message.success(res.message);
                      this.props.closemodal();
                      this.handleReturnStockBtnCancle();
                    }
                })
                
            }
        });
    }
   
    handleReturnStockBtnCancle=()=>{
        this.props.form.resetFields();
        this.setState({visible:false,statusKey:''})
    }
       /**
   * @description: 自动回显数据
   * @return:
   */
  renderDetailKeyHTML = () => {
    const { detailData } = this.state;
    return [
        {name:'调整人姓名：',key:'staffName'},
        {name:'调整人工号：',key:'staffCode'},
    ].map(item => {
        return (
            <Col span={24} key={item.key}>
                <div className="detailbox">
                    <span className="label">{item.name}</span>
                    <div className="value" >
                        {
                            detailData[item.key] ?
                                <TooltipComponent
                                    text={detailData[item.key]}
                                    width={200}
                                /> : '--'
                        }
                    </div>
                </div>
            </Col>
        );
    });
};
openModal=()=>{//
    const {projectid,row}=this.props;
    this.setState({visible:true},()=>{
        this.setState({
            detailData:row,
        },()=>{
            if(row.identity=='OFFICIAL') this.setState({statusKey:'OTHER_ADJUST'})
        })
        this.getCityList();
        this.getDicCacheList();
    })
}
  /**
     * @description: 获取下拉枚举值
     * @param {type} 
     * @return: 
     * @author: 陈金金
     */
    getDicCacheList=()=>{
        this.$http.queryDicCacheList(['STAFF_INFO_ADJUST','IDENTITY']).then(res=>{
            if(res&&res.errorCode==200){
                this.setState({
                    statusList:res.data
                })
            }
        })
    }
    handleChange=(e)=>{
        // this.props.form.resetFields();
        
       
        this.props.form.setFieldsValue({
            [`adjustAfter`]: undefined,
        });
        this.setState({
            statusKey:e.key
        })
    }
    /**
     * @description: 获取城市列表
     * @param {type} 
     * @return: 
     * @author: 陈金金
     */
    getCityList=()=>{
        this.$http.CityList().then(res=>{
            if(res&&res.errorCode==200){
                this.setState({
                    CityList:res.data||[]
                })
            }
        })
    }
    /**
     * @description: 办公城市和服务性质联动
     * @param {type} 
     * @return: 
     * @author: 陈金金
     */
    linkageChange=(param,type)=>{
        this.setState({
            RankInfoList:[]
        })
        this.props.form.setFields({
            ['adjustAfter']: {
                value: undefined
            }
        });  
        if(type=='serveList'){ //选择办公城市联动
            this.props.form.setFields({
                ['serveNature']: {
                    value: undefined
                },
            });  
          
        }
        this.$http.RankInfoList(param).then(res=>{
            if(res&&res.errorCode==200){
                this.setState({
                    [type]:res.data||[],
                    ...param
                })
                
            }
        })
    }
    render() {
        const { serveList,CityList,RankInfoList,cityId,serveNature,visible,statusList,statusKey,detailData,btnLoading } = this.state;
        const { form,key } = this.props;
        const {getFieldDecorator}=this.props.form

        return (
            <>
                 <span 
                 className="span" 
                 key={key}
                 onClick={()=>this.openModal()}
                 >
                     人员基础信息调整
                </span>
               {
                   visible&&<Modal
                   className="BasicInfoChangeModal"
                   title="人员基础信息调整"
                   visible={visible}
                   width={480}
                   footer={[
                    <div key='BasicInfoChangeModal'>
                        <Button key="cancle" onClick={() => this.handleReturnStockBtnCancle()}>取消</Button>
                        <Button key="sure" onClick={this.handleAddSure} loading={btnLoading} type="primary" >提交 </Button>
                    </div>
                   ]}
                   onCancel={this.handleReturnStockBtnCancle}
                   maskClosable={false}
                   okText="提交"
               >
                   <Row>
                       {this.renderDetailKeyHTML()}
                   </Row>
                   <Form >
                       <Form.Item label="调整类型">
                           {getFieldDecorator('adjustType', {
                               initialValue:detailData&&detailData.identity=='OFFICIAL'?{key:'OTHER_ADJUST'}:undefined,
                               rules:[{required:true,message:'请选择调整类型'}]
                           })(
                            <Select placeholder="请选择" 
                                allowClear
                                labelInValue
                                disabled={detailData&&detailData.identity=='OFFICIAL'}
                                onChange={e=>this.handleChange(e)}
                            >
                                {
                                isArray(statusList['STAFF_INFO_ADJUST'])&&statusList['STAFF_INFO_ADJUST'].map(v=>{
                                        return <Option key={v.key} value={v.key}>{v.value}</Option>
                                    })
                                }
                            </Select>
                           )}
                       </Form.Item>
                       {
                           statusKey=='IDENTITY_ADJUST'&& <div>
                           <Row gutter={20}>
                               <Col span={10}>
                               <div className="flexstart detailbox">
                                    <div className='label'>当前身份：</div>
                                    <div className='value'>
                                        {detailData.identity=='INTERNSHIP'?'实习生':'正式员工'}
                                    </div>
                                    <Form.Item >
                                        {getFieldDecorator('adjustBefore', {
                                           initialValue:detailData.identity
                                        })(
                                            <Input style={{display:'none'}}/>
                                        )}
                                    </Form.Item>
                               </div>
                               </Col>
                               <Col span={14}>
                                   <div className="flexend detailbox">
                                           <div className='label'>转正后工号：</div>
                                           <BaseInput
                                               form={form}
                                               required={true}
                                               disabled={detailData.identity!='INTERNSHIP'}
                                               placeholder="请输入"
                                               validatorName={baseInput1}
                                               field="adjustAfter"
                                               maxLength={20}
                                               style={{width:130}}
                                               message="请输入转正后工号"
                                           />
                                   </div>
                               </Col>
                           </Row>
                       </div>
                       }
                        {
                           statusKey=='OTHER_ADJUST'&& <div>
                                <div>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                        <div className="flexstart detailbox mB32">
                                                <div className='label'>当前所属城市：</div>
                                                <div className='value'>{detailData.cityName}</div>
                                        </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="flexend detailbox mB32">
                                                    <div className='label'>调整后：</div>
                                                    <Form.Item >
                                                        {getFieldDecorator('cityId', {
                                                            rules:[{required:true,message:'请选择所属城市'}]
                                                        })(
                                                            <Select placeholder="请选择" 
                                                            allowClear
                                                            style={{width:100}}
                                                            onChange={e=>this.linkageChange({'cityId':e},'serveList')}
                                                            >
                                                                {
                                                                    isArray(CityList)&&CityList.map(v=>{
                                                                        return <Option key={v.cityId} value={v.cityId}>{v.cityName}</Option>
                                                                    })
                                                                }
                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                   
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                        <div className="flexstart detailbox mB32">
                                                <div className='label'>当前服务性质：</div>
                                                <div className='value'>{detailData.serveNatureValue}</div>
                                        </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="flexend detailbox mB32">
                                                    <div className='label'>调整后：</div>
                                                    <Form.Item >
                                                    {getFieldDecorator('serveNature', {
                                                        rules:[{required:true,message:'请选择服务性质'}]
                                                    })(
                                                        <Select 
                                                        placeholder="请选择" 
                                                        allowClear
                                                        style={{width:100}}
                                                        onChange={e=>this.linkageChange({'serveNature':e,'cityId':cityId},'RankInfoList')}
                                                        >
                                                            {
                                                            isArray(serveList)&&_.uniqBy(serveList,'serveNature')
                                                                .map(v=>{
                                                                    return <Option key={v.serveNature} value={v.serveNature}>{v.serveNatureValue}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                        )}
                                                    </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                        <div className="flexstart detailbox mB32">
                                                <div className='label'>当前人员层级：</div>
                                                <div className='value'>{detailData.rank}</div>
                                                <Form.Item >
                                                    {getFieldDecorator('adjustBefore', {
                                                    initialValue:detailData.rankSalaryId
                                                    })(
                                                        <Input style={{display:'none'}}/>
                                                    )}
                                                </Form.Item>
                                        </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="flexend detailbox mB32">
                                                    <div className='label'>调整后：</div>
                                                    <Form.Item >
                                                        {getFieldDecorator('adjustAfter', {
                                                            rules:[{required:true,message:'请选择层级'}]
                                                        })(
                                                            <Select 
                                                            placeholder="请选择" 
                                                            allowClear
                                                            style={{width:100}}
                                                            // labelInValue
                                                            // onChange={e=>this.linkageChange({'rank':e,'serveNature':serveNature,'cityId':cityId},'rank')}
                                                            >
                                                            {
                                                                isArray(RankInfoList)&&RankInfoList.map(v=>{
                                                                    return <Option key={v.rankSalaryId} value={v.rankSalaryId}>{v.rank}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                        )}
                                                    </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                           </div>
                        }
                       
                       <TextFieldInput
                            form={form}
                            showLength={true}
                            label="调整原因说明"
                            placeholder="请输入"
                            required={true}
                            allowClear
                            field="remarks"
                            maxLength={500}
                            autoSize={{ minRows: 4, maxRows: 5 }}
                         />
                    
                   </Form>
               </Modal>
               } 
            </>
        );
    }
}

export default Form.create()(BasicInfoChange);