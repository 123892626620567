/*
 * @Description: 月度在册人员花名册
 * @Version: 2.0
 * @Autor: 赵娟
 * @Date: 2020-11-24 10:24:24
 */

import React from "react";
import TooltipComponent from "../../../components/TooltipComponent"; // 文本溢出提示
import {
  baseInput1,
  baseInput7,
} from "../../../utils/validate";
import {
  isPositiveIncome,
  accMul,
} from "../../../utils/common";
import moment from "moment";
/**
 * @description: 查询条件
 * @author: 赵娟
 */
const myDate = new Date();
const tYear = myDate.getFullYear();
const tMonth = myDate.getMonth() + 1;
export const searchFormDataApply = [
  {
    label: "统计时间",
    name: "month",
    type: "MonthPicker",
    initialValue: moment(
      tYear + "-" + tMonth,
      "YYYY-MM"
    ).subtract(1, "months"),
  },
  {
    label: "工号",
    name: "staffCode",
    type: "input",
    validatorName: baseInput1,
    placeholder: "请输入员工工号",
    maxLength: 18,
  },
  {
    label: "姓名",
    name: "staffName",
    type: "input",
    validatorName: baseInput7,
    placeholder: "请输入员工姓名",
    maxLength: 18,
  },
  {
    label: "部门",
    name: "deptIds",
    type: "treeSelect",
    selectData: [],
    placeholder: "全部",
  },
  {
    label: "状态",
    name: "staffStatus",
    type: "select",
    placeholder: "全部",
    initialValue: "null",
    selectData: [
      {
        name: "全部",
        value: "null",
      },
      {
        name: "在职",
        value: 1,
      },
      {
        name: "离职",
        value: 2,
      },
    ],
  },
];

/**
 * @description: 表头信息
 * @author: 赵娟
 */
export const columns = [
  {
    title: "序号",
    width: 60,
    fixed: "left",
    render: (text, record, index) => `${index + 1}`,
  },
  {
    title: "员工编号",
    dataIndex: "staffCode",
    key: "staffCode",
    width: 180,
    fixed: "left",
    render: (t) =>
      t ? <TooltipComponent width={160} text={t} /> : "-",
  },
  {
    title: "姓名",
    key: "staffName",
    dataIndex: "staffName",
    width: 150,
    render: (t) =>
      t ? <TooltipComponent width={140} text={t} /> : "-",
  },
  {
    title: "入职时间",
    key: "onBoardingTime",
    dataIndex: "onBoardingTime",
    width: 200,
    render: (t) =>
      t ? <TooltipComponent width={190} text={t} /> : "-",
  },
  {
    title: "离职时间",
    key: "resigningTime",
    dataIndex: "resigningTime",
    width: 200,
    render: (t) =>
      t ? <TooltipComponent width={190} text={t} /> : "-",
  },
  {
    title: "离职原因",
    key: "resigningReason",
    dataIndex: "resigningReason",
    width: 200,
    render: (t) =>
      t ? <TooltipComponent width={190} text={t} /> : "-",
  },
  {
    title: "博彦司龄",
    key: "beyondWorkingAge",
    dataIndex: "beyondWorkingAge",
    width: 130,
    render: (t) =>
      t ? <TooltipComponent width={120} text={t} /> : "-",
  },
  {
    title: "联系电话",
    key: "contactNumber",
    dataIndex: "contactNumber",
    width: 160,
    render: (t) =>
      t ? <TooltipComponent width={150} text={t} /> : "-",
  },
  {
    title: "身份证号码",
    key: "idNumber",
    dataIndex: "idNumber",
    width: 240,
    render: (t) =>
      t ? <TooltipComponent width={230} text={t} /> : "-",
  },
  {
    title: "性别",
    key: "sexStr",
    dataIndex: "sexStr",
    width: 80,
    render: (t) =>
      t ? <TooltipComponent width={70} text={t} /> : "-",
  },
  {
    title: "组别",
    key: "groupName",
    dataIndex: "groupName",
    width: 120,
    render: (t) =>
      t ? <TooltipComponent width={100} text={t} /> : "-",
  },
  {
    title: "WBS",
    key: "wbsName",
    dataIndex: "wbsName",
    width: 120,
    render: (t) =>
      t ? <TooltipComponent width={110} text={t} /> : "-",
  },
  {
    title: "办公城市",
    key: "cityName",
    dataIndex: "cityName",
    width: 140,
    render: (t) =>
      t ? <TooltipComponent width={130} text={t} /> : "-",
  },
  {
    title: "服务性质",
    key: "serveNatureName",
    dataIndex: "serveNatureName",
    width: 120,
    render: (t) =>
      t ? <TooltipComponent width={100} text={t} /> : "-",
  },
  {
    title: "转正系统薪资",
    key: "turnSalary",
    dataIndex: "turnSalary",
    width: 120,
    render: (t) =>
      t ? (
        <TooltipComponent
          width={110}
          text={isPositiveIncome(t)}
        />
      ) : (
        "-"
      ),
  } /* , {
    title: '绩效考核基准',
    key: 'performanceStandard',
    dataIndex: 'performanceStandard',
    width: 120,
    render: t => t ? <TooltipComponent width={110} text={isPositiveIncome(t)} /> : '-',
} */,
  {
    title: "非系统固定涨薪",
    key: "nonSystemFixedSalaryIncrease",
    dataIndex: "nonSystemFixedSalaryIncrease",
    width: 180,
    render: (t) =>
      t ? (
        <TooltipComponent
          width={110}
          text={isPositiveIncome(t)}
        />
      ) : (
        "-"
      ),
  },
  {
    title: "个人绩效基准值",
    key: "personalPerformanceBenchmarks",
    dataIndex: "personalPerformanceBenchmarks",
    width: 180,
    render: (t) =>
      t ? (
        <TooltipComponent
          width={110}
          text={isPositiveIncome(t)}
        />
      ) : (
        "-"
      ),
  },
  {
    title: "项目绩效池基准值",
    key: "projectPerformancePoolBenchmarks",
    dataIndex: "projectPerformancePoolBenchmarks",
    width: 180,
    render: (t) =>
      t ? (
        <TooltipComponent
          width={110}
          text={isPositiveIncome(t)}
        />
      ) : (
        "-"
      ),
  },
  {
    title: "薪资合计",
    key: "salaryTotal",
    dataIndex: "salaryTotal",
    width: 120,
    render: (t) =>
      t ? (
        <TooltipComponent
          width={110}
          text={isPositiveIncome(t)}
        />
      ) : (
        "-"
      ),
  },
  {
    title: "社保公积金成本",
    key: "socialSecurityFundCost",
    dataIndex: "socialSecurityFundCost",
    width: 150,
    render: (t) =>
      t ? (
        <TooltipComponent
          width={140}
          text={isPositiveIncome(t)}
        />
      ) : (
        "-"
      ),
  },
  {
    title: "项目名称",
    key: "projectName",
    dataIndex: "projectName",
    width: 120,
    render: (t) =>
      t ? <TooltipComponent width={110} text={t} /> : "-",
  },
  {
    title: "项目性质",
    key: "projectTypeName",
    dataIndex: "projectTypeName",
    width: 120,
    render: (t) =>
      t ? <TooltipComponent width={110} text={t} /> : "-",
  },
  {
    title: "岗位职能",
    key: "postFunction",
    dataIndex: "postFunction",
    width: 140,
    render: (t) =>
      t ? <TooltipComponent width={130} text={t} /> : "-",
  },
  {
    title: "层级",
    key: "rank",
    dataIndex: "rank",
    width: 140,
    render: (t) =>
      t ? <TooltipComponent width={130} text={t} /> : "-",
  },
  {
    title: "采购价",
    key: "purchasePrice",
    dataIndex: "purchasePrice",
    width: 140,
    render: (t) =>
      t ? (
        <TooltipComponent
          width={130}
          text={isPositiveIncome(t)}
        />
      ) : (
        "-"
      ),
  },
  {
    title: "对应薪资上限",
    key: "purchaseSalaryCeil",
    dataIndex: "purchaseSalaryCeil",
    width: 140,
    render: (t) =>
      t ? (
        <TooltipComponent
          width={130}
          text={isPositiveIncome(t)}
        />
      ) : (
        "-"
      ),
  },
  {
    title: "超上限薪资",
    key: "remarkMoney",
    dataIndex: "remarkMoney",
    width: 140,
    render: (t) =>
      t ? (
        <TooltipComponent
          width={130}
          text={isPositiveIncome(t)}
        />
      ) : (
        "-"
      ),
  },
];
