/*
 * @文档描述: 流程配置 --- 修改
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-08-18 17:00:29
 * @最后修改人: 文倩
 */
import React, { Component, Fragment } from 'react';
import { Modal, Form, Button, message, Select } from 'antd';


import { processForm } from '../../contants';
import './index.less';

const { Option } = Select;

class Revise extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false, // 编辑弹框显隐
            formData: {},  //回填数据
        };
    }

    componentDidMount() {
        
    }

    
    /**
     * @函数描述: 查询仓库数据
     * @return:
     */

    getRoleList = () => {
        const {formData}=this.props;

        
        this.$http.getEnableRoleProcess().then(res => {
            if (res && res.errorCode == 200) {
                processForm[formData.procType][0].selectData = res.data;
                if(processForm[formData.procType][1]){
                    processForm[formData.procType][1].selectData = res.data;
                }
                if(processForm[formData.procType][2]){
                    processForm[formData.procType][2].selectData = res.data;
                }
                

                this.queryModalForm();

            }
        });
    }

    /**
     * @函数描述: 回显弹窗数据
     * @param 
     * @return:
     */
    queryModalForm = () => {
        const { formData } = this.props;
        const { setFieldsValue } = this.props.form;
        let querys={};

        if (Object.keys(formData).length > 0) {
            let params = {
                firstLevel: {
                    label: formData && formData.groupName2,
                    key: formData && formData.groupId2
                },
                secondLevel: {
                    label: formData && formData.groupName3,
                    key: formData && formData.groupId3
                },
                threeLevel: {
                    label: formData && formData.groupName4,
                    key: formData && formData.groupId4
                }
            };
            

            Object.keys(params).forEach(res => {
                if (params[res].key != "undefined" || params[res].label != "undefined") {
                    querys[res] = params[res];
                }
            });
            
            this.setState({
                formData: querys
            }, () => {
                setFieldsValue(querys);
            });
        }
    }



    /**
     * @函数描述: 点击显示编辑弹框
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return:
     */
    handlePassBtnShow = () => {

        this.setState({
            visible: true
        }, () => {
            this.getRoleList();
        });

    };
    /**
     * @函数描述: 取消弹框显示
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦
     */
    handlePassBtnCancle = () => {
        this.setState(
            {
                visible: false
            },
            () => {
                this.props.form.resetFields();
            }
        );
    };
    /**
     * @函数描述: 确定编辑成功
     * @param {String} passMessage  审批意见
     * @param {String} id
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦，重新表格当前页数据
     */
    handleAddSure = e => {
        e.preventDefault();
        const { validateFieldsAndScroll } = this.props.form;
        const { formData } = this.props;

        
        let params = {};
        let list = [];
        validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                for (let k in values) {
                    let newId;
                    let newTaskDefKey;
                    let newTaskDefValue;
                    if (k == 'firstLevel') { 
                        newId = formData && formData.id2;
                        newTaskDefKey = formData && formData.taskDefKey2;
                        newTaskDefValue = formData && formData.taskDefValue2;
                    }
                    if (k == 'secondLevel') { 
                        newId = formData && formData.id3;
                        newTaskDefKey = formData && formData.taskDefKey3;
                        newTaskDefValue = formData && formData.taskDefValue3; 
                    }
                    if (k == 'threeLevel') { 
                        newId = formData && formData.id4;
                        newTaskDefKey = formData && formData.taskDefKey4;
                        newTaskDefValue = formData && formData.taskDefValue4;
                    }
                    console.log(formData);
                    list.push({
                        auditType: formData && formData.auditType,
                        // copyToGroup:formData && formData.copyToGroup,
                        procDefKey: formData && formData.procDefKey,
                        procDefValue: formData && formData.procDefValue,
                        procType: formData && formData.procType,
                        id: newId,
                        taskDefKey: newTaskDefKey,
                        taskDefValue: newTaskDefValue,
                        groupId: values[k].key || null,
                        groupName: values[k].label || null
                    });
                }
                
                list.unshift({
                    auditType: formData && formData.auditType,
                    // copyToGroup:formData && formData.copyToGroup,
                    procDefKey: formData && formData.procDefKey,
                    procDefValue: formData && formData.procDefValue,
                    procType: formData && formData.procType,
                    id: formData && formData.id1,
                    taskDefKey: formData && formData.taskDefKey1,
                    taskDefValue: formData && formData.taskDefValue1,
                    groupId: formData && formData.groupId1,
                    groupName: formData && formData.groupName1
                });

                params = {
                    procDefKey: formData.procType,
                    procDefValue: formData.procDefValue,
                    procConfigVOS: list
                }

                
                const res = await this.$http.updateProcess(params);
                if (res && res.errorCode == 200) {
                    message.success(res.message);
                    this.props.handleSubmit();
                    this.handlePassBtnCancle();
                }
            }
        });
    };

    /**
     * @函数描述: 循环表单项
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦，重新表格当前页数据
     */
    handleForm = item => {
        const form = this.props.form;

        const html = (
            <Form.Item label={item.name}>
                {this.props.form.getFieldDecorator(item.key, {
                    rules: item.rules
                })(
                    <Select
                        placeholder={item.placeholder || '请选择'}
                        mode={item.mode || ''}
                        form={form}
                        disabled={item.disabled}
                        allowClear
                        labelInValue
                    >
                        {this.$common.isArray(item.selectData) && item.selectData.map(res => {
                            return (
                                <Option key={res[item.mapping ? item.mapping.value : 'key']}>
                                    {res[item.mapping ? item.mapping.name : 'label']}
                                </Option>);
                        })}
                    </Select>
                )}
            </Form.Item>
        )
        return html;
    };


    render() {
        const { form, formData } = this.props;
        const { getFieldDecorator } = form;
        const { visible } = this.state;
        return (
            <Fragment key="passBtn">
                <Button onClick={this.handlePassBtnShow} type="link">
                    修改
                    </Button>
                <Modal
                    className="reviseModal"
                    title="修改"
                    visible={visible}
                    width={518}
                    onCancel={this.handlePassBtnCancle}
                    onOk={this.handleAddSure}
                    maskClosable={false}
                    okText="提交"
                >
                    <h3>
                        <font>发起流程:</font>
                        <span>{formData.groupName1}</span>
                    </h3>
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }}>
                        {processForm[formData.procType].map((item, i) => {
                            return <Fragment key={i}>
                                {this.handleForm(item)}
                            </Fragment>
                        })}
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}
export default Form.create()(Revise);
