/*
 * @Description: 项目看板接口配置
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-25 11:50:15
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-08-19 14:04:26
 */

import http from "../fetch";
const baseUrl = process.env.API_URL ? process.env.API_URL : "";

export default {
  // 新增项目
  addProjectInfo(param, callbak) {
    return http.post(`${baseUrl}/bpo/projectInfo/addProjectInfo`, param);
  },
  // 新增人员
  addProjectStaffRoster(param, callbak) {
    return http.post(`${baseUrl}/bpo/staffRoster/addProjectStaffRoster`, param);
  },
  // 项目详情
  getProjectInfoById(param, callbak) {
    return http.post(`${baseUrl}/bpo/projectInfo/getProjectInfoById`, param);
  },
  // 项目列表
  pageProjectInfoByCondition(param, callbak) {
    return http.post(
      `${baseUrl}/bpo/projectInfo/pageProjectInfoByCondition`,
      param
    );
  },
  // 项目人员列表
  pageStaffProjectRefList(param, callbak) {
    return http.post(
      `${baseUrl}/bpo/staffProjectRef/pageStaffProjectRefList`,
      param
    );
  },
  // 项目修改回显
  queryProjectEchoById(param, callbak) {
    return http.post(`${baseUrl}/bpo/projectInfo/queryProjectEchoById`, param);
  },
  // 核准金额明细列表
  pageApprovalDetailByCondition(param, callbak) {
    return http.post(
      `${baseUrl}/bpo/approvalDetail/pageApprovalDetailByCondition`,
      param
    );
  },
  // 修改项目
  updateProjectInfo(param, callbak) {
    return http.post(`${baseUrl}/bpo/projectInfo/updateProjectInfo`, param);
  },
  // 修改核准金额
  updateApprovalDetail(param, callbak) {
    return http.post(
      `${baseUrl}/bpo/approvalDetail/updateApprovalDetail`,
      param
    );
  },
  // 历史变更记录
  pageProjectChangeDetailByCondition(param, callbak) {
    return http.post(
      `${baseUrl}/bpo/projectChangeDetail/pageProjectChangeDetailByCondition`,
      param
    );
  },
  // 人员转项目得项目下拉
  queryProjectOption(param, callbak) {
    return http.post(`${baseUrl}/bpo/projectInfo/queryProjectOption`, param);
  },
  // 员工详情
  getProjectStaffRosterById(param, callbak) {
    return http.post(
      `${baseUrl}/bpo/staffRoster/getProjectStaffRosterById`,
      param
    );
  },
  // 项目列表导出

  exportProjectInfoByCondition(param, callbak) {
    return `${baseUrl}/bpo/projectInfo/exportProjectInfoByCondition`;
    // return http.post(`${baseUrl}/bpo/projectInfo/exportProjectInfoByCondition`, param)
  },
  // 人员列表导出

  exportStaffProjectRef(param, callbak) {
    return `${baseUrl}/bpo/staffProjectRef/export`;
    // return http.post(`${baseUrl}/bpo/projectInfo/exportProjectInfoByCondition`, param)
  },
  // 变更项
  queryChangeField(param, callbak) {
    return http.post(
      `${baseUrl}/bpo/projectChangeDetail/queryChangeField`,
      param
    );
  },
  //项目详情-下载新增新人员模板
  DownloadNewPersonnelTemplate(param) {
    return http.get(
      `${baseUrl}/bpo/staffRoster/downStaffRosterModel`,
      param,
      "",
      "blob"
    );
  },
  //项目详情-项目人员批量导入
  batchImportOfProjectPersonnel(param) {
    return http.post(
      `${baseUrl}/bpo/staffRoster/addBatchProjectStaffRoster`,
      param,
      false,
      "blob",
      true
    );
  },

  //项目详情-下载新增人员批量导入失败数据
  failedDataDownloadOfNewPersonnelImport(param) {
    return http.get(
      `${baseUrl}/bpo/staffRoster/downErrorData`,
      param,
      "",
      "blob"
    );
  },
};
