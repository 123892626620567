/*
 * @Description: 层级薪资
 * @Autor: 文倩
 * @Date: 2020-07-13 14:24:33
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-06 16:29:51
 */
import React, { Component } from "react";
import { Button } from "antd";

import Table from "../../../../components/table";
import { salaryColumns } from "../../contants";

import AddSalary from "./addSalary"; //添加层级薪资
import RemoveSalary from "./removeSalary"; //删除层级薪资
import DownLoadTemp from "./downLoadTemp"; //下载导入模板
import ImportFile from "../../../../components/importBtn/index"; //导入按钮

class HierarchicalSalary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      pageNo: 1,
      pageSize: 10,
      loading: false,
      tableList: [],
      selectedRowKeys: [], //复选框选中的值
    };
    this.operateColumns = [
      {
        title: "操作",
        width: 120,
        fixed: "right",
        render: (record) => {
          let { pageNo, tableList } = this.state;
          return (
            <div className="butt">
              <span className="operation-wrap">
                <AddSalary
                  type="list"
                  formData={record}
                  handleOk={this.queryTableList}
                />
                <RemoveSalary
                  type="list"
                  rowkey={[record.rankSalaryId]}
                  handleOk={() => {
                    if (pageNo > 1 && tableList.length === 1) {
                      pageNo = pageNo - 1;
                    }
                    this.queryTableList(pageNo);
                  }}
                />
              </span>
            </div>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.queryTableList();
  }

  /**
   * @description: 查询层级薪资数据
   * @param {type}
   * @return:
   * @author: 文倩
   */

  queryTableList = (pageNo = this.state.pageNo, pageSize = 10) => {
    const params = {
      page: pageNo,
      pageSize,
    };

    this.setState({
      loading: true,
    });
    this.$http.getSalaryList(params).then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          loading: false,
          tableList: res.data.rows,
          total: res.data.total,
          pageNo,
          pageSize,
          selectedRowKeys: [],
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  };

  /**
   * @description: 选中复选框回调
   * @param {type}
   * @return:
   * @author: 文倩
   */
  onSelectChange = (keys) => {
    this.setState({
      selectedRowKeys: keys,
    });
    console.log(keys, "------keys");
  };

  render() {
    let {
      tableList,
      loading,
      total,
      pageNo,
      pageSize,
      selectedRowKeys,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div className="salary-table operation-table">
        <AddSalary handleOk={this.queryTableList} />
        {this.$common.isArray(tableList) && (
          <RemoveSalary
            rowkeys={selectedRowKeys}
            handleOk={() => {
              if (
                pageNo > 1 &&
                selectedRowKeys.length > 0 &&
                selectedRowKeys.length <= 10 &&
                tableList.length <= 10
              ) {
                pageNo = pageNo - 1;
              }
              this.queryTableList(pageNo);
            }}
          />
        )}
        <DownLoadTemp />
        <ImportFile
          title="文件导入"
          text="导入"
          importFile={(val) => this.$http.importFile(val)}
          importOk={this.queryTableList}
          downLoadError={(val) => this.$http.importErrorFile(val)}
        />
        <Table
          rowKey="rankSalaryId"
          loading={loading}
          scroll={{ x: "100%" }}
          rowSelection={rowSelection}
          columns={[...salaryColumns, ...this.operateColumns]}
          dataSource={tableList}
          total={total}
          current={pageNo}
          pageSize={pageSize}
          isPagination={true}
          handleChangePage={this.queryTableList}
          handleChangePageSize={this.queryTableList}
        />
      </div>
    );
  }
}

export default HierarchicalSalary;
