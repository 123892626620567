/*
 * @文档描述: 花名册信息--导入按钮
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-07-29 14:57:42
 * @最后修改人: 文倩
 */
import React, { Component, Fragment } from 'react';
import { Button, message, Upload, Modal, Icon } from 'antd';


import './index.less';

/*
 * @Description: 组件所需传入参数
 * @String:title        //弹窗标题
 * @String:text         //按钮文本
 * @function:importOk           ()=>{}  //导入成功后刷新列表
 * @function:importFile         ()=>{}  //导入请求接口  ps:(v)=>{this.download(v)}
 */

class ImportFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isImportLoading: false,
            visible: false,
            isFileNameShow: false,
            alertVisible: false,  //导出结果弹窗
            file: ""
        }
    }
    /**
     * @函数描述: 导入
     * @param {String} file  文件名
     */
    handleUpload = (file) => {
        this.setState({
            file,
            isFileNameShow: true
        });
    };

    /**
     * @description: 上传成功
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    handleOk = async () => {
        const { file } = this.state;
        if (file != '') {

            let fileData = new FormData();//创建文件流

            if (this.$common.limitUploadxlsx(file.file)) {
                fileData.append("file", file.file);

                this.setState({
                    isImportLoading: true
                });

                const res = await this.props.importFile(fileData);

                if (res && res.request && res.request.responseType == 'blob') {
                    var reader = new FileReader();
                    reader.onload = () => {
                        var content = JSON.parse(reader.result);//内容就在这里
                        this.setState({
                            isImportLoading: false
                        });
                        try {
                            if (content && content.errorCode == 200) {
                                if (content.data.success) {
                                    this.setState({
                                        visible:false,
                                        isFileNameShow: false,
                                        file:""
                                    });
                                } else {
                                    this.setState({
                                        visible:false,
                                        isFileNameShow: false,
                                        file:"",
                                    });
                                }
                                this.props.importOk();
                            } else {
                                message.error(content.message);
                            }
                        } catch{
                            console.log('导入失败');
                            this.setState({
                                isImportLoading: false,
                                visible:false,
                                isFileNameShow: false,
                                file:"",
                            });
                        }
                    };
                    reader.readAsText(res.data);
                } else {
                    this.setState({
                        isImportLoading: false
                    });
                }
            }
        }else{
            message.warning("请先上传文件");
        }
    }



    /**
     * @description: 弹窗显隐，true为显示，false为隐藏
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    handleOpen = () => {
        this.setState({
            visible: true
        });
    }


    /**
     * @description: 弹窗显隐，true为显示，false为隐藏
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    handleCancel = () => {
        this.setState({
            visible: false,
            isFileNameShow: false,
            file: ""
        });
    }


    /**
     * @description: 删除文件
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    handleRemove = () => {
        this.setState({
            isFileNameShow: false,
            file: ""
        });
    }


    render() {
        const {
            isImportLoading,
            visible,
            file,
            isFileNameShow
        } = this.state;
        const { text, title } = this.props;
        const params = {
            accept: ".xlsx,.xls",
            customRequest: this.handleUpload,
        };
        return (
            <Fragment>
                <Button onClick={this.handleOpen}>{text}</Button>
                <Modal
                    title={title}
                    visible={visible}
                    wrapClassName="importBtn"
                    confirmLoading={isImportLoading}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div className="importFile">
                        <h6>选择要上传的文件:</h6>
                        {isFileNameShow ?
                            <span className="fileName">
                                <font>{file.file && file.file.name}</font>
                                <Icon type="close" onClick={this.handleRemove} />
                            </span>
                            :
                            <Upload {...params}>
                                <Button type="dashed">
                                    <Icon type="plus" />
                                添加文件
                            </Button>
                            </Upload>
                        }
                    </div>
                </Modal>
            </Fragment>
        );
    }
}
export default ImportFile;
