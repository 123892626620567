/*
 * @文档描述: 账号管理 --- 停用/启用
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-08-11 11:31:20
 * @最后修改人: 文倩
 */
import React, { Component } from 'react';
import './index.less';
import { message } from 'antd';

import PopconfirmTip from '../../../../components/Popconfirm'; // 删除二次确认




class Remove extends Component {
    /**
     * @函数描述: 确定通过审批
     * @param {String} RevokeMessage  审批意见
     * @param {String} id
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦，重新表格当前页数据
     */
    handleRevoke = async () => {
        const query = {
            staffState: !this.props.record.staffState,
            staffId: this.props.record.staffId,
        }
        const res = await this.$http.enadleAccount(query);
        if (res && res.errorCode == 200) {
            message.destroy();
            message.success(res.message);
            this.props.handleSubmit();
        }else if(res && res.errorCode == 503){
            this.$utils.clearUserInfo();
            this.$utils.clear();
            this.props.history.push('/');
        }
    };
    render() {
        const { record } = this.props;
        return (
            <PopconfirmTip
                key="revokeBtn"
                title={`确定要${record.staffState?"停用":"启用"}该账号吗？`}
                onConfirm={this.handleRevoke}
            >
                <span>{record.staffState?"停用":"启用"}</span>
            </PopconfirmTip>
        );
    }
}
export default Remove;
