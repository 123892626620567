/*
 * @Description: 我的工作台接口配置
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-25 11:50:15
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-08-04 13:19:17
 */ 


import http from '../fetch'
const baseUrl = process.env.API_URL ? process.env.API_URL : '';

export default {
 // 待办列表
 pageProcTaskTodoByCondition(param, callbak) {
    return http.post(`${baseUrl}/bpo/procTaskTodo/pageProcTaskTodoByCondition`, param)
},
 // 薪资超上限-详情
 getEmolumentInformById(param, callbak) {
    return http.post(`${baseUrl}/bpo/emolumentInform/getEmolumentInformById`, param)
},
   // 薪资超上限-审核通过
emolumentInformPass(param, callbak) {
    return http.post(`${baseUrl}/bpo/emolumentInform/pass`, param)
},
  // 已办列表
  pageProcTaskDoneByCondition(param, callbak) {
    return http.post(`${baseUrl}/bpo/procTaskDone/pageProcTaskDoneByCondition`, param)
},
  // 流程查询
  pageProcTaskDoneByList(param, callbak) {
    return http.post(`${baseUrl}/bpo/procTaskDone/pageProcTaskDoneByList`, param)
},
  // 项目迁移发起
  addProjectAdjust(param, callbak) {
    return http.post(`${baseUrl}/bpo/projectAdjust/addProjectAdjust`, param)
 },
  // 项目迁移详情
  getProjectAdjustById(param, callbak) {
    return http.post(`${baseUrl}/bpo/projectAdjust/getProjectAdjustById`, param)
  },
   // 项目迁移审核通过
   projectAdjustIdPass(param, callbak) {
    return http.post(`${baseUrl}/bpo/projectAdjust/pass`, param)
  },
   // 项目迁移-审核驳回
   projectAdjustIdRefuse(param, callbak) {
    return http.post(`${baseUrl}/bpo/projectAdjust/refuse`, param)
  },
   // 项目人员信息列表
   pageStaffRosterByProjectId(param, callbak) {
    return http.post(`${baseUrl}/bpo/staffProjectRef/pageStaffRosterByProjectId`, param)
  },
   // 员工离职新增
   addStaffResigning(param, callbak) {
    return http.post(`${baseUrl}/bpo/staffResigning/addStaffResigning`, param)
  },
  // 结项申请发起
  addPostProject(param, callbak) {
    return http.post(`${baseUrl}/bpo/postProject/addPostProject`, param)
  },
  // 结项申请-详情
  getPostProjectById(param, callbak) {
    return http.post(`${baseUrl}/bpo/postProject/getPostProjectById`, param)
  },
  // 结项申请-审核通过
  postProjectPass(param, callbak) {
    return http.post(`${baseUrl}/bpo/postProject/pass`, param)
  },
  // 结项申请-审核驳回
  postProjectRefuse(param, callbak) {
    return http.post(`${baseUrl}/bpo/postProject/refuse`, param)
  },
   // 薪资结构调整-发起
   addSalaryAdjust(param, callbak) {
    return http.post(`${baseUrl}/bpo/salaryAdjust/addSalaryAdjust`, param)
  },
  // 薪资结构调整-详情
  getSalaryAdjustById(param, callbak) {
    return http.post(`${baseUrl}/bpo/salaryAdjust/getSalaryAdjustById`, param)
  },
  // 薪资结构调整-通过
  salaryAdjustPass(param, callbak) {
    return http.post(`${baseUrl}/bpo/salaryAdjust/pass`, param)
  },
  // 薪资结构调整-驳回
  salaryAdjustRefuse(param, callbak) {
    return http.post(`${baseUrl}/bpo/salaryAdjust/refuse`, param)
  },
  // 员工信息调整-发起
  addStaffInfoAdjust(param, callbak) {
    return http.post(`${baseUrl}/bpo/staffInfoAdjust/addStaffInfoAdjust`, param)
  },
  // 员工信息调整-详情
  getStaffInfoAdjustById(param, callbak) {
    return http.post(`${baseUrl}/bpo/staffInfoAdjust/getStaffInfoAdjustById`, param)
  },
  // 员工信息调整-通过
  staffInfoAdjustPass(param, callbak) {
    return http.post(`${baseUrl}/bpo/staffInfoAdjust/pass`, param)
  },
  // 员工信息调整-驳回
  staffInfoAdjustRefuse(param, callbak) {
    return http.post(`${baseUrl}/bpo/staffInfoAdjust/refuse`, param)
  },
  // 人员项目调整-发起
  addStaffProjectAdjust(param, callbak) {
    return http.post(`${baseUrl}/bpo/staffProjectAdjust/addStaffProjectAdjust`, param)
  },
  // 人员项目调整-详情
  getStaffProjectAdjustById(param, callbak) {
    return http.post(`${baseUrl}/bpo/staffProjectAdjust/getStaffProjectAdjustById`, param)
  },
  // 人员项目调整-通过
  staffProjectAdjustPass(param, callbak) {
    return http.post(`${baseUrl}/bpo/staffProjectAdjust/pass`, param)
  },
  // 人员项目调整-驳回
  staffProjectAdjustRefuse(param, callbak) {
    return http.post(`${baseUrl}/bpo/staffProjectAdjust/refuse`, param)
  },
}