/*
 * @Description: 人员转项目申请
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:18:49
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-08-24 16:35:28
 */

import React, { Component } from "react";
import {
  Button,
  Select,
  Row,
  Col,
  Form,
  DatePicker,
  Modal,
  message,
} from "antd";
import "./Components.less";
import moment from "moment";
import TooltipComponent from "../../../../../components/TooltipComponent"; // 文本溢出提示
import TextFieldInput from "../../../../../components/sceneInput/TextAreaInput";
import { isArray } from "../../../../../utils/common";
import { throttle } from "lodash";
const { Option } = Select;
class StaffTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 3,
      visible: false,
      loading: false,
      detailData: {},
      projectList: [],
      btnLoading: false,
    };
  }
  /**
   * @description: 人员项目调整发起
   * @author: 陈金金
   */
  handleAddSure = () => {
    const { row, projectid } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { effectiveTime, ...rest } = values;
        const param = {
          staffId: row.staffId,
          staffCode: row.staffCode,
          staffName: row.staffName,
          projectIdBefore: projectid,
          effectiveTime: effectiveTime.format("YYYY-MM-DD"),
          ...rest,
        };
        this.setState({ btnLoading: true });
        this.$http.addStaffProjectAdjust(param).then((res) => {
          this.setState({ btnLoading: false });
          if (res && res.errorCode == 200) {
            message.destroy();
            message.success(res.message);
            this.props.closemodal();
            this.handleReturnStockBtnCancle();
          }
        });
      }
    });
  };

  handleReturnStockBtnCancle = () => {
    this.props.form.resetFields();
    this.setState({ visible: false });
  };
  /**
   * @description: 自动回显数据
   * @return:
   */
  renderDetailKeyHTML = () => {
    const { detailData } = this.state;
    return [
      { name: "调整人姓名：", key: "staffName" },
      { name: "调整人工号：", key: "staffCode" },
    ].map((item) => {
      return (
        <Col span={24} key={item.key}>
          <div className="detailbox">
            <span className="label">{item.name}</span>
            <div className="value">
              {detailData[item.key] ? (
                <TooltipComponent text={detailData[item.key]} width={200} />
              ) : (
                "--"
              )}
            </div>
          </div>
        </Col>
      );
    });
  };
  openModal = () => {
    //
    const { projectid, row } = this.props;
    this.setState({ visible: true }, () => {
      this.setState({
        detailData: row,
      });
      this.getprojectList(projectid, null);
    });
  };
  getprojectList = (projectId, projectName) => {
    this.$http.queryProjectOption({ projectId, projectName }).then((res) => {
      console.log("res --->", res);
      if (res && res.errorCode == 200) {
        this.setState({
          projectList: res.data,
        });
      }
    });
  };
  render() {
    const { visible, projectList, btnLoading } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { key } = this.props;
    return (
      <>
        <span className="span" key={key} onClick={() => this.openModal()}>
          人员转项目
        </span>
        {visible && (
          <Modal
            className="returnStockModal"
            title="人员转项目申请"
            visible={visible}
            width={480}
            footer={[
              <div key="returnStockModal">
                <Button
                  key="cancle"
                  onClick={() => this.handleReturnStockBtnCancle()}
                >
                  取消
                </Button>
                <Button
                  key="sure"
                  onClick={this.handleAddSure}
                  loading={btnLoading}
                  type="primary"
                >
                  提交{" "}
                </Button>
              </div>,
            ]}
            onCancel={this.handleReturnStockBtnCancle}
            maskClosable={false}
            okText="提交"
          >
            <Row>{this.renderDetailKeyHTML()}</Row>
            <Form>
              <Form.Item label="调整人将转至">
                {getFieldDecorator("projectIdAfter", {
                  rules: [{ required: true, message: "请选择项目组" }],
                })(
                  <Select
                    placeholder="请选择项目"
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children &&
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {isArray(projectList) &&
                      projectList.map((v) => {
                        return (
                          <Option key={v.projectId} value={v.projectId}>
                            {v.projectName}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="生效时间">
                {getFieldDecorator("effectiveTime", {
                  rules: [
                    {
                      type: "object",
                      required: true,
                      message: "请选择生效时间",
                    },
                  ],
                })(
                  <DatePicker
                    style={{ width: "100%" }}
                    // disabledDate={(e) => e && e < moment().subtract(1, "days")}
                  />
                )}
              </Form.Item>
              <TextFieldInput
                form={this.props.form}
                showLength={true}
                label="人员转项目原因说明"
                placeholder="请输入"
                required={true}
                allowClear
                field="remarks"
                maxLength={500}
                autoSize={{ minRows: 4, maxRows: 5 }}
              />
            </Form>
          </Modal>
        )}
      </>
    );
  }
}

export default Form.create()(StaffTransfer);
