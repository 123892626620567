/*
 * @Description: 基础信息维护页面
 * @Autor: 文倩
 * @Date: 2020-07-13 14:25:33
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-11 10:43:40
 */

import React, { Component, Fragment } from "react";

import Search from '../../../components/searchForm';


import FixedCost from './FixedCost';                //固定成本
import SocialInsurance from './SocialInsurance';        //社保公积金成本
import HierarchicalSalary from './HierarchicalSalary';  //层级薪资
import Group from './Group';        //组别
import WBS from './WBS';            //WBS
import City from './City';          //城市
import Project from './Project';    //项目
 
import { basicTab } from '../contants';
import './index.less';

class BasicInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableList: [],
        }
    }


    


    /**
     * @description: 切换列表tabs
     * @param {type} key:"0"
     * @return: 
     * @author: 文倩
     */
    hansleChangeTabs = key => {
        this.setState({
            currentStatus: key,
        });
    };


    /**
     * @description: 根据tab渲染当前内容
     * @param {type} 
     * @return: 
     * @author: 文倩
     * @LastEditors: 赵娟
     * @description: 添加项目tab
     * @LastEditTime: 2020-11-26 10:43:40
     */
    renderTableContent = (status) => {
        const { tableList } = this.state;
        let render;
        switch (status) {
            case '0':
                render = <FixedCost
                    tableList={tableList} />
                break;
            case '1':
                render = <SocialInsurance
                    tableList={tableList} />
                break;
            case '2':
                render = <HierarchicalSalary
                    tableList={tableList} />
                break;
            case '3':
                render = <Group
                    tableList={tableList} />
                break;
            case '4':
                render = <WBS
                    tableList={tableList} />
                break;
            case '5':
                render = <City
                    tableList={tableList} />
                break;
            case '6':
                render = <Project
                    tableList={tableList} />
                break;
            default:
                render = <FixedCost
                    tableList={tableList} />
                break;
        }
        return render;
    }

    render() {
        const { currentStatus } = this.state;

        return (
            <Fragment>
                <Search
                    tabList={basicTab}
                    hansleChangeTabs={this.hansleChangeTabs} />
                {this.renderTableContent(currentStatus)}
            </Fragment>
        );
    }
}

export default BasicInfo;