/*

 */
import React from "react";
import { map, filter, cloneDeep } from "lodash";
import { Form, Input, Button } from "antd";
import "./index.less";
import { MinusCircleOutlined } from "@ant-design/icons";

class DynamicFieldSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      WBSList: [],
      wbsNameList: [],
    };
  }

  //初始化
  componentDidMount() {
    this.assignment();
  }

  setWbsList = (wbsList) => {
    console.log(this.state.WBSList);
    this.setState({
      WBSList: [],
    });

    this.assignment(wbsList);
  };
  //父->子组件赋值
  assignment = (wbsList) => {
    const { initialValue } = this.props;
    const copyValue = cloneDeep(wbsList || initialValue);
    this.id = 0;
    this.num = 0;
    const newInitialValue = map(copyValue, (v) => ({
      key: this.id++,
      value: v,
      num: v.length,
    }));
    this.setState({
      WBSList: newInitialValue,
      wbsNameList: copyValue,
    });
  };
  // 正则校验
  validator = (rules, value, callback) => {
    const { wbsNameList } = this.state;
    const { validatorName, message } = this.props;
    const _this = this;
    if (value) {
      if (validatorName(value)) {
        wbsNameList.forEach((val) => {
          val === value && _this.num++;
        });
        if (_this.num < 2) {
          callback();
        } else {
          callback(`WBS不能重复`);
        }
        _this.num = 0;
        callback();
      } else {
        callback(`请输入正确的格式：${message}`);
      }
    }
    callback();
  };

  //删除
  removeInput = (key, index) => {
    const { WBSList, wbsNameList } = this.state;
    WBSList.splice(index, 1);
    wbsNameList.splice(index, 1);
    this.setState({
      WBSList,
      wbsNameList,
    });
  };

  //添加
  add = () => {
    const { WBSList } = this.state;
    WBSList.push({
      key: this.id++,
      value: "",
      num: 0,
    });
    this.setState({
      WBSList,
    });
  };

  _onChange = (e, index) => {
    const { WBSList, wbsNameList } = this.state;
    WBSList[index].num = e.target.value.length;
    wbsNameList[index] = e.target.value;
    this.setState({
      WBSList,
      wbsNameList,
    });
  };

  render() {
    const propsObj = { ...this.props };
    const {
      label,
      required,
      message,
      placeholder,
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { WBSList } = this.state;

    Reflect.deleteProperty(propsObj, "validatorName");
    Reflect.deleteProperty(propsObj, "initialValue");

    return (
      <Form.Item label={label} required>
        {(WBSList || []).map((v, index) => {
          return (
            <div className="lineInput" key={v.key}>
              <Form.Item
                style={{ width: "100%", margin: 0 }}
              >
                {getFieldDecorator(
                  `wbsList[${v.key}].value`,
                  {
                    initialValue: v.value || undefined,
                    validate: [
                      {
                        trigger: "onBlur",
                        rules: [
                          {
                            required,
                            message: `请输入${
                              label || message
                            }`,
                          },
                          { validator: this.validator },
                        ],
                      },
                    ],
                  }
                )(
                  <Input
                    {...propsObj}
                    onChange={(e) =>
                      this._onChange(e, index)
                    }
                    addonAfter={<span>{v.num}/50</span>}
                    placeholder={placeholder || "请输入"}
                  />
                )}
              </Form.Item>
              {WBSList.length > 1 && (
                <div
                  className="minus"
                  onClick={() =>
                    this.removeInput(v.key, index)
                  }
                >
                  <span className="symbol" />
                </div>
              )}
            </div>
          );
        })}
        {WBSList.length < propsObj.count && (
          <Button
            className="addBtn"
            onClick={() => this.add()}
          >
            + {label}
          </Button>
        )}
      </Form.Item>
    );
  }
}

export default DynamicFieldSet;
