/*
 * @Description: 路由配置
 * @Author: 文倩
 * @Date: 2020-07-08 15:42:17
 * @LastEditTime: 2020-07-08 16:27:51
 * @LastEditor: 文倩
 */
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from '../api/history';
import App from '../App';
import AuthorizedRoute from '../components/AuthorizedRoute';
import RouterList from "./router";
import RouterConfig from "./routerConfig";


let OSS = window.OSS.Wrapper
let client = new OSS({
	region: 'oss-cn-hangzhou',//如果需要前端直传，这需要配置oss
	accessKeyId: 'LTAI4FwZr89Gn2e4FTyV1eLH',//如果需要前端直传，这需要配置oss
	accessKeySecret: 'cM6W8Pvb9LUD6SmGVaRRNkn1hvXSNq',//如果需要前端直传，这需要配置oss
	secure: true,
	bucket: 'by-invoice', //自定义的上传后地址，加在oss前 //如果需要前端直传，这需要配置oss 
});


window.client = client
const RouterPage = () => {

	return (
		<Router history={history}>
			<Switch>
				{RouterConfig.map(route => {
					return (
						<Route
							key={route.name}
							path={route.path}
							name={route.name}
							exact={route.exact}
							component={route.component} />
					)
				})}
				<App>
					{
						RouterList.map(route => {
							const component = route.component;
							return (

								<AuthorizedRoute
									key={route.path}
									exact={route.exact}
									path={route.path}
									name={route.name}
									history={history}
									showHeader={true}
									openAccessMenu={route.openAccessMenu}
									component={component} />
							)
						})
					}
				</App>
			</Switch>
		</Router>

	)
}

export default RouterPage;
