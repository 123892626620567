/*
 * @文档描述: 角色 --- 删除
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-08-11 11:30:06
 * @最后修改人: 文倩
 */
import React, { Component } from 'react';
import {  message } from 'antd';

import PopconfirmTip from '../../../../components/Popconfirm'; // 删除二次确认

import './index.less';

class Remove extends Component {
    /**
     * @函数描述: 确定通过审批
     * @param {String} RevokeMessage  审批意见
     * @param {String} id
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦，重新表格当前页数据
     */
    handleRevoke =async () => {
        const { record ,type} = this.props;
        const param = {
            roleId: record.roleId
        }

        const query = {
            roleId: record.roleId,
            roleStatus: !record.roleStatus
        }
        
        let res;
        if (type == 'remove') {
            res = await this.$http.removeRole(param);
        } else {
            res = await this.$http.enadleRole(query);
        }
    
        
        if (res && res.errorCode == 200) {
            message.destroy();
            message.success(res.message);
            this.props.handleSubmit();
        }
    };
    render() {
        const { text } = this.props;
        return (
            <PopconfirmTip
                key="revokeBtn"
                title={`确定要${text}该角色吗？`}
                onConfirm={this.handleRevoke}
            >
                <span>{text}</span>
            </PopconfirmTip>
        );
    }
}
export default Remove;
