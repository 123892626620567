/*
 * @Author: 陈金金
 * @Date: 2020-07-14 18:32:55
 * @LastEditTime: 2021-02-20 15:40:30
 * @LastEditors: 陈金金
 * @Description: 项目看板详情
 * @FilePath: \BPO\src\pages\workbench\index.jsx
 */
import React, { Component } from "react";
import { Button, Row, Col, Input, Form } from "antd";
import "./detail.less";
import Table from "../../../../components/table/index";
import ModalData from "./Components/ModalData";
import { getQueryString } from "../../../../utils/common";
import ExportBtn from "../../../../components/exportBtn"; // 导出
import TooltipComponent from "../../../../components/TooltipComponent"; // 文本溢出提示
import {
  detailKey,
  detailSearchFormDataApply,
  detailColumns,
} from "../../data";
class ProjectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 3,
      title: "",
      type: 0,
      loading: false,
      visible: false,
      page: 1,
      pageSize: 10,
      isCard: false,
      StaffList: [],
      searchData: detailSearchFormDataApply(),
      searchValue: {},
      detailData: {},
      projectId: 0,
      sortParam: {},
    };
  }
  componentDidMount() {
    if (getQueryString("projectId")) {
      this.setState(
        {
          projectId: getQueryString("projectId"),
        },
        () => {
          this.getDetail(getQueryString("projectId"));
          this.getStaffList();
        }
      );
    }
  }
  getDetail = (projectId) => {
    this.$http.getProjectInfoById({ projectId }).then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          detailData: res.data,
        });
      }
    });
  };
  /**
   * @description: 项目人员列表
   * @param {type}
   * @return:
   * @author: 陈金金
   */
  getStaffList = (page = this.state.page, pageSize = this.state.pageSize) => {
    const { projectId, searchValue, sortParam } = this.state;
    this.setState({ loading: true });
    this.$http
      .pageStaffProjectRefList({
        page,
        pageSize,
        projectId,
        ...searchValue,
        ...sortParam,
      })
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.errorCode == 200) {
          this.setState({
            StaffList: res.data.rows,
            total: res.data.total,
            page,
            pageSize,
          });
        }
      });
  };
  /**
   * @description: 项目详情
   * @return:
   */
  renderDetailKeyHTML = () => {
    const { detailData } = this.state;
    return detailKey.map((item) => {
      return (
        <Col span={item.key == "approvalCost" ? 12 : 6} key={item.key}>
          <div className="detailbox">
            <span className="label">{item.name}</span>
            <div className="value">
              {detailData[item.key] ? (
                <TooltipComponent
                  text={
                    item.before != ""
                      ? this.$common.isPositiveIncome(detailData[item.key]) +
                        item.after
                      : item.after != ""
                      ? this.$common.accMul(detailData[item.key], 1) + "%"
                      : detailData[item.key] + item.after
                  }
                  //  text={item.before+detailData[item.key]+item.after}
                  width={item.key == "approvalCost" ? undefined : 116}
                />
              ) : (
                "--"
              )}
            </div>
            {item.key == "approvalCost" && (
              <span
                onClick={() => this.openModal("核准金额历史数据", 1)}
                className="approval"
              >
                核准金额历史数据
              </span>
            )}
          </div>
        </Col>
      );
    });
  };
  /**
   * @description: 人员搜索
   * @author: 陈金金
   */
  handleSearch = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState(
        {
          searchValue: values,
          StaffList: [],
          page: 1,
        },
        () => {
          this.getStaffList();
        }
      );
    });
  };
  onReset = () => {
    this.props.form.resetFields(); // 重置表单
    this.setState(
      {
        searchValue: {},
        StaffList: [],
        page: 1,
      },
      () => {
        this.getStaffList();
      }
    );
  };
  // 表格操作
  action = () => {
    return {
      title: "操作",
      width: 120,
      key: "operation",
      fixed: "right",
      render: (t, record, i) => {
        return (
          <span
            className="operation-wrap"
            onClick={() => this.openModal("人员信息详情", 3, record)}
          >
            <span>查看</span>
          </span>
        );
      },
    };
  };

  /**
   * @description: 人员搜索dom
   * @author: 陈金金
   */
  renderSearchHtml = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="inline">
        <Row>
          <Col span={12}>
            <Form.Item label={"人员搜索"}>
              {getFieldDecorator("keyword")(
                <Input
                  style={{ width: "270px" }}
                  placeholder="请输入工号/姓名"
                />
              )}
            </Form.Item>
            <div className="Space8">
              <Button type="primary" onClick={this.handleSearch}>
                {" "}
                搜 索
              </Button>
              <Button onClick={this.onReset}>重 置</Button>
            </div>
          </Col>
          <Col span={12}></Col>
        </Row>
      </Form>
    );
  };

  /**
   * @description:
   * @param {Number} type 1 ,核准金额历史数据 2 ,历史变更记录 3 ,查看
   * @param {String} title 标题
   * @return:
   * @author: 陈金金
   */
  openModal = (title, type, r) => {
    console.log("r ----->", r);
    this.setState({
      title,
      type,
      visible: true,
      modaldetailData: r ? r : undefined,
    });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };
  /**
   * @description: 排序
   * @param {type}   1.ascend 正序 2.descend 倒序
   * @author: 陈金金
   */
  handleChange = (p, f, s) => {
    if (s && s.order && s.order == "ascend") s.order = "ASC";
    if (s && s.order && s.order == "descend") s.order = "DESC";
    const obj = {};
    obj[`${s.columnKey}`] = s.order;
    this.setState(
      {
        sortParam: obj,
      },
      () => {
        this.getStaffList();
      }
    );
  };
  render() {
    const {
      total,
      page,
      pageSize,
      searchValue,
      sortParam,
      type,
      visible,
      loading,
      title,
      isCard,
      StaffList,
      projectId,
      modaldetailData,
    } = this.state;
    return (
      <div className="ProjectDetailBox">
        <div className="card">
          <div className="pjDetail">
            <div className="title">项目详情</div>
            <div className="btn Space8">
              <Button
                type="primary"
                onClick={() => this.openModal("历史变更记录", 2)}
              >
                历史变更记录
              </Button>
            </div>
          </div>
          <Row gutter={20}>{this.renderDetailKeyHTML()}</Row>
        </div>
        <div className="card">
          <div className="searchdetail">{this.renderSearchHtml()}</div>
          <div className="table" style={{ paddingBottom: isCard ? 0 : "" }}>
            <div className="Space8">
              {this.isArray(StaffList) && (
                <ExportBtn
                  text={"导出列表"}
                  className="btnColor"
                  params={{ ...searchValue, ...sortParam, projectId }}
                  url={this.$http.exportStaffProjectRef()}
                />
              )}
            </div>
            <Table
              keyName="staffCode"
              columns={[...detailColumns, this.action()]}
              dataSource={StaffList}
              scroll={{ x: "100%" }}
              loading={loading}
              total={total}
              current={page}
              pageSize={pageSize}
              isPagination={true}
              onChange={(p, f, s, e) => this.handleChange(p, f, s, e)}
              handleChangePage={this.getStaffList}
              handleChangePageSize={this.getStaffList}
            />
          </div>
        </div>
        {visible && (
          <ModalData
            title={title}
            visible={visible}
            loading={loading}
            type={type}
            projectId={projectId}
            ModalDetail={modaldetailData}
            closeModal={() => this.closeModal()}
          />
        )}
      </div>
    );
  }
}

export default Form.create()(ProjectDetail);
