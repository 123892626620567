/*
 * @Description: 组别页面
 * @Autor: 文倩
 * @Date: 2020-07-13 14:25:33
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-10 15:01:52
 */

import React, { Component } from "react";

import Table from "../../../../components/table";
import { groupColums } from "../../contants";

import AddGroup from "./addGroup"; //新增组别
import RemoveGroup from "./removeGroup"; //删除组别

import "../index.less";

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      total: 0,
      tableList: [],
      loading: false, //加载动画
      selectedRowKeys: [], //复选框选中的值
    };

    /**
     * @Description: 编辑
     * @author: 赵娟
     */
    this.operateColumns = [
      {
        title: "操作",
        width: 100,
        fixed: "right",
        render: (record) => {
          return (
            <div className="butt">
              <span className="operation-wrap">
                <AddGroup
                  type="list"
                  formData={record}
                  handleOk={this.queryTableList}
                />
              </span>
            </div>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.queryTableList();
  }

  /**
   * @description: 查询组别数据
   * @param {type}
   * @return:
   * @author: 文倩
   */
  queryTableList = (page = this.state.page, pageSize = 10) => {
    const query = {
      page,
      pageSize,
    };
    this.setState({
      loading: true,
    });
    this.$http.getGroupList(query).then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          tableList: res.data.rows,
          page,
          pageSize,
          total: res.data.total,
          selectedRowKeys: [],
        });
      }
      if (res) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  /**
   * @description: 修改组别
   * @param {type}
   * @return:
   * @author: 文倩
   */
  /* updateGroup = (values) => {
    const query = {
      groupId: values.groupId,
      groupName: values.groupName,
    };
    return this.$http.updateGroup(query);
  }; */

  /**
   * @description: 选中复选框的回调
   * @param {type}
   * @return:
   * @author: 文倩
   */
  onSelectChange = (keys) => {
    this.setState({
      selectedRowKeys: keys,
    });
    console.log(keys, "----------复选");
  };

  render() {
    let {
      tableList,
      selectedRowKeys,
      loading,
      page,
      pageSize,
      total,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div className="salary-table operation-table">
        <AddGroup handleOk={this.queryTableList} />
        <RemoveGroup
          rowkeys={selectedRowKeys}
          handleOk={() => {
            if (
              page > 1 &&
              selectedRowKeys.length > 0 &&
              selectedRowKeys.length <= 10 &&
              tableList.length <= 10
            ) {
              page = page - 1;
            }
            this.queryTableList(page);
          }}
        />
        <Table
          rowKey="groupId"
          loading={loading}
          scroll={{ x: "100%" }}
          columns={[...groupColums, ...this.operateColumns]}
          dataSource={tableList}
          total={total}
          current={page}
          pageSize={pageSize}
          rowSelection={rowSelection}
          isPagination={true}
          handleChangePage={this.queryTableList}
          handleChangePageSize={this.queryTableList}
        />
      </div>
    );
  }
}

export default Group;
