/*
 * @文档描述: 角色下拉列表----勿动
 * @author: 文倩
 * @Date: 2020-06-09 
 * @LastEditTime: 2020-07-28 16:23:42
 * @LastEditors: 文倩
 */
import React from 'react';
import { Form, Select } from 'antd';



const { Option } = Select;

class RoleSelect extends React.Component {
    state = {
        roleData: []
    };

    componentDidMount() {
        this.getRoleList();
    }
    
    /**
     * @函数描述: 查询全部角色
     * @return:
     */

    getRoleList = () => {
        this.$http.getEnableRole().then(res => {
            if (res.errorCode === 200) {
                this.setState({
                    roleData: res.data
                });
            } else {
                this.setState({
                    roleData: []
                });
            }
        });
    }
    /**
     * @函数描述: 渲染select数据
     * @param {type}
     * @return:
     */

    render() {
        const { getFieldDecorator } = this.props.form;
        const { label, required, message, initialValue, field, labelInValue, placeholder,mode,disabled } = this.props;
        const { roleData } = this.state;

        
        return (
            <div className="wareHouseSelect">
                <Form.Item label={label}>
                    {getFieldDecorator(field, {
                        initialValue: initialValue || undefined,
                        validate: [
                            {
                                rules: [{ required, message: `请选择${label || message}` }]
                            }
                        ]
                    })(
                        <Select
                            labelInValue={labelInValue}
                            mode={mode || ""}
                            allowClear
                            disabled={disabled}
                            placeholder={placeholder}>
                            {this.$common.isArray(roleData) && roleData.map(item => {
                                return <Option key={item.roleId} value={item.roleId}>{item.roleName}</Option>
                            })}
                        </Select>
                    )}
                </Form.Item>
            </div>
        );
    }
}

export default RoleSelect;
