/*
 * @Description: 项目类花名册列表
 * @Version: 2.0
 * @Autor: 文倩
 * @Date: 2020-07-17 10:24:24
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-17 14:16:46
 */

import React, { Component } from 'react';
import { Button, message } from 'antd';
import moment from 'moment';

import Table from '../../../../../components/table/index';

class Roster extends Component {
    render() {
        const { keyName,columns,scroll,total, current, pageSize,isPagination,handleChangePage,handleChangePageSize, loading, dataSource } = this.props;
        return (
            <Table
                keyName={keyName}
                columns={columns}
                dataSource={dataSource}
                scroll={scroll}
                loading={loading}
                total={total}
                current={current}
                pageSize={pageSize}
                isPagination={isPagination}
                handleChangePage={handleChangePage}
                handleChangePageSize={handleChangePageSize}
            />
        );
    }
}

export default Roster;