/*
 * @Description: 信息维护接口配置
 * @Author: 文倩
 * @Date: 2020-07-14 14:32:16
 * @LastEditTime: 2020-08-07 15:58:03
 * @LastEditors: 文倩
 */

import http from "../fetch";
const baseUrl = process.env.API_URL ? process.env.API_URL : "";

export default {
  // 流程配置列表
  getProcessList(param) {
    return http.post(
      `${baseUrl}/bpo/procConfig/listProcConfigByCondition`,
      param
    );
  },

  // 更新流程配置
  updateProcess(param) {
    return http.post(`${baseUrl}/bpo/procConfig/updateProcConfig`, param);
  },

  //固定成本列表
  getFixedCostList(param) {
    return http.post(`${baseUrl}/bpo/bpoFixedCost/listInfo`, param);
  },

  //固定成本--修改
  updateFixedCost(param) {
    return http.post(`${baseUrl}/bpo/bpoFixedCost/updateInfo`, param);
  },

  //社保公积金列表
  getSocialList(param) {
    return http.post(`${baseUrl}/bpo/bpoSocialSecurityFund/listInfo`, param);
  },

  //社保公积金--新增
  addSocial(param) {
    return http.post(`${baseUrl}/bpo/bpoSocialSecurityFund/addInfo`, param);
  },

  //社保公积金--修改
  updateSocial(param) {
    return http.post(`${baseUrl}/bpo/bpoSocialSecurityFund/updateInfo`, param);
  },

  //社保公积金--删除
  removeSocial(param) {
    return http.post(`${baseUrl}/bpo/bpoSocialSecurityFund/deleteInfo`, param);
  },

  //城市列表不分页
  getAllCityList(param) {
    return http.post(`${baseUrl}/bpo/bpoCity/listAllInfo`, param);
  },

  //层级薪资列表
  getSalaryList(param) {
    return http.post(`${baseUrl}/bpo/bpoRankSalary/listInfo`, param);
  },

  //层级薪资---新增
  addSalary(param) {
    return http.post(`${baseUrl}/bpo/bpoRankSalary/addInfo`, param);
  },

  //层级薪资---修改
  updateSalary(param) {
    return http.post(`${baseUrl}/bpo/bpoRankSalary/updateInfo`, param);
  },

  //层级薪资---删除
  removeSalary(param) {
    return http.post(`${baseUrl}/bpo/bpoRankSalary/deleteInfo`, param);
  },

  //层级薪资---下载导入模板
  downLoadTemp(param) {
    return http.get(
      `${baseUrl}/bpo/bpoRankSalary/downloadTemplate`,
      param,
      "",
      "blob"
    );
  },

  //层级薪资---导入
  importFile(param) {
    return http.post(
      `${baseUrl}/bpo/bpoRankSalary/importInfo`,
      param,
      false,
      "blob",
      true
    );
  },

  //层级薪资---导出错误数据
  importErrorFile(param) {
    return http.get(
      `${baseUrl}/bpo/bpoRankSalary/exportErrorInfo`,
      param,
      "",
      "blob"
    );
  },

  //组别列表
  getGroupList(param) {
    return http.post(`${baseUrl}/bpo/bpoGroup/listInfo`, param);
  },

  //组别---新增
  addGroup(param) {
    return http.post(`${baseUrl}/bpo/bpoGroup/addGroupWbs`, param);
  },

  //组别---修改
  updateGroup(param) {
    return http.post(`${baseUrl}/bpo/bpoGroup/updateGroupWbs`, param);
  },

  //组别---删除
  removeGroup(param) {
    return http.post(`${baseUrl}/bpo/bpoGroup/deleteInfo`, param);
  },

  //WBS列表
  getWBSList(param) {
    return http.post(`${baseUrl}/bpo/bpoWbs/listInfo`, param);
  },

  //WBS---新增
  addWBS(param) {
    return http.post(`${baseUrl}/bpo/bpoWbs/addInfo`, param);
  },

  //WBS---修改
  updateWBS(param) {
    return http.post(`${baseUrl}/bpo/bpoWbs/updateInfo`, param);
  },

  //WBS---删除
  removeWBS(param) {
    return http.post(`${baseUrl}/bpo/bpoWbs/deleteInfo`, param);
  },

  //城市列表
  getCityList(param) {
    return http.post(`${baseUrl}/bpo/bpoCity/listInfo`, param);
  },

  //城市---新增
  addCity(param) {
    return http.post(`${baseUrl}/bpo/bpoCity/addInfo`, param);
  },

  //城市---修改
  updateCity(param) {
    return http.post(`${baseUrl}/bpo/bpoCity/updateInfo`, param);
  },

  //城市---删除
  removeCity(param) {
    return http.post(`${baseUrl}/bpo/bpoCity/deleteInfo`, param);
  },

  //花名册信息列表
  getRosterInfoList(param) {
    return http.post(`${baseUrl}/bpo/bpoAsyncResult/bpoAsyncResultPage`, param);
  },

    //项目列表
    getProjectList(param) {
        return http.post(`${baseUrl}/bpo/projectInfo/pageProjectInfoBaseInfo`, param)
    },

    //项目---模版下载
    downLoadProjectTemp(param) {
        return http.get(`${baseUrl}/bpo/projectInfo/downProjectModel`, param, "", "blob")
    },

    //项目---文件导入
    importProjectFile(param) {
        return http.post(`${baseUrl}/bpo/projectInfo/importProjectInfoBaseInfo`, param, false, "blob", true)
    },

    //项目---下载导入失败
    
    importProjectErrorFile(param) {
      return http.get(
        `${baseUrl}/bpo/projectInfo/downErrData`,
        param,
        "",
        "blob"
      );
    },

    //项目编辑（组别，项目类型，业务类型下拉列表查询）
    getEditInfo(param) {
        return http.get(`${baseUrl}/bpo/projectInfo/getEditInfo`, param)
    },

    //项目--修改
    updateProject(param) {
        return http.post(`${baseUrl}/bpo/projectInfo/editProjectInfoBaseInfo`, param)
    },

    //花名册信息列表
    getRosterInfoList(param) {
        return http.post(`${baseUrl}/bpo/bpoAsyncResult/bpoAsyncResultPage`, param)
    },
  //花名册信息--模板下载
  downLoadRoster(param) {
    return http.get(
      `${baseUrl}/bpo/bpoAsyncResult/downloadTemplate`,
      param,
      "",
      "blob"
    );
  },

  //花名册信息--导入项目类
  importRoster1(param) {
    return http.post(
      `${baseUrl}/bpo/bpoAsyncResult/projectImportBpoAsync`,
      param,
      false,
      "blob",
      true
    );
  },

  //花名册信息--导入中后台类
  importRoster2(param) {
    return http.post(
      `${baseUrl}/bpo/bpoAsyncResult/backGroundImportBpoAsync`,
      param,
      false,
      "blob",
      true
    );
  },

  //花名册信息--导入招聘类
  importRoster3(param) {
    return http.post(
      `${baseUrl}/bpo/bpoAsyncResult/inviteImportBpoAsync`,
      param,
      false,
      "blob",
      true
    );
  },

  //组织架构列表
  getOrgTree(param) {
    return http.post(`${baseUrl}/bpo/org/getOrgTree`, param);
  },

  //组织架构查询详情
  getOrgTreeDetail(param) {
    return http.post(`${baseUrl}/bpo/org/selectOrgByCode`, param);
  },

  //组织架构---添加
  addOrgTree(param) {
    return http.post(`${baseUrl}/bpo/org/addOrg`, param);
  },

  //组织架构---修改
  updateOrgTree(param) {
    return http.post(`${baseUrl}/bpo/org/updateOrganization`, param);
  },

  //组织架构---删除
  removeOrgTree(param) {
    return http.post(`${baseUrl}/bpo/org/deleteOrganization`, param);
  },

  //组织架构---获取code
  getOrgCode(param) {
    return http.post(`${baseUrl}/bpo/org/findLastOrg`, param);
  },

  //层级薪资---获取字典
  queryDicCache(param) {
    return http.post(`${baseUrl}/bpo/dicCache/queryDicCache`, param);
  },
};
