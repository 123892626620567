import React, { Component } from 'react';

import NoData from '../../../../../components/noData';

import './index.less';

class TableConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        const { columns, dataSource } = this.props;
        
        return (
            <div className="tableConfig">
                {this.$common.isArray(dataSource) ?
                    <ul>
                        {this.$common.isArray(columns) && columns.map(item => {
                            return (
                                <li key={item.dataIndex}>
                                    <h3>
                                        <span style={{ width: item.width + 'px' }}>{item.title}</span>
                                    </h3>
                                    <div className="font">
                                        {item.render(
                                            dataSource[item.dataIndex] && dataSource[item.dataIndex].taskDefValue,
                                            dataSource[item.dataIndex],
                                            dataSource
                                        )}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    :
                    <div className="noData" style={{ width: '100%' }}>
                        <NoData width={200} />
                    </div>
                }
            </div>
        );
    }
}

export default TableConfig;