/*
 * @Description: 社保公积金成本
 * @Autor: 文倩
 * @Date: 2020-07-13 14:25:33
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-10 15:02:02
 */

import React, { Component } from "react";


import Table from '../../../../components/tableSmall';
import { socialInsuranceColums } from '../../contants';

import AddSocial from './addSocial';    //添加社保公积金成本
import RemoveSocial from './removeSocial';  //删除社保公积金成本

import '../index.less';

class SocialInsurance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 10,
            total: 0,
            tableList: [],
            loading: false,    //加载动画
            selectkeyChange: [],   //复选框选中的值
        }
    }


    componentDidMount() {
        this.queryTableList();
    }

    /**
     * @description: 查询社保公积金数据
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    queryTableList = (page = this.state.page, pageSize = 10) => {
        const query = {
            page,
            pageSize,
        }
        this.setState({
            loading: true
        });
        this.$http.getSocialList(query).then(res => {
            if (res && res.errorCode) {
                this.setState({
                    tableList: res.data.rows,
                    page,
                    pageSize,
                    total: res.data.total,
                    selectkeyChange: []
                });
            }
            if (res) {
                this.setState({
                    loading: false
                });
            }
        })
    }


    /**
     * @description: 修改社保公积金成本
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    updateSocial = (values) => {
        const query = {
            socialSecurityFundId: values.socialSecurityFundId,
            cityId: values.cityId,
            socialSecurityFundCost: values.socialSecurityFundCost
        }
        return this.$http.updateSocial(query);
    }

    /**
     * @description: 选中复选框的回调
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    onSelectChange = keys => {
        console.log(keys, '----------复选');
        this.setState({
            selectkeyChange: keys
        });
    }


    render() {
        let { tableList, loading, selectkeyChange, page, pageSize, total } = this.state;


        const rowSelection = {
            selectedRowKeys: selectkeyChange,
            onChange: this.onSelectChange
        }

        return (
            <div className="operation-tableSmall">
                <AddSocial
                    handleOk={this.queryTableList} />
                {this.$common.isArray(tableList) &&
                    <RemoveSocial
                        rowkeys={selectkeyChange}
                        handleOk={() => {
                            if (page > 1 && selectkeyChange.length > 0
                                && selectkeyChange.length <= 10 &&
                                tableList.length <= 10 ) {
                                page = page - 1;
                            }
                            this.queryTableList(page);
                        }} />}
                <Table
                    rowKey="socialSecurityFundId"
                    showHeader={this.$common.isArray(tableList) ? true : false}
                    bordered={false}
                    loading={loading}
                    current={page}
                    pageSize={pageSize}
                    total={total}
                    isPagination={true}
                    operateClassName="null"
                    width={120}
                    rowSelection={rowSelection}
                    columns={socialInsuranceColums}
                    dataSource={tableList}
                    handleSubmit={(val) => this.updateSocial(val)}
                    handleOk={this.queryTableList}
                    handleChangePage={this.queryTableList}
                    handleChangePageSize={this.queryTableList} />
            </div>
        );
    }
}

export default SocialInsurance;