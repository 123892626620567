/*
 * @Description: 
 * @Author: 文倩
 * @Date: 2020-07-09 14:53:14
 * @LastEditTime: 2020-07-31 12:17:28
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-07-09 14:53:14
 * @LastEditors: 文倩
 */

import React from 'react';
import ReactDOM from 'react-dom';
import Router from './routers';
import '../src/assets/css/reset.less'
import Es6Promise from 'es6-promise'
import './index.css'
import { ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import Api from '../src/api/apis'
import md5 from 'js-md5'
import {Provider} from "react-redux"
import {initStore}  from './store'
import history from './api/history';
import 'babel-polyfill'
import utils from './utils/utils'
import*as common from './utils/common';
moment.locale('zh-cn');
require('es6-promise').polyfill()
Es6Promise.polyfill()

const stores = initStore({})

// md5加密
React.Component.prototype.$md5 = md5;
React.Component.prototype.$http = Api;
React.Component.prototype.$utils = utils;
React.Component.prototype.$common = common;
//  两数相乘 求积
React.Component.prototype.mcl = function (num1, num2) {
  let m = 0,
    s1 = num1.toString(),
    s2 = num2.toString();
  try {
    m += s1.split(".")[1].length
  } catch (e) {}
  try {
    m += s2.split(".")[1].length
  } catch (e) {}
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}
// 截取路由参数
React.Component.prototype.getQueryVariable = function (variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return (false);
}
// 判断数组有没有值
React.Component.prototype.isArray = (arr) => {
  if (Array.isArray(arr) && arr.length > 0) {
      return true;
  } else {
      return false;
  }
}
// 判断权限
React.Component.prototype.$showBtn = function (btnName) {
  return this.props.AuthBtn.indexOf(btnName) > -1 ? true : false
};
ReactDOM.render(
  <Provider store={stores}>
    <ConfigProvider locale={zhCN}>
      <Router history={history} />
    </ConfigProvider>
  </Provider>
  , document.getElementById('root')
);
