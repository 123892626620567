/*
 * @文档描述: 组织架构 --- 删除
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-08-11 11:36:20
 * @最后修改人: 文倩
 */
import React, { Component } from "react";
import { Button, message } from "antd";
import _ from "lodash";

import PopconfirmTip from "../../../../../components/Popconfirm"; // 删除二次确认

class Remove extends Component {
  /**
   * @函数描述: 确定通过审批
   * @param {String} RevokeMessage  审批意见
   * @param {String} id
   * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
   * @return: 记得重置表单呦，重新表格当前页数据
   */
  handleRevoke = () => {
    const { record } = this.props;

    this.$http.removeOrgTree({ ...record }).then((res) => {
      if (res && res.errorCode == 200) {
        message.destroy();
        message.success(res.message);
        this.props.handleSubmit();
      }
    });
  };
  render() {
    const { record } = this.props;
    return (
      <PopconfirmTip
        key="revokeBtn"
        title={
          record.organizationLevel === 2
            ? `该部门下存在子部门，是否全部删除？`
            : `确定要删除该条数据吗？`
        }
        onConfirm={this.handleRevoke}
      >
        <Button className="btnColor">删除</Button>
      </PopconfirmTip>
    );
  }
}
export default Remove;
