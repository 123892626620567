import React, { Component } from "react";
import imgIcon from "../../assets/image/loginIcon2x.png";
import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "../../assets/image/logo.png";
import userNameIcon from "../../assets/image/username.png";
import pwsIcon from "../../assets/image/password.png";
import "./index.less";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoading: false,
    };
  }

  //登录
  onFinish = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ btnLoading: true });
        this.$http
          .login({
            loginName: values.loginName,
            loginPwd: this.$utils.encrypt(values.loginPwd),
          })
          .then((res) => {
            if (res && res.errorCode == 200) {
              this.$utils.set("userInfo", res.data);
              this.$utils.setUserInfo("token", res.data.sessionId);
              this.getMenuByUser();
            }
            this.setState({
              btnLoading: false,
            });
          })
          .catch((error) => {
            this.setState({
              btnLoading: false,
            });
          });
      }
    });
  };

  //跳转首次进入页面地址
  goLink = (v) => {
    if (this.$common.isArray(v.siderMenu))
      this.props.history.push(v.siderMenu[0].key);
    else this.props.history.push(v.key);
  };

  //获取按钮权限
  getMenuByUser = async () => {
    const res = await this.$http.getMenuByUser();
    if (res && res.errorCode == 200) {
      if (this.$common.isArray(res.data)) {
        this.$utils.set("menuList", res.data);
        this.goLink(res.data[0]);
      } else {
        this.props.history.push("/Page403");
      }
    } else {
      this.$utils.clearUserInfo();
      this.$utils.clear();
      this.props.history.push("/");
    }
  };

  componentDidMount() {
    // const { history } = this.props;
    // let token = this.$utils.getUserInfo('token');
    // if (token) {
    //     history.goBack();
    // }
  }

  render() {
    const { btnLoading } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="loginPage" style={{ minHeight: "610px" }}>
        <div className="loginBg" style={{ minHeight: "610px" }}>
          <div className="loginContent">
            <div className="LoginIcon">
              <img src={imgIcon} alt="" />
            </div>
            <div className="LoginForm">
              <div className="formtitle">
                <img src={logo} alt="" />
                <font style={{ color: "#3344FF" }}>BPO生态看板系统</font>
              </div>
              <p>全球客户信赖的IT综合服务提供商和战略合作伙伴</p>
              <div className="formbox">
                <Form
                  name="normal_login"
                  className="login-form"
                  onSubmit={this.onFinish}
                >
                  <Form.Item>
                    {getFieldDecorator("loginName", {
                      rules: [{ required: true, message: "请输入账号" }],
                    })(
                      <Input
                        maxLength={50}
                        prefix={
                          //   <UserOutlined className="site-form-item-icon" />
                          <img
                            src={userNameIcon}
                            className="sideMenuIcon"
                            alt="icon"
                          />
                        }
                        placeholder="请输入账号"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("loginPwd", {
                      rules: [{ required: true, message: "请输入密码" }],
                    })(
                      <Input
                        maxLength={50}
                        prefix={
                          //   <LockOutlined className="site-form-item-icon" />
                          <img
                            src={pwsIcon}
                            className="sideMenuIcon"
                            alt="icon"
                          />
                        }
                        type="password"
                        placeholder="请输入密码"
                      />
                    )}
                  </Form.Item>
                  <div className="submitbtn">
                    <Form.Item>
                      <Button
                        loading={btnLoading}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        登录
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className="loginfooter">
            <ul>
              <li>帮助</li>
              <li>隐私</li>
              <li>条款</li>
            </ul>
            <p>
              copyright <span style={{ fontSize: "13px" }}>&copy;</span>{" "}
              博彦科技项目产品部出品
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(Login);
