/*
 * @Description: 核准金额历史数据
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:46:51
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-08-26 11:17:22
 */

import React, { Component } from "react";
import { Form, Modal, Spin, message } from "antd";
import "./Components.less";
import Table from "../../../../../components/table/index";
import TooltipComponent from "../../../../../components/TooltipComponent"; // 文本溢出提示
import InputNumber from "../../../../../components/baseInput/number";
import { baseInput4 } from "../../../../../utils/validate.js";
class ApprovedAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 3,
      visible: false,
      Loading: false,
      pageNo: 1,
      pageSize: 10,
      courierDate: "",

      isCard: false,
      searchValue: {},
      detailData: {},
      dataSource: [
        { key: 1, number: 123 },
        { key: 2, number: 345 },
      ],
      selectedRowKeys: [],
    };
  }
  /**
   * @description: 核准金额修改
   * @author: 陈金金
   */
  handleAddSure = (i) => {
    const { projectId } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.$http
          .updateApprovalDetail({ projectId, ...values.dataSource[i] })
          .then((res) => {
            if (res && res.errorCode == 200) {
              message.destroy();
              message.success(res.message);
              // this.getList(projectId);
              this.props.closeModal();
              this.handleReturnStockBtnCancle();
            }
          });
      }
    });
  };

  handleReturnStockBtnCancle = () => {
    this.props.form.resetFields();
    this.setState({ visible: false });
  };

  handleModeChange = (e) => {
    console.log(e);
    this.setState({
      courierDate: e.format("YYYY-MM-DD"),
    });
    return e;
  };
  openModal = () => {
    const { projectId } = this.props;
    this.setState({ visible: true, Loading: true }, () => {
      this.getList(projectId);
    });
  };
  /**
   * @description: 核准金额列表
   * @param {String} projectId 项目ID
   * @author: 陈金金
   */
  getList = (projectId) => {
    this.setState({ Loading: true });
    this.$http.pageApprovalDetailByCondition({ projectId }).then((res) => {
      this.setState({ Loading: false });
      if (res && res.errorCode == 200) {
        this.setState({
          dataSource: res.data.rows,
          total: res.data.total,
        });
      }
    });
  };
  render() {
    const { visible, dataSource, Loading } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "时间",
        dataIndex: "approvalTime",
        key: "approvalTime",
        width: 160,
        render: (t) => (t ? <TooltipComponent width={100} text={t} /> : "-"),
      },
      {
        title: "核准后项目收入",
        dataIndex: "approvalIncome",
        key: "approvalIncome",
        width: 160,
        render: (t, r, i) => {
          return (
            <>
              <InputNumber
                form={form}
                required={true}
                allowClear
                initialValue={dataSource[i].approvalIncome || undefined}
                placeholder="请输入"
                validatorName={baseInput4}
                field={`dataSource[${i}].approvalIncome`}
                maxLength={10}
                step={0.01}
                max={9999999.99}
                style={{ width: 120 }}
                message="请输入数字"
              />
              {getFieldDecorator(`dataSource[${i}].approvalDetailId`, {
                initialValue: dataSource[i].approvalDetailId || undefined,
              })}
            </>
          );
        },
      },
      {
        title: "核准后项目成本",
        dataIndex: "approvalCost",
        key: "approvalCost",
        width: 160,
        render: (t, r, i) => {
          return (
            <InputNumber
              form={form}
              required={true}
              allowClear
              initialValue={dataSource[i].approvalCost || undefined}
              placeholder="请输入"
              validatorName={baseInput4}
              field={`dataSource[${i}].approvalCost`}
              maxLength={10}
              step={0.01}
              max={9999999.99}
              style={{ width: 120 }}
              message="请输入数字"
            />
          );
        },
      },
      {
        title: "操作",
        width: 100,
        fixed: "right",
        render: (t, record, i) => {
          return (
            <span className="operation-wrap">
              <span onClick={() => this.handleAddSure(i)}>保存</span>
            </span>
          );
        },
      },
    ];
    return (
      <>
        <span className="approval" onClick={() => this.openModal()}>
          核准金额历史数据调整
        </span>
        {visible && (
          <Modal
            className="ApprovedAmount"
            title="核准金额历史数据调整"
            visible={visible}
            width={620}
            footer={null}
            onCancel={this.handleReturnStockBtnCancle}
            maskClosable={false}
          >
            <Spin spinning={Loading}>
              <Form>
                <Table
                  rowKey={(r) => r.approvalDetailId}
                  className="leaseApplyList"
                  pagination={false}
                  columns={[...columns]}
                  dataSource={dataSource}
                />
              </Form>
            </Spin>
          </Modal>
        )}
      </>
    );
  }
}

export default Form.create()(ApprovedAmount);
