/*
 * @Description: 花名册
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-17 10:24:24
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-19 11:58:21
 */

import React, { Component } from "react";
import { Button, message } from "antd";
import moment from "moment";
import "../StatisticalReport.less";
import Search from "../../../components/searchForm";
import Table from "../../../components/table/index";

import Project from "./component/projectRoster";
import Middle from "./component/middleRoster";
import Recruit from "./component/recruitRoster";

import {
  searchFormDataApply,
  projectColumns,
  middleColumns,
  recruitColumns,
} from "./data";
class Roster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      loading: false,
      isExportShow: false,
      pageNo: 1,
      pageSize: 10,
      searchData: searchFormDataApply,
      searchValue: {},
      dataSource: [],
    };
  }

  componentDidMount() {
    this.queryTableList();
    this.queryDeptAndSub();
  }

  /**
   * @description: 查询列表数据
   * @param {type}
   * @return {type}
   * @author: 文倩
   */
  queryTableList = (
    page = this.state.pageNo,
    pageSize = this.state.pageSize
  ) => {
    const { searchValue } = this.state;
    searchValue.type = (searchValue && searchValue.type) || "PROJECT";
    searchValue.staffStatus =
      searchValue.staffStatus === "null" ? null : searchValue.staffStatus;
    const query = {
      page,
      pageSize,
      ...searchValue,
    };
    this.setState({
      loading: true,
    });
    this.$http.getRosterList(query).then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          dataSource: res.data.rows,
          total: res.data.total,
          pageNo: page,
          pageSize,
        });
      }
      if (res) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  /**
   * @description: 部门数据请求
   * @author: 赵娟
   */

  queryDeptAndSub = async () => {
    const res = await this.$http.getDeptAndSub();
    if (res && res.errorCode == 200) {
      searchFormDataApply[3].selectData = res.data;
      this.setState({
        searchData: searchFormDataApply,
      });
    }
  };

  /**
   * @description: 查询搜索列表数据
   * @param {type}
   * @return {type}
   * @author: 文倩
   */
  querySearch = (val) => {
    this.setState(
      {
        searchValue: val || {},
        pageNo: 1,
      },
      () => {
        this.queryTableList();
      }
    );
  };

  /**
   * @description:根据搜索条件渲染不同列表
   * @param {type}
   * @return {type}
   * @author: 文倩
   */
  renderTable = () => {
    const {
      total,
      pageNo,
      pageSize,
      searchValue,
      loading,
      dataSource,
    } = this.state;
    const tableHeadObj = {
      PROJECT: () => {
        return (
          <Project
            keyName="staffId"
            columns={projectColumns}
            dataSource={dataSource}
            scroll={{ x: "100%" }}
            loading={loading}
            total={total}
            current={pageNo}
            pageSize={pageSize}
            isPagination={true}
            handleChangePage={this.queryTableList}
            handleChangePageSize={this.queryTableList}
          />
        );
      },
      BACKSTAGE: () => {
        return (
          <Middle
            keyName="staffId"
            columns={middleColumns}
            dataSource={dataSource}
            scroll={{ x: "100%" }}
            loading={loading}
            total={total}
            current={pageNo}
            pageSize={pageSize}
            isPagination={true}
            handleChangePage={this.queryTableList}
            handleChangePageSize={this.queryTableList}
          />
        );
      },
      RECRUI: () => {
        return (
          <Recruit
            keyName="staffId"
            columns={recruitColumns}
            dataSource={dataSource}
            scroll={{ x: "100%" }}
            loading={loading}
            total={total}
            current={pageNo}
            pageSize={pageSize}
            isPagination={true}
            handleChangePage={this.queryTableList}
            handleChangePageSize={this.queryTableList}
          />
        );
      },
    };
    return tableHeadObj[searchValue.type ? searchValue.type : "PROJECT"]();
  };

  /**
   * @description: 导出花名册
   * @param {type}
   * @return {type}
   * @author: 文倩
   */
  exportExcel = () => {
    const { searchValue } = this.state;
    const query = {
      type: (searchValue && searchValue.type) || "PROJECT",
      staffCode: (searchValue && searchValue.staffCode) || null,
      staffName: (searchValue && searchValue.staffName) || null,
    };
    this.setState({
      isExportShow: true,
    });
    this.$http.exportRoster(query).then((res) => {
      if (res && res.request && res.request.responseType == "blob") {
        var reader = new FileReader();
        reader.onload = () => {
          try {
            var content = JSON.parse(reader.result); //内容就在这里
            if (content && content.code != 200) {
              message.warning(content.message);
            }
          } catch {
            const date = moment()
              .format("YYYY-MM-DD HH:mm:ss")
              .replace(/[\s|\-| |\:]/g, "");
            this.$common.getExcel(res, date);
          }
        };
        reader.readAsText(res.data);
      }
      if (res) {
        this.setState({
          isExportShow: false,
        });
      }
    });
  };

  render() {
    const {
      total,
      pageNo,
      pageSize,
      searchData,
      searchValue,
      loading,
      dataSource,
      isExportShow,
    } = this.state;
    return (
      <div className="ProjectListBox StatisticalReport">
        <div className="account">
          <div className="searchbox">
            <Search
              tabList={[{ value: "花名册" }]}
              FormData={searchData}
              handleSubmit={(values) => {
                this.querySearch(values);
              }}
            />
          </div>

          <div className="operation-table">
            {this.$common.isArray(dataSource) && (
              <div className="Space8">
                <Button
                  // className="btnColor"
                  loading={isExportShow}
                  onClick={this.exportExcel}
                >
                  导出列表
                </Button>
              </div>
            )}
            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}

export default Roster;
