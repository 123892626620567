/*
 * @Description: 
 * @Author: 文倩
 * @Date: 2020-06-01 10:25:07
 * @LastEditTime: 2020-06-05 18:32:00
 * @LastEditors: 文倩
 */

import { combineReducers } from "redux";
import menu from './menu';

const rootReducer = combineReducers({
  menu
});

export default rootReducer;
