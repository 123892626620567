/*
 * @Description: WBS页面
 * @Autor: 文倩
 * @Date: 2020-07-13 14:25:33
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-10 15:02:11
 */

import React, { Component } from "react";
import { Button } from 'antd';

import Table from '../../../../components/tableSmall';
import { WbsColums } from '../../contants';

import AddWBS from './addWbs';  //新增WBS
import RemoveWBS from './removeWbs'; //删除WBS

import '../index.less';

class WBS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 10,
            total: 0,
            tableList: [],
            loading: false,   //加载动画
            selectedRowKeys: []  //复选框选中的值
        }
    }


    componentDidMount() {
        this.queryTableList();
    }


    /**
     * @description: 查询WBS数据
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    queryTableList = (page=this.state.page,pageSize=10) => {
        const query = {
            page,
            pageSize
        }
        this.setState({
            loading: true
        });
        this.$http.getWBSList(query).then(res => {
            if (res && res.errorCode == 200) {
                this.setState({
                    tableList: res.data.rows,
                    page,
                    pageSize,
                    total: res.data.total,
                    selectedRowKeys:[]
                });
            }
            if (res) {
                this.setState({
                    loading: false
                });
            }
        })
    }

    /**
     * @description: 修改WBS
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    updateWBS = (values) => {
        const query = {
            wbsId: values.wbsId,
            wbsName: values.wbsName
        }
        return this.$http.updateWBS(query);
    }

    /**
     * @description: 选中复选框的回调
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    onSelectChange = keys => {
        this.setState({
            selectedRowKeys: keys
        });
        console.log(keys, '----------复选');
    }


    render() {
        let { tableList, loading, selectedRowKeys,page,pageSize,total } = this.state;


        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange
        }

        return (
            <div className="operation-tableSmall">
                <AddWBS
                    handleOk={this.queryTableList} />
                <RemoveWBS
                    rowkeys={selectedRowKeys}
                    handleOk={()=>{
                        if (page > 1 && selectedRowKeys.length > 0
                            && selectedRowKeys.length <= 10 &&
                            tableList.length <= 10 ) {
                                page = page - 1;
                        }
                        this.queryTableList(page);
                    }} />
                <Table
                    rowKey="wbsId"
                    showHeader={true}
                    bordered={false}
                    loading={loading}
                    operateClassName="null"
                    width={120}
                    current={page}
                    pageSize={pageSize}
                    total={total}
                    isPagination={true}
                    rowSelection={rowSelection}
                    columns={WbsColums}
                    dataSource={tableList}
                    handleSubmit={(val) => this.updateWBS(val)}
                    handleOk={this.queryTableList}
                    handleChangePage={this.queryTableList}
                    handleChangePageSize={this.queryTableList} />
            </div>
        );
    }
}

export default WBS;