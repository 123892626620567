/*
 * @文档描述:城市 --- 新增
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-08-11 11:12:08
 * @最后修改人: 文倩
 */
import React, { Component, Fragment } from 'react';
import { Modal, Form, Button } from 'antd';


import BaseInput from '../../../../../components/baseInput/index';

import { baseInput7 } from '../../../../../utils/validate';




class AddCity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false, // 编辑弹框显隐
            
        };
    }

    

    

    /**
     * @函数描述: 点击显示编辑弹框
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return:
     */
    handlePassBtnShow = () => {
        this.setState({
            visible: true
        });
    };
    
    /**
     * @函数描述: 取消弹框显示
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦
     */
    handlePassBtnCancle = () => {
        this.setState(
            {
                visible: false
            },
            () => {
                this.props.form.resetFields();
            }
        );
    };
    /**
     * @函数描述: 确定编辑成功
     * @param {String} passMessage  审批意见
     * @param {String} id
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦，重新表格当前页数据
     */
    handleAddSure = e => {
        e.preventDefault();
        const { validateFieldsAndScroll } = this.props.form;


        validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                const query={
                    ...values
                }
                this.$http.addCity(query).then(res=>{
                    if(res && res.errorCode == 200){
                        this.handlePassBtnCancle();
                        this.props.handleOk();
                    }
                })
            }
        });
    };






    render() {
        const { form } = this.props;
        const { visible } = this.state;

        return (
            <Fragment key="passBtn">
                <Button onClick={this.handlePassBtnShow} type="primary">
                    新增
                    </Button>
                <Modal
                    className="reviseModal"
                    title="新增"
                    visible={visible}
                    width={518}
                    onCancel={this.handlePassBtnCancle}
                    onOk={this.handleAddSure}
                    maskClosable={false}
                    okText="提交"
                >
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }}>
                        <BaseInput
                            form={form}
                            label="城市"
                            field='cityName'
                            required={true}
                            message={"长度为10，可输入中文、数字、英文、特殊符号"}
                            placeholder='请输入城市'
                            validatorName={baseInput7}
                            maxLength={10}
                        />
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}
export default Form.create()(AddCity);
