/*
 * @Description: 组织信息页面
 * @Author: 文倩
 * @Date: 2020-07-17 15:46:31
 * @LastEditTime: 2020-08-06 17:11:37
 * @LastEditors: 文倩
 */

import React, { Component, Fragment } from "react";
import { Button, Form, Select, message } from "antd";
import _ from "lodash";

import BaseInput from "../../../../../components/baseInput/index";
import Tooltip from "../../../../../components/TooltipComponent";
import { organCols, organForm } from "../../../contants";

import AccountSelect from "../accountSelect"; //账号下拉

import Remove from "../remove"; //删除组织信息

import "./index.less";

const { Option } = Select;

class OrganInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditForm: false, //是否编辑表单
      isAddOrg: false, //是否添加部门
      formDataList: organForm, //表单配置项
      selectedKeys: ["001"], //当前选中的节点
    };
  }

  componentDidMount() {
    const { isAddorg } = this.props;
    this.setState({
      isAddOrg: isAddorg,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedKeys: this.props.selectedKeys,
    });
    if (this.props.detailCon != nextProps.detailCon) {
      this.renderFormData(nextProps);
    }

    if (this.props.isAddorg != nextProps.isAddorg) {
      this.setState({
        isAddOrg: nextProps.isAddorg,
      });
      this.props.form.resetFields();
    }
  }

  /**
   * @description: 回填表单的值
   * @param {type}
   * @return:
   * @author: 文倩
   */
  renderFormData = (props) => {
    const { detailCon, form } = props;
    const { isEditForm } = this.state;
    if (isEditForm) {
      const params = {
        organizationName: detailCon.organizationName,
        organizationCode: detailCon.organizationCode,
        parentOrgCode: detailCon.parentOrgCode,
        administrator: this.$common.isArray(detailCon.administrators)
          ? _.map(detailCon.administrators, "staffId")
          : [],
        delivery: this.$common.isArray(detailCon.deliverys)
          ? _.map(detailCon.deliverys, "staffId")
          : [],
        bp: this.$common.isArray(detailCon.bps)
          ? _.map(detailCon.bps, "staffId")
          : [],
      };
      form.setFieldsValue(params);
    }
  };

  /**
   * @description: 渲染非编辑状态页面
   * @param {type}
   * @return:
   * @author: 文倩
   */
  renderTableShow = () => {
    const { detailCon } = this.props;

    return (
      <div className="orgContent">
        <ul className="ulList">
          {organCols.map((item) => {
            return (
              <li key={item.filed}>
                <font>{item.name}</font>
                <span className="span">
                  <Tooltip
                    width={"100%"}
                    text={
                      Array.isArray(detailCon[item.filed])
                        ? this.$common.isArray(detailCon[item.filed])
                          ? _.map(detailCon[item.filed], "staffName").join(",")
                          : "-"
                        : detailCon[item.filed] || "-"
                    }
                  />
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  /**
   * @description: 渲染编辑状态表单
   * @param {type}
   * @return:
   * @author: 文倩
   */
  renderForm = () => {
    const { formDataList } = this.state;
    const { detailCon } = this.props;
    return (
      <div className="orgContent">
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }}>
          {formDataList(detailCon).map((item, i) => {
            return <Fragment key={i}>{this.handleForm(item, i)}</Fragment>;
          })}
          <Form.Item style={{ textAlign: "right" }} className="buttRight">
            <Button type="primary" onClick={() => this.handleSubmit()}>
              保存
            </Button>
            <Button className="btnColor" onClick={() => this.updateCancel()}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  /**
   * @description: 表单值发生改变时回调
   * @param {type}
   * @return:
   * @author: 文倩
   */
  changeValForm = (e) => {
    const { form } = this.props;
    const { isAddOrg } = this.state;
    if (isAddOrg && e.target.value != "") {
      this.$http.getOrgCode().then((res) => {
        if (res && res.errorCode == 200) {
          form.setFieldsValue({
            organizationCode: res.data,
            parentOrgCode: this.state.selectedKeys,
          });
        }
      });
    }
  };

  /**
   * @description: 提交表单数据
   * @param {type}
   * @return:
   * @author: 文倩
   */
  handleSubmit = () => {
    const { isAddOrg } = this.state;
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const query = {
          organizationCode: values.organizationCode,
          parentOrgCode: values.parentOrgCode,
          organizationName: values.organizationName,
          administrators: this.checkAddParams(values.administrator),
          deliverys: this.checkAddParams(values.delivery),
          bps: this.checkAddParams(values.bp),
        };

        const params = {
          organizationCode: values.organizationCode,
          parentOrgCode: values.parentOrgCode,
          organizationName: values.organizationName,
          administrators: _.uniqBy(
            this.checkAddParams(values.administrator),
            "staffId"
          ),
          deliverys: _.uniqBy(this.checkAddParams(values.delivery), "staffId"),
          bps: _.uniqBy(this.checkAddParams(values.bp), "staffId"),
        };

        let res;

        if (isAddOrg) {
          res = await this.$http.addOrgTree(query);
        } else {
          res = await this.$http.updateOrgTree(params);
        }

        if (res && res.errorCode == 200) {
          message.destroy();
          message.success(res.message);
          this.props.handleOk();
          this.setState({
            isEditForm: false,
          });
        }
      }
    });
  };

  /**
   * @description: 查询添加部门传参
   * @param {type}
   * @return:
   * @author: 文倩
   */
  checkAddParams = (list) => {
    let newList = [];
    if (this.$common.isArray(list)) {
      list.map((item) => {
        newList.push({
          staffId: item,
        });
      });
    }
    return newList;
  };

  /**
   * @description: 循环表单项
   * @param {type}
   * @return:
   * @author: 文倩
   */
  handleForm = (item, index) => {
    const form = this.props.form;
    const { isAddOrg } = this.state;
    let html;
    switch (item.type) {
      case "input":
        html = (
          <BaseInput
            form={form}
            label={item.name}
            field={item.key}
            required={item.required}
            placeholder={item.placeholder}
            validatorName={item.validatorName}
            maxLength={item.maxLength}
            message={item.message}
            disabled={item.disabled}
            initialValue={!isAddOrg && item.initialValue}
          />
        );
        break;
      case "BaseInputCode":
        html = (
          <BaseInput
            form={form}
            label={item.name}
            field={item.key}
            required={item.required}
            placeholder={item.placeholder}
            validatorName={item.validatorName}
            maxLength={item.maxLength}
            message={item.message}
            disabled={item.disabled}
            initialValue={!isAddOrg && item.initialValue}
            onBlur={this.changeValForm}
          />
        );
        break;
      case "accountSelect":
        html = (
          <AccountSelect
            form={form}
            label={item.name}
            field={item.key}
            required={item.required}
            placeholder={item.placeholder || "请选择"}
            mode={item.mode || ""}
            labelInValue={item.labelInValue}
            message={item.message}
            disabled={item.disabled}
            initialValue={!isAddOrg && item.initialValue}
          />
        );
        break;
      case "Select":
        html = (
          <Form.Item label={item.name}>
            {this.props.form.getFieldDecorator(item.key, {
              initialValue: item.initialValue,
              rules: item.rules,
            })(
              <Select
                placeholder={item.placeholder || "请选择"}
                mode={item.mode || ""}
                form={form}
                disabled={item.disabled}
                allowClear
                labelInValue
              >
                {this.$common.isArray(item.selectData) &&
                  item.selectData.map((res) => {
                    return (
                      <Option
                        key={res[item.mapping ? item.mapping.value : "key"]}
                      >
                        {res[item.mapping ? item.mapping.name : "label"]}
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        );
        break;
      default:
        html = (
          <BaseInput
            form={form}
            label={item.name}
            field={item.key}
            required={item.required}
            placeholder={item.placeholder}
            validatorName={item.validatorName}
            maxLength={item.maxLength}
            message={item.message}
            disabled={item.disabled}
            initialValue={item.initialValue}
          />
        );
        break;
    }
    return html;
  };

  /**
   * @description: 切换为表单模式
   * @param {type}
   * @return:
   * @author: 文倩
   */
  updateForm = () => {
    this.setState(
      {
        isEditForm: true,
      },
      () => {
        this.renderFormData(this.props);
      }
    );
  };

  /**
   * @description: 取消编辑状态
   * @param {type}
   * @return:
   * @author: 文倩
   */
  updateCancel = () => {
    this.setState(
      {
        isEditForm: false,
        isAddOrg: false,
      },
      () => this.props.handleCancel(this.state.isAddOrg)
    );
  };

  render() {
    const { isEditForm, isAddOrg } = this.state;
    const { detailCon } = this.props;
    return (
      <div className="orgInfoCon">
        <div>
          <h4>组织信息</h4>
          {isEditForm || isAddOrg ? this.renderForm() : this.renderTableShow()}
        </div>
        {!isEditForm && !isAddOrg && (
          <div>
            <Button className="btnColor" onClick={() => this.updateForm()}>
              修改信息
            </Button>
            {detailCon.organizationCode != "001" && (
              <Remove
                record={detailCon}
                handleSubmit={() => this.props.handleRemove()}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Form.create()(OrganInfo);
