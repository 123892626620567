/*
 * @Description: 固定成本
 * @Autor: 文倩
 * @Date: 2020-07-13 14:25:33
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-10 15:01:09
 */

import React, { Component, Fragment } from "react";

import Table from '../../../../components/tableSmall';
import { fixedCostColums } from '../../contants';
import '../index.less';

class FixedCost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableList: [],
            loading: false,    //加载动画
        }
    }


    componentDidMount() {
        this.queryTableList();
    }


    /**
     * @description: 查询固定成本列表数据
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    queryTableList = () => {
        const query = {
            page: 1,
            pageSize: 999
        }
        this.setState({
            loading: true
        });
        this.$http.getFixedCostList(query).then(res => {
            if (res && res.errorCode == 200) {
                this.setState({
                    tableList: res.data.rows
                });
            }
            if (res) {
                this.setState({
                    loading: false
                });
            }
        });
    }


    /**
     * @description: 修改固定成本
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    updateFixedCost = (values) => {
        const query = {
            fixedCostId: values.fixedCostId,
            fixedCostValue: values.fixedCostValue
        }
        return this.$http.updateFixedCost(query);
    }

    render() {
        const { tableList, loading } = this.state;

        return (
            <Fragment>
                <Table
                    rowKey="fixedCostId"
                    showHeader={false}
                    bordered={true}
                    className={"table-smallFix"}
                    operateClassName="operate"
                    width={20}
                    loading={loading}
                    columns={fixedCostColums}
                    dataSource={tableList}
                    handleSubmit={(val) => this.updateFixedCost(val)}
                    handleOk={this.queryTableList} />
            </Fragment>
        );
    }
}

export default FixedCost;