/*
 * @Description: 月度在册人员统计
 * @Version: 2.0
 * @Autor: 赵娟
 * @Date: 2020-11-24 10:24:24
 */

import React from "react";
import TooltipComponent from "../../../components/TooltipComponent"; // 文本溢出提示
import { baseInput7 } from "../../../utils/validate";
import {
  isPositiveIncome,
  accMul,
} from "../../../utils/common";
import moment from "moment";
/**
 * @description: 查询条件
 * @author: 赵娟
 */
const myDate = new Date();
const tYear = myDate.getFullYear();
const tMonth = myDate.getMonth() + 1;
export const searchFormDataApply = [
  {
    label: "统计时间",
    name: "month",
    type: "MonthPicker",
    initialValue: moment(
      tYear + "-" + tMonth,
      "YYYY-MM"
    ).subtract(1, "months"),
  },
  {
    label: "部门",
    name: "deptIds",
    type: "treeSelect",
    idOrName: true,
    selectData: [],
    placeholder: "全部",
  },
];

/**
 * @description: 表头信息
 * @author: 赵娟
 */
export const columns = [
  {
    title: "序号",
    width: 60,
    fixed: "left",
    render: (text, record, index) => `${index + 1}`,
  },
  {
    title: "部门",
    dataIndex: "deptName",
    key: "deptName",
    width: 200,
    fixed: "left",
    render: (t) =>
      t ? <TooltipComponent width={160} text={t} /> : "-",
  },
  {
    title: "不付费人数",
    key: "noPayPerson",
    dataIndex: "noPayPerson",
    width: 140,
    render: (t) =>
      t ? <TooltipComponent width={140} text={t} /> : "-",
  },
  {
    title: "在职正式人员",
    key: "formal",
    dataIndex: "formal",
    width: 140,
    render: (t) =>
      t ? <TooltipComponent width={150} text={t} /> : "-",
  },
  {
    title: "在职非正式人员",
    key: "noFormal",
    dataIndex: "noFormal",
    width: 130,
    render: (t) =>
      t ? <TooltipComponent width={120} text={t} /> : "-",
  },
  {
    title: "月底在职人数",
    key: "workNum",
    dataIndex: "workNum",
    width: 140,
    render: (t) =>
      t ? <TooltipComponent width={150} text={t} /> : "-",
  },
  {
    title: "离职(正式)人数",
    key: "formalResignation",
    dataIndex: "formalResignation",
    width: 140,
    render: (t) =>
      t ? <TooltipComponent width={230} text={t} /> : "-",
  },
  {
    title: "离职(非正式)人数",
    key: "informalResignation",
    dataIndex: "informalResignation",
    width: 140,
    render: (t) =>
      t ? <TooltipComponent width={70} text={t} /> : "-",
  },
  {
    title: "入职人数",
    key: "entry",
    dataIndex: "entry",
    width: 140,
    render: (t) =>
      t ? <TooltipComponent width={130} text={t} /> : "-",
  },
  {
    title: "项目个数",
    key: "projectNum",
    dataIndex: "projectNum",
    width: 140,
    render: (t) =>
      t ? <TooltipComponent width={130} text={t} /> : "-",
  },
];
