import React from "react";
import { Popconfirm } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
export default (props) => {
  return (
    <Popconfirm
      // autoAdjustOverflow
      overlayStyle={{ borderRadius: "10px" }}
      title={
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#000000",
            paddingLeft: "8px",
          }}
        >
          {props.title}
        </div>
      }
      cancelText="取消"
      okText={<div style={{ fontSize: "14px" }}>确定</div>}
      onConfirm={() => props.onConfirm(props.record, 1)}
      onCancel={props.onCancel ? props.onCancel : null}
      icon={<CloseCircleFilled style={{ fontSize: "22px", color: "red" }} />}
    >
      {props.children}
    </Popconfirm>
  );
};
