/*
 * @Description: 系统配置接口
 * @Author: 文倩
 * @Date: 2020-06-02 10:40:39
 * @LastEditTime: 2020-08-05 09:58:15
 * @LastEditor: 文倩
 */

import http from '../fetch'
const baseUrl = process.env.API_URL ? process.env.API_URL : '';

export default {

    //账号管理----列表
    getAccountList(param, callbak) {
        return http.post(`${baseUrl}/bpo/staffInfo/findStaffPageList`, param)
    },

    //账号管理----停用/启用
    enadleAccount(param, callbak) {
        return http.post(`${baseUrl}/bpo/staffInfo/staffStartOrStop`, param)
    },

    //账号管理----修改
    updateAccount(param, callbak) {
        return http.post(`${baseUrl}/bpo/staffInfo/updateStaff`, param)
    },

    //账号管理---账号下拉不分页(查询启用的账号)
    getAccountNopage(param, callbak) {
        return http.post(`${baseUrl}/bpo/staffInfo/selectStaffList`, param)
    },


    //获取全部启用的角色
    getEnableRole(param, callbak) {
        return http.post(`${baseUrl}/bpo/role/findStateRolePermissionList`, param)
    },

    //获取全部启用的角色--流程配置
    getEnableRoleProcess(param, callbak) {
        return http.post(`${baseUrl}/bpo/role/queryProcConfigRole`, param)
    },
    
    
    //角色---列表
    getRoleList(param, callbak) {
        return http.post(`${baseUrl}/bpo/role/findRolePermissionPage`, param)
    },

    //角色--新增
    addRole(param, callbak) {
        return http.post(`${baseUrl}/bpo/role/addRolePermission`, param)
    },

    //角色--修改
    updateRole(param, callbak) {
        return http.post(`${baseUrl}/bpo/role/updateRolePermission`, param)
    },

    //角色--停用/启用
    enadleRole(param, callbak) {
        return http.post(`${baseUrl}/bpo/role/updateRoleStatus`, param)
    },

    //角色---删除
    removeRole(param, callbak) {
        return http.post(`${baseUrl}/bpo/role/deleteRole`, param)
    },

    //角色---根据用户查找菜单权限
    getMenuByUser(param, callbak) {
        return http.post(`${baseUrl}/bpo/menuPermisson/getMenuTreeByStaff`, param)
    },

    //角色---权限的下拉数据
    getPowerList(param, callbak) {
        return http.post(`${baseUrl}/bpo/menuPermisson/treeForAccountDefault`, param)
    },

}


