/*
 * @Author: 陈金金
 * @Date: 2020-07-14 18:32:55
 * @LastEditTime: 2020-08-14 11:49:01
 * @LastEditors: 陈金金
 * @Description: 新建项目
 * @FilePath: \BPO\src\pages\workbench\index.jsx
 */
import React, { Component } from "react";
import { Select, Input, Form, TreeSelect, message } from "antd";
import "./CreateProject.less";
import AlertModal from "../../../components/AlertModal";

import { isArray } from "../../../utils/common";
import { _isEmoji } from "../../../utils/validate.js";
const { Option } = Select;
const { TreeNode } = TreeSelect;
class CreateProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentData: [],
      groupList: [], //组别列表
    };
  }
  handleOk = (e, cb) => {
    const param = {
      ...e,
      organizationId: e.organizationId.value.split("-")[0],
      belongRelation: e.organizationId.value.split("-")[1],
    };
    this.$http.addProjectInfo({ ...param }).then((res) => {
      cb && cb();
      if (res && res.errorCode == 200) {
        message.destroy();
        message.success("保存成功");
        this.props.closeModal(true);
      }
    });
  };
  closeModal = () => {
    this.props.closeModal();
  };
  componentDidMount() {
    // this.getOrgList();
    this.getSelectList();
  }
  /**
   * @函数描述: 查询部门数据
   * @return:
   */
  getOrgList = () => {
    this.$http.queryOrgTree().then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          departmentData: res.data || [],
        });
      } else {
        this.setState({
          departmentData: [],
        });
      }
    });
  };
  /**
   * @函数描述: 渲染部门treeSelect
   * @param {type}
   * @return:
   */
  renderTreeNodes = (data) => {
    console.log(data);
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            title={item.organizationName}
            key={item.organizationCode}
            // dataRef={item}
            value={item.organizationCode}
            // disabled={item.organizationLevel * 1 < 3}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      } else {
        return (
          <TreeNode
            key={item.organizationCode}
            // {...item}
            title={item.organizationName}
            value={item.organizationCode}
            // disabled={item.organizationLevel * 1 < 3}
          />
        );
      }
    });
  };

  /**
   * @description: 获取组别
   * @param {type}
   * @return:
   * @author: 陈金金
   */
  getSelectList = () => {
    this.$http.GroupInfoList({ page: 1, pageSize: 999 }).then((res) => {
      console.log("GroupInfoList --->", res);
      if (res && res.errorCode == 200) {
        this.setState({
          groupList: res.data || [],
        });
      }
    });
  };

  render() {
    const { departmentData, groupList } = this.state;
    const { visible } = this.props;
    const FormData = [
      {
        id: 1,
        type: "input",
        field: "projectName",
        maxLength: 30,
        validatorName: _isEmoji,
        label: "项目名称",
        message: "长度为30，可输入中文、数字、英文、特殊符号",
        required: true,
      },
      {
        id: 2,
        name: "projectType",
        field: "projectType",
        label: "项目类型",
        type: "statusSelect",
        initialValue: "MAN_MONTH",
        required: true,
        dicGroup: "PROJECT_TYPE",
      },
      {
        id: 3,
        name: "businessType",
        field: "businessType",
        label: "业务类型",
        type: "statusSelect",
        required: true,
        dicGroup: "BUSINESS_TYPE",
      },
      {
        id: 4,
        name: "organizationId",
        type: "DepartmentSelect",
        field: "organizationId",
        labelInValue: true,
        belongRelation: true,
        label: "所属组织",
        // item:()=>
        // <TreeSelect
        //             style={{ width: '100%' }}
        //             dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        //             placeholder={'请选择'}
        //             treeDefaultExpandAll
        //             labelInValue={false}
        //             // onChange={this.handleChange}
        //             allowClear
        //         >
        //       {this.renderTreeNodes(departmentData)}
        //   </TreeSelect>,
        rules: [{ required: true, message: "请选择所属组织" }],
      },
      {
        id: 5,
        field: "groupId",
        label: "组别",
        type: "select",
        required: true,
        dataSource: groupList,
      },
    ];
    return (
      <div className="CreateProjectBox">
        <AlertModal
          title={"新建项目"}
          width={480}
          FormData={FormData}
          OkText={"确定"}
          CancelText={"取消"}
          modalData={null}
          visible={visible}
          onOk={(e, cb) => this.handleOk(e, cb)}
          onCancel={() => this.closeModal()}
        />
      </div>
    );
  }
}

export default Form.create()(CreateProject);
