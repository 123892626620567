/*
 * @Description: 项目毛利率统计
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-17 10:24:24
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-08-19 16:24:22
 */

import React, { Component } from "react";
import { Button } from "antd";
import moment from "moment";

import "../StatisticalReport.less";
import Search from "../../../components/searchForm";
import Table from "../../../components/table/index";
import ExportBtn from "../../../components/exportBtn"; // 导出
import { searchFormDataApply, columns } from "./data";
class ProjectIncomeRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      loading: false,
      visible: false,
      page: 1,
      pageSize: 10,
      searchData: searchFormDataApply,
      dataSource: [],
      type: true,
      searchValue: {},
    };
  }
  componentDidMount() {
    this.queryTableList();
    this.queryDeptAndSub();
  }
  /**
   * @description: 列表数据请求
   * @author: 陈金金
   */
  queryTableList = (
    page = this.state.page,
    pageSize = this.state.pageSize,
    type = this.state.type
  ) => {
    console.log("page --->", page); //2
    console.log("state = page -->", this.state.page); //1
    const { searchValue } = this.state;
    if (type) {
      const myDate = new Date();
      const tYear = myDate.getFullYear();
      const tMonth = myDate.getMonth() + 1;
      searchValue.month = tYear + "-" + tMonth;
    }
    searchValue.month =
      searchValue && searchValue.month
        ? moment(searchValue.month).format("YYYY-MM")
        : null;
    const query = {
      page,
      pageSize,
      ...searchValue,
    };
    console.log(page);
    this.setState({ loading: true });
    this.$http.pageGrossProfitMarginStatisticsByCondition(query).then((res) => {
      this.setState({ loading: false });
      if (res && res.errorCode == 200) {
        this.setState({
          dataSource: res.data.rows,
          total: res.data.total,
          page,
          pageSize,
        });
      }
    });
  };

  /**
   * @description: 部门数据请求
   * @author: 赵娟
   */

  queryDeptAndSub = async () => {
    const res = await this.$http.getDeptAndSub();
    if (res && res.errorCode == 200) {
      searchFormDataApply[2].selectData = res.data;
      this.setState({
        searchData: searchFormDataApply,
      });
    }
  };

  /**
   * @description: 搜索
   * @param {type}
   * @return {type}
   * @author: 陈金金
   */
  querySearch = (v) => {
    if (v && v.month) v.month = v.month.format("YYYY-MM");
    this.setState(
      {
        searchValue: v ? v : {},
        dataSource: [],
        page: 1,
        type: v ? false : true,
      },
      () => {
        this.queryTableList();
      }
    );
  };
  render() {
    const {
      total,
      page,
      pageSize,
      searchData,
      loading,
      dataSource,
      searchValue,
    } = this.state;
    return (
      <div className="ProjectIncomeRate StatisticalReport">
        <div className="account">
          <div className="searchbox">
            <Search
              tabList={[{ value: "项目毛利率统计" }]}
              FormData={searchData}
              handleSubmit={(values) => {
                this.querySearch(values);
              }}
            />
          </div>

          <div className="operation-table">
            <div className="Space8">
              {this.isArray(dataSource) && (
                <ExportBtn
                  text={"导出列表"}
                  params={{ ...searchValue }}
                  url={this.$http.exportProject()}
                />
              )}
            </div>
            <Table
              keyName="grossProfitMarginStatisticsId"
              columns={columns}
              dataSource={dataSource}
              scroll={{ x: "100%" }}
              loading={loading}
              total={total}
              current={page}
              pageSize={pageSize}
              isPagination={true}
              handleChangePage={this.queryTableList}
              handleChangePageSize={this.queryTableList}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectIncomeRate;
