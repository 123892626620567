/*
 * @Description: 项目毛利率统计数据
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-17 10:40:26
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-17 13:56:36
 */ 

import React from 'react';
import TooltipComponent from '../../../components/TooltipComponent'; // 文本溢出提示
import {baseInput7} from '../../../utils/validate';
import {isPositiveIncome,accMul} from '../../../utils/common'; 
import moment from 'moment';
/**
 * @description: 查询条件
 * @author: 陈金金
 */
const myDate = new Date();
const tYear = myDate.getFullYear();
const tMonth = myDate.getMonth() + 1;
export const searchFormDataApply = [ {
    label: '统计时间',
    name: 'month',
    type: 'MonthPicker',
    initialValue: moment(tYear + '-' + tMonth, 'YYYY-MM')
}, {
    label: '项目名称',
    name: 'projectName',
    type: 'input',
    validatorName:baseInput7,
    maxLength:100,
}, {
    label: '部门',
    name: 'deptIds',
    type: 'treeSelect',
    selectData: [],
    placeholder: "全部"
}]

/**
 * @description: 表头信息
 * @author: 陈金金
 */
export const columns = [ {
    title: '序号',
    width:60,
    fixed:"left",
    render:(text,record,index)=> `${index+1}`,
  }, {
    title: '项目名称',
    dataIndex: 'projectName',
    key: 'projectName',
    fixed:"left",
    width:170,
    render:t=>t?<TooltipComponent width={140}  text={t}/>:'-'
}, {
    title: '部门',
    dataIndex: 'deptName',
    key: 'deptName',
    fixed:"left",
    width:170,
    render:t=>t?<TooltipComponent width={140}  text={t}/>:'-'
},
{
    title: '相对收入',
    key: 'purchaseTotal',
    dataIndex: 'purchaseTotal',
    width:150,
    render:t=>t?<TooltipComponent width={140}  text={isPositiveIncome(t)}/>:'-',
}, {
    title: '税后金额',
    key: 'afterTax',
    dataIndex: 'afterTax',
    width:120,
    render:t=>t?<TooltipComponent width={120}  text={isPositiveIncome(t)}/>:'-',
}, {
    title: '当月薪资',
    key: 'salary',
    dataIndex: 'salary',
    width:130,
    render:t=>t?<TooltipComponent width={120}  text={isPositiveIncome(t)}/>:'-',
}, {
    title: '社保公积金成本',
    key: 'socialSecurityFund',
    dataIndex: 'socialSecurityFund',
    width:160,
    render:t=>t?<TooltipComponent width={150}  text={isPositiveIncome(t)}/>:'-',
},
{
    title: '年终奖分摊',
    key: 'yearEndBonusAverage',
    dataIndex: 'yearEndBonusAverage',
    width:160,
    render:t=>t?<TooltipComponent width={150}  text={isPositiveIncome(t)}/>:'-',
},
{
    title: '工位费',
    key: 'officeCubicleMoney',
    dataIndex: 'officeCubicleMoney',
    width:140,
    render:t=>t?<TooltipComponent width={130}  text={isPositiveIncome(t)}/>:'-',
},
{
    title: '软件分摊',
    key: 'softwareMoney',
    dataIndex: 'softwareMoney',
    width:140,
    render:t=>t?<TooltipComponent width={130}  text={isPositiveIncome(t)}/>:'-',
},{
    title: '资产损耗',
    key: 'assetLoss',
    dataIndex: 'assetLoss',
    width:140,
    render:t=>t?<TooltipComponent width={130}  text={isPositiveIncome(t)}/>:'-',
},{
    title: '费用合计',
    key: 'addUpTo',
    dataIndex: 'addUpTo',
    width:140,
    render:t=>t?<TooltipComponent width={130}  text={isPositiveIncome(t)}/>:'-',
},{
    title: '毛利',
    key: 'grossMargin',
    dataIndex: 'grossMargin',
    width:140,
    render:t=>t?<TooltipComponent width={130}  text={isPositiveIncome(t)}/>:'-',
},{
    title: '毛利率',
    key: 'grossProfitRate',
    dataIndex: 'grossProfitRate',
    width:140,
    render:t=>t?<TooltipComponent width={130}  text={accMul(t,1)+'%'}/>:'-',
}]
