/*
 * @文档描述: 账号管理 ----修改
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2021-02-03 17:54:02
 * @最后修改人: 文倩
 */
import React, { Component, Fragment } from 'react';
import { Modal, Form, message, } from 'antd';

import BaseInput from '../../../../components/baseInput/index';

import RoleSelect from '../../component/roleSelect/index';
import { baseInput7 } from '../../../../utils/validate';

import './index.less';

class updateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false // 编辑弹框显隐
        };
    }
    /**
     * @函数描述: 点击显示编辑弹框
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return:
     */
    handlePassBtnShow = () => {
        this.setState({
            visible: true
        });
    };
    /**
     * @函数描述: 取消弹框显示
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦
     */
    handlePassBtnCancle = () => {
        this.setState(
            {
                visible: false
            },
            () => {
                this.props.form.resetFields();
            }
        );
    };
    /**
     * @函数描述: 确定编辑成功
     * @param {String} passMessage  审批意见
     * @param {String} id
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦，重新表格当前页数据
     */
    handleAddSure = e => {
        e.preventDefault();
        const { validateFieldsAndScroll } = this.props.form;
        const { formData } = this.props;

        validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                const query = {
                    staffId: formData.staffId,
                    ...values
                }
                const res = await this.$http.updateAccount(query);
                if (res && res.errorCode == 200) {
                    this.handlePassBtnCancle();
                    this.props.handleSubmit();
                    message.destroy();
                    message.success(res.message);
                } else if (res && res.errorCode == 502) {
                    this.$utils.clear();
                    this.$utils.clearUserInfo();
                    this.props.history.push('/');
                }
            }
        });
    };

    /**
     * @函数描述: 处理角色回显
     */
    checkRoleData = (list) => {
        let arr = [];
        if (this.$common.isArray(list)) {
            list.map(item => {
                if(item.roleId != '1' && item.roleId != '2' && item.roleId != '3'){
                    arr.push(item.roleId);
                }
            });
        }
        return arr;
    }

    render() {
        const { form, formData } = this.props;
        const { visible } = this.state;
        return (
            <Fragment key="passBtn">
                <span
                    onClick={this.handlePassBtnShow}
                >
                    修改
                </span>
                <Modal
                    className="reviseModal"
                    title='修改'
                    visible={visible}
                    width={518}
                    destroyOnClose={true}
                    onCancel={this.handlePassBtnCancle}
                    onOk={this.handleAddSure}
                    maskClosable={false}
                    okText="提交"
                >
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }}>
                        {/* <BaseInput
                            form={form}
                            label="账号名称"
                            field='staffName'
                            required={true}
                            message="长度100个字，可输入中文、数字、英文、常用字符"
                            initialValue={formData && formData.staffName}
                            placeholder='请输入'
                            validatorName={baseInput7}
                            maxLength={100}
                        />

                        <BaseInput
                            form={form}
                            label="域账号"
                            field='sloginName'
                            required={true}
                            message="长度100个字，可输入中文、数字、英文、常用字符"
                            initialValue={formData && formData.sloginName}
                            placeholder='请输入'
                            validatorName={baseInput7}
                            maxLength={100}
                        /> */}

                        <RoleSelect
                            label='角色'
                            form={form}
                            field="roleIds"
                            initialValue={formData && this.checkRoleData(formData.roleVOS)}
                            required={true}
                            placeholder="请选择"
                            mode="multiple" />
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}
export default Form.create()(updateAccount);
