import React from "react";
import InputNumber from "../../../../../components/baseInput/number";
import { baseInput4 } from "../../../../../utils/validate.js";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import "./Components.less";
class ChangeKeyValue extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      keyLabel,
      previousValues,
      form,
      keyFieldName,
      valueFieldName,
      ...rest
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Row gutter={20} style={{ marginBottom: "10px" }}>
        <Col span={12}>
          <div className="flexstart detailbox">
            <div className="label">{keyLabel}：</div>
            <div className="value">¥{previousValues}</div>
            {getFieldDecorator(keyFieldName, {
              initialValue: previousValues,
            })}
          </div>
        </Col>
        <Col span={12}>
          <div className="flexend detailbox">
            <div className="label">调整后：</div>
            <InputNumber
              form={form}
              allowClear
              placeholder="请输入"
              validatorName={baseInput4}
              field={valueFieldName}
              {...rest}
              maxLength={10}
              min={0}
              step={0.01}
              max={9999999.99}
              style={{ width: 130 }}
              message="数字"
            />
          </div>
        </Col>
      </Row>
    );
  }
}

ChangeKeyValue.defaultProps = {
  keyFieldName: "Key",
  valueFieldName: "Value",
  previousValues: "0",
};

ChangeKeyValue.propTypes = {
  /**form实例 */
  form: PropTypes.object.isRequired,
  /**key的label */
  keyLabel: PropTypes.string,
  /**之前的值 */
  previousValues: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**key  Field*/
  keyFieldName: PropTypes.string.isRequired,
  /**value  Field*/
  valueFieldName: PropTypes.string.isRequired,
};

export default ChangeKeyValue;
