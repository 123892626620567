/*
 * @Author: xiajikun 
 * @Date: 2020-04-24 12:20:30 
 * @Last Modified by: 
 * @Last Modified time: 2020-06-03 15:20:04
 */
import React from 'react'
import { Input, Form } from 'antd';
import { isIdentityInput } from '../../utils/validate'; // 引用校验规则

class identityInput extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {}
    /**
     * @value 字段值
     *
     * @memberof identityInput
     */
    validator = (rules, value, callback) => {
        const { label } = this.props;
        if (value && value.trim()) {
            if (isIdentityInput(value.trim())) {
                callback()
            } else {
                callback(`请输入正确的${label}`)
            }
        } else {
            callback()
        }
    }
   /**
    * 身份证组件
     * @props的属性
     * label 标题
     * required 是否必填
     * field 字段名
     * trigger 校验的时机
     */
    render() {
        const { getFieldDecorator } = this.props.form;
        const { label, required, field, trigger, initialValue } = this.props;
        return (
            <Form.Item label={label}>
                {getFieldDecorator(field, {
                    initialValue: initialValue || null,
                    validate: [{
                        trigger: trigger || 'onChange',
                        rules: [{
                            required,
                            message: `请输入${label}`
                        }, {
                            validator: this.validator
                        }]
                    }]

                })(
                    <Input {...this.props} />
                )}
            </Form.Item>
        )
    }
}

export default identityInput