/*
 * @Description: 入职离职表
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-17 10:40:26
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-27 10:45:34
 */

import React from 'react';
import TooltipComponent from '../../../components/TooltipComponent'; // 文本溢出提示
import { baseInput7, baseInput1 } from '../../../utils/validate';

/**
 * @description: 搜索配置项
 * @param {type} 
 * @return {type} 
 * @author: 文倩
 */


export const searchFormDataApply = [{
    label: '统计时间',
    name: 'month',
    type: 'rangeTime',
    placeholder: ["开始时间", "结束时间"]
}, {
    label: '统计类型',
    name: 'timeType',
    type: 'select',
    placeholder: '全部',
    // isEntry:true,
    selectData: [
        {
            name: "全部",
            value: null
        }, {
            name: "入职时间",
            value: '1'
        }, {
            name: "离职时间",
            value: '2'
        }
    ]
},
{
    label: '工号',
    name: 'staffCode',
    type: 'input',
    validatorName: baseInput1,
    placeholder: "请输入员工工号",
    maxLength: 18
}, {
    label: '姓名',
    name: 'staffName',
    type: 'input',
    validatorName: baseInput7,
    placeholder: "请输入员工姓名",
    maxLength: 18
}]


/**
 * @description: 查询列表配置项
 * @param {type} 
 * @return {type} 
 * @author: 文倩
 */
export const columns = [{
    title: '序号',
    width: 60,
    fixed: "left",
    render: (text, record, index) => `${index + 1}`,
}, {
    title: '工号',
    dataIndex: 'staffCode',
    key: 'staffCode',
    width: 160,
    fixed: "left",
    render: t => t ? <TooltipComponent width={140} text={t} /> : '-'
}, {
    title: '姓名',
    key: 'staffName',
    dataIndex: 'staffName',
    width: 120,
    render: t => t ? <TooltipComponent width={100} text={t} /> : '-',
}, {
    title: '入职时间',
    key: 'onBoardingTime',
    dataIndex: 'onBoardingTime',
    width: 120,
    render: t => t ? <TooltipComponent width={120} text={t} /> : '-',
}, {
    title: '离职时间',
    key: 'resigningTime',
    dataIndex: 'resigningTime',
    width: 130,
    render: t => t ? <TooltipComponent width={120} text={t} /> : '-',
}, {
    title: '项目名称',
    key: 'projectName',
    dataIndex: 'projectName',
    width: 150,
    render: t => t ? <TooltipComponent width={140} text={t} /> : '-',
}, {
    title: '组别',
    key: 'groupName',
    dataIndex: 'groupName',
    width: 150,
    render: t => t ? <TooltipComponent width={140} text={t} /> : '-',
}, {
    title: '离职原因',
    key: 'remarks',
    dataIndex: 'remarks',
    width: 480,
    render: t => t ? <TooltipComponent width={460} text={t} /> : '-',
}]
