/**
 * @description 模板下载
 * @author zxz
 * @version v1.2
 */
import React from "react";
import { Button, message } from "antd";
import PropTypes from "prop-types";

const baseUrl = process.env.API_URL ? process.env.API_URL : "";

class TemplateDownload extends React.Component {
  state = {
    isImportLoading: false,
  };
  /**
   * @method 模板下载文件
   */
  jumpTo = async () => {
    this.setState({
      isImportLoading: true,
    });
    const res = await this.$http.DownloadNewPersonnelTemplate({});
    console.log("res --- >", res);
    if (res && res.request && res.request.responseType == "blob") {
      var reader = new FileReader();
      reader.onload = () => {
        try {
          console.log("try");
          var content = JSON.parse(reader.result);
          console.log("content -->", content);
          if (content && content.code != 200) {
            message.warning(content.message);
          }
        } catch (error) {
          console.log("catch --- > ", error);
          this.$common.getExcel(res);
        }
      };
      reader.readAsText(res.data);
    }
    if (res) {
      this.setState({
        isImportLoading: false,
      });
    }
  };

  render() {
    return (
      <Button
        type="primary"
        loading={this.state.isImportLoading}
        onClick={() => this.jumpTo()}
      >
        模板下载
      </Button>
    );
  }
}

TemplateDownload.defaultProps = {};

TemplateDownload.propTypes = {
  templateHref: PropTypes.string.isRequired,
};

export default TemplateDownload;
