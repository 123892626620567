/*
 * @Description: 信息维护字段配置
 * @Author: 文倩
 * @Date: 2020-06-02 11:39:06
 * @LastEditTime: 2020-08-25 17:24:43
 * @LastEditor: 文倩
 */
import React from 'react'
import TooltipComponent from '../../components/TooltipComponent';
import { accMul,isPositiveIncome } from '../../utils/common';
import { baseInput7, baseInput1, baseInput4 } from '../../utils/validate';


//流程配置配置项
export const processColumns = [
    {
        name: "结项申请",
        key: "POST_PROJECT",
        columns: [
            {
                title: '发起流程',
                dataIndex: 'groupName1',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={400} placement="topLeft" text={text} /> : '-'

            },
            {
                title: '一级审批人',
                dataIndex: 'groupName2',
                width: 400,
                render: text => text != 'undefined' ? <TooltipComponent width={500} placement="topLeft" text={text} /> : '-'
            }
        ]
    },
    {
        name: "薪资结构调整-付费类型",
        key: "SALARY_ADJUST_PAY_TYPE",
        columns: [
            {
                title: '发起流程',
                dataIndex: 'groupName1',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={400} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '一级审批人',
                dataIndex: 'groupName2',
                width: 400,
                render: text => text != 'undefined' ? <TooltipComponent width={500} placement="topLeft" text={text} /> : '-'
            }
        ]
    },
    {
        name: "薪资结构调整-薪资调整+",
        key: "SALARY_ADJUST_SUM",
        columns: [
            {
                title: '发起流程',
                dataIndex: 'groupName1',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={400} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '一级审批人',
                dataIndex: 'groupName2',
                width: 400,
                render: text => text != 'undefined' ? <TooltipComponent width={500} placement="topLeft" text={text} /> : '-'
            }
        ]
    },
    {
        name: "薪资结构调整-非系统工资变动",
        key: "PERFORMANCE_STANDARD",
        columns: [
            {
                title: '发起流程',
                dataIndex: 'groupName1',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={400} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '一级审批人',
                dataIndex: 'groupName2',
                width: 400,
                render: text => text != 'undefined' ? <TooltipComponent width={500} placement="topLeft" text={text} /> : '-'
            }
        ]
    },
    {
        name: "薪资结构调整-薪资调整-",
        key: "SALARY_ADJUST_SUB",
        columns: [
            {
                title: '发起流程',
                dataIndex: 'groupName1',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={400} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '一级审批人',
                dataIndex: 'groupName2',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={500} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '二级审批人',
                dataIndex: 'groupName3',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={500} placement="topLeft" text={text} /> : '-'
            }
        ]
    },
    {
        name: "人员基础信息调整-身份变更",
        key: "IDENTITY_ADJUST",
        columns: [
            {
                title: '发起流程',
                dataIndex: 'groupName1',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={400} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '一级审批人',
                dataIndex: 'groupName2',
                width: 400,
                render: text => text != 'undefined' ? <TooltipComponent width={500} placement="topLeft" text={text} /> : '-'
            }
        ]
    },
    {
        name: "人员基础信息调整-人员层级、场地变动",
        key: "OTHER_ADJUST",
        columns: [
            {
                title: '发起流程',
                dataIndex: 'groupName1',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={400} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '一级审批人',
                dataIndex: 'groupName2',
                width: 400,
                render: text => text != 'undefined' ? <TooltipComponent width={500} placement="topLeft" text={text} /> : '-'
            }
        ]
    },
    {
        name: "薪资超上限通知-知会BPO",
        key: "EMOLUMENT_INFORM",
        columns: [
            {
                title: '发起流程',
                dataIndex: 'groupName1',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={400} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '一级审批人',
                dataIndex: 'groupName2',
                width: 400,
                render: text => text != 'undefined' ? <TooltipComponent width={500} placement="topLeft" text={text} /> : '-'
            }
        ]
    },
    {
        name: "项目调整（A组→B组）",
        key: "PROJECT_ADJUST",
        columns: [
            {
                title: '发起流程',
                dataIndex: 'groupName1',
                width: 200,
                render: text => text != 'undefined' ? <TooltipComponent width={400} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '一级审批人',
                dataIndex: 'groupName2',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={400} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '二级审批人',
                dataIndex: 'groupName3',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={400} placement="topLeft" text={text} /> : '-'
            }
        ]
    },
    {
        name: "人员调整（A组→A组）",
        key: "STAFF_PROJECT_ADJUST_AA",
        columns: [
            {
                title: '发起流程',
                dataIndex: 'groupName1',
                width: 300,
                render: text => text != 'undefined' ? <TooltipComponent width={400} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '一级审批人',
                dataIndex: 'groupName2',
                width: 400,
                render: text => text != 'undefined' ? <TooltipComponent width={500} placement="topLeft" text={text} /> : '-'
            }
        ]
    },
    {
        name: "人员调整（A组→B组）",
        key: "STAFF_PROJECT_ADJUST_AB",
        columns: [
            {
                title: '发起流程',
                dataIndex: 'groupName1',
                width: 200,
                render: text => text != 'undefined' ? <TooltipComponent width={200} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '一级审批人',
                dataIndex: 'groupName2',
                width: 200,
                render: text => text != 'undefined' ? <TooltipComponent width={300} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '二级审批人',
                dataIndex: 'groupName3',
                width: 200,
                render: text => text != 'undefined' ? <TooltipComponent width={300} placement="topLeft" text={text} /> : '-'
            },
            {
                title: '三级审批人',
                dataIndex: 'groupName4',
                width: 200,
                render: text => text != 'undefined' ? <TooltipComponent width={300} placement="topLeft" text={text} /> : '-'
            }
        ]
    }
]

//流程配置表单字段配置
export const processForm = {
    "POST_PROJECT": [{
        key: 'firstLevel',
        name: '一级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择一级审批人'
        }]
    }],                                     //结项申请
    "SALARY_ADJUST_PAY_TYPE": [{
        key: 'firstLevel',
        name: '一级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择一级审批人'
        }]
    }],                                     //薪资结构调整-付费类型
    "SALARY_ADJUST_SUM": [{
        key: 'firstLevel',
        name: '一级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择一级审批人'
        }]
    }],                                     //薪资结构调整-薪资调整+
    "PERFORMANCE_STANDARD": [{
        key: 'firstLevel',
        name: '一级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择一级审批人'
        }]
    }],   //薪资结构调整-绩效基准值变动
    "SALARY_ADJUST_SUB": [{
        key: 'firstLevel',
        name: '一级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择一级审批人'
        }]
    }, {
        key: 'secondLevel',
        name: '二级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择二级审批人'
        }]
    }],         //薪资结构调整-薪资调整-
    "IDENTITY_ADJUST": [{
        key: 'firstLevel',
        name: '一级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择一级审批人'
        }]
    }],           //人员基础信息调整-身份变更
    "OTHER_ADJUST": [{
        key: 'firstLevel',
        name: '一级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择一级审批人'
        }]
    }],              //人员基础信息调整-人员层级、场地变动
    "EMOLUMENT_INFORM": [{
        key: 'firstLevel',
        name: '一级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择一级审批人'
        }]
    }],          //薪资超上限通知-知会BPO
    "PROJECT_ADJUST": [{
        key: 'firstLevel',
        name: '一级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择一级审批人'
        }]
    }, {
        key: 'secondLevel',
        name: '二级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择二级审批人'
        }]
    }],            //项目调整（A组→B组）
    "STAFF_PROJECT_ADJUST_AA": [{
        key: 'firstLevel',
        name: '一级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择一级审批人'
        }]
    }],   //人员调整（A组→A组）
    "STAFF_PROJECT_ADJUST_AB": [{
        key: 'firstLevel',
        name: '一级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择一级审批人'
        }]
    }, {
        key: 'secondLevel',
        name: '二级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择二级审批人'
        }]
    }, {
        key: 'threeLevel',
        name: '三级审批人',
        type: 'Select',
        placeholder: '请选择',
        mapping: {
            name: 'roleName',
            value: 'roleId'
        },
        selectData: [],
        rules: [{
            required: true,
            message: '请选择三级审批人'
        }]
    }],     //人员调整（A组→B组）
}



//花名册tab配置项
export const rosterTab = [
    {
        value: "项目类人员信息",
        key: "1"
    }, {
        value: "中后台人员信息",
        key: "2"
    }, {
        value: "招聘类人员信息",
        key: "3"
    }
]

// 花名册信息维护列表配置信息
export const rosterColumns = [
    {
        title: '序号',
        dataIndex: 'num',
        width: 50,
        render: (text, record, index) => index > -1 ? index + 1 : '-'
    }, {
        title: '导入文件名',
        dataIndex: 'fileName',
        width: 200,
        render: text => text ? <TooltipComponent width={200} placement="topLeft" text={text} /> : '-'
    }, {
        title: '导入时间',
        dataIndex: 'gmtCreated',
        width: 200,
        render: text => text ? <TooltipComponent width={200} placement="topLeft" text={text} /> : '-'
    }, {
        title: '成功条数',
        dataIndex: 'successNum',
        width: 100,
        render: text => text ? <TooltipComponent width={100} placement="topLeft" text={text} /> : '-'
    }, {
        title: '失败条数',
        dataIndex: 'failedNum',
        width: 100,
        render: text => text ? <TooltipComponent width={100} placement="topLeft" text={text} /> : '-'
    }, {
        title: '总条数',
        dataIndex: 'sumNum',
        width: 100,
        render: text => text ? <TooltipComponent width={100} placement="topLeft" text={text} /> : '-'
    }
]


// 基础信息维护tab配置项
export const basicTab = [
    {
        value: "固定成本",
        key: "0"
    }, {
        value: "社保公积金成本",
        key: "1"
    }, {
        value: "层级薪资",
        key: "2"
    }, {
        value: "组别",
        key: "3"
    }, /* {
        value: "WBS",
        key: "4"
    }, */ {
        value: "城市",
        key: "5"
    }, {
        value: "项目",
        key: "6"
    }
]



//基础信息维护-固定成本列配置项
export const fixedCostColums = [
    {
        dataIndex: 'fixedCostName',
        className: "firstColumns",
        width: 100,
        render: text => text ? <TooltipComponent width={100} placement="topLeft" text={text} /> : '-'
    }, {
        dataIndex: 'fixedCostValue',
        className: "secondColumns",
        width: 200,
        editable: true,
        required: true,
        message: "长度10，包括小数点和两位小数",
        validatorName: baseInput4,
        type: "NumberInput",
        min: 0,
        max: 9999999.99,
        step: 0.01,
        render: (text, record) => text ? <TooltipComponent width={200} placement="topLeft"
            text={record.fixedCostName == '税率' ? accMul(text,100) + '%' : '￥' + text} /> : '-'
    }
]



//基础信息维护-社保公积金成本列配置项
export const socialInsuranceColums = [
    {
        title: "城市名称",
        dataIndex: 'cityName',
        className: "columns",
        width: 200,
        // editable: true,
        required: true,
        type: "Select",
        key: "cityId",
        render: text => text ? <TooltipComponent width={200} placement="topLeft" text={text} /> : '-'
    }, {
        title: "社保公积金成本",
        dataIndex: 'socialSecurityFundCost',
        className: "secondColumns",
        width: 200,
        editable: true,
        required: true,
        message: "长度10，包括小数点和两位小数",
        validatorName: baseInput4,
        type: "NumberInput",
        min: 0,
        max: 9999999.99,
        step: 0.01,
        render: text => text ? <TooltipComponent width={200} placement="topLeft" text={isPositiveIncome(text)} /> : '-'
    }
]



//基础信息维护-层级薪资列表配置项
export const salaryColumns = [
    {
        title: '城市名称',
        dataIndex: 'cityName',
        width: 150,
        render: text => text ? <TooltipComponent width={150} placement="topLeft" text={text} /> : '-'
    }, {
        title: '服务性质',
        dataIndex: 'serveNatureValue',
        width: 150,
        render: text => text ? <TooltipComponent width={150} placement="topLeft" text={text} /> : '-'
    }, {
        title: '层级',
        dataIndex: 'rank',
        width: 150,
        render: text => text ? <TooltipComponent width={150} placement="topLeft" text={text} /> : '-'
    }, {
        title: '采购价',
        dataIndex: 'purchasePrice',
        width: 150,
        render: text => text ? <TooltipComponent width={150} placement="topLeft" text={isPositiveIncome(text)} /> : '-'
    }, {
        title: '薪资上限',
        dataIndex: 'purchaseSalaryCeil',
        width: 150,
        render: text => text ? <TooltipComponent width={150} placement="topLeft" text={isPositiveIncome(text)} /> : '-'
    }
]


//基础信息维护-组别列表配置项
export const groupColums = [
    {
        title: "组别",
        dataIndex: 'groupName',
       // className: "columns",
        width: 150,
       // editable: true,
       // required: true,
       // message: "长度为10，可输入中文、数字、英文、特殊符号",
       // maxLength: 10,
      //  validatorName: baseInput7,
       // style: { width: "350px" },
        render: text => text ? <TooltipComponent width={150} placement="topLeft" text={text} /> : '-'
    },{
        title: "WBS",
        dataIndex: 'wbsList',
       // className: "columns",
        width: 1200,
       // editable: true,
      //  required: true,
       // message: "长度为10，可输入中文、数字、英文、特殊符号",
      //  maxLength: 10,
       // validatorName: baseInput7,
      //  style: { width: "350px" },
        render: text => text ? <TooltipComponent width={1200} placement="topLeft" text={text.join(",")} /> : '-'
    }
]


//基础信息维护-WBS列表配置项
export const WbsColums = [
    {
        title: "WBS",
        dataIndex: 'wbsName',
        className: "columns",
        width: 600,
        editable: true,
        required: true,
        message: "长度为10，可输入中文、数字、英文、特殊符号",
        maxLength: 10,
        validatorName: baseInput7,
        style: { width: "350px" },
        render: text => text ? <TooltipComponent width={600} placement="topLeft" text={text} /> : '-'
    }
]


//基础信息维护-城市
export const CityColums = [
    {
        title: "城市",
        dataIndex: 'cityName',
        className: "columns",
        width: 600,
        editable: true,
        required: true,
        message: "长度为10，可输入中文、数字、英文、特殊符号",
        maxLength: 10,
        validatorName: baseInput7,
        style: { width: "350px" },
        render: text => text ? <TooltipComponent width={600} placement="topLeft" text={text} /> : '-'
    }
]

//基础信息维护-项目列表配置项
export const projectColumns = [
    {
        title: '项目名称',
        dataIndex: 'projectName',
        width: 300,
        render: text => text ? <TooltipComponent width={300} placement="topLeft" text={text} /> : '-'
    }, {
        title: '项目类型',
        dataIndex: 'projectTypeStr',
        width: 150,
        render: text => text ? <TooltipComponent width={150} placement="topLeft" text={text} /> : '-'
    }, {
        title: '业务类型',
        dataIndex: 'businessTypeStr',
        width: 150,
        render: text => text ? <TooltipComponent width={150} placement="topLeft" text={text} /> : '-'
    }, {
        title: '组别',
        dataIndex: 'groupName',
        width: 150,
        render: text => text ? <TooltipComponent width={150} placement="topLeft" text={text} /> : '-'
    }
]


//组织架构信息配置项
export const organCols = [
    {
        name: "部门名称",
        filed: "organizationName"
    }, {
        name: "部门编码",
        filed: "organizationCode"
    }, {
        name: "父级编码",
        filed: "parentOrgCode"
    }, {
        name: "部门head",
        filed: "administrators"
    }, {
        name: "部门交付",
        filed: "deliverys"
    }, {
        name: "部门BP",
        filed: "bps"
    }
]



//组织架构表单配置项
export const organForm = (formData) => [
    {
        key: 'organizationName',
        name: '组织名称',
        type: 'BaseInputCode',
        placeholder: '请输入组织名称',
        required: true,
        message: "长度为20，可输入中文、数字、英文、特殊符号",
        maxLength: 20,
        validatorName: baseInput7,
        initialValue: formData && formData.organizationName
    }, {
        key: 'organizationCode',
        name: '组织编码',
        type: 'input',
        placeholder: '请输入组织编码',
        required: true,
        disabled: true,
        validatorName: baseInput1,
        initialValue: formData && formData.organizationCode
    }, {
        key: 'parentOrgCode',
        name: '父级编码',
        type: 'input',
        placeholder: '请输入父级编码',
        required: true,
        disabled: true,
        validatorName: baseInput1,
        initialValue: formData && formData.parentOrgCode
    }, {
        key: 'administrator',
        name: '部门head',
        type: 'accountSelect',
        placeholder: '请选择部门head',
        mode: "multiple",
        required: true,
        message: '请选择部门head'
    }, {
        key: 'delivery',
        name: '部门交付',
        type: 'accountSelect',
        placeholder: '请选择部门交付',
        mode: "multiple",
        required: true,
        message: '请选择部门交付'
    }, {
        key: 'bp',
        name: '部门BP',
        type: 'accountSelect',
        mode: "multiple",
        placeholder: '请选择部门BP',
        required: true,
        message: '请选择部门BP'
    }
]

