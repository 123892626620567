/*
 * @Author: 陈金金
 * @Date: 2020-07-14 18:32:55
 * @LastEditTime: 2021-02-20 15:39:43
 * @LastEditors: 陈金金
 * @Description: 项目看板详情编辑
 * @FilePath: \BPO\src\pages\workbench\index.jsx
 */
import React, { Component } from "react";
import { Button, Row, Col, Input, Form } from "antd";
import "./Edit.less";
import Table from "../../../../components/table/index";
import ProjectInfo from "./Components/ProjectInfo"; //项目信息调整
import AddStaff from "./Components/AddStaff"; //新增人员
import ProjectTransfer from "./Components/ProjectTransfer"; // 项目转移
import StaffTransfer from "./Components/StaffTransfer"; //人员转项目
import StaffTurnover from "./Components/StaffTurnover"; //人员离职
import EndProject from "./Components/EndProject"; //结项申请
import ApprovedAmount from "./Components/ApprovedAmount"; //结项申请
import SalaryChange from "./Components/SalaryChange"; //薪资结构调整
import BasicInfoChange from "./Components/BasicInfoChange"; //人员基础信息调整
import TemplateDownload from "./Components/TemplateDownload"; // 模板下载
import FileImport from "./Components/FileImport"; // 文件导入
import TooltipComponent from "../../../../components/TooltipComponent";

import { getQueryString } from "../../../../utils/common";
import {
  detailKey,
  detailSearchFormDataApply,
  detailColumns,
} from "../../data";
class ProjectEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 3,
      loading: false,
      page: 1,
      pageSize: 10,
      isCard: false,
      searchData: detailSearchFormDataApply(),
      searchValue: {},
      detailData: {},
      selectedRowKeys: [],
      selectedRowList: [],
      projectId: null,
      StaffList: [],
    };
  }
  componentDidMount() {
    if (getQueryString("projectId")) {
      this.setState(
        {
          projectId: getQueryString("projectId"),
        },
        () => {
          this.updateAll();
        }
      );
    }
  }
  /**
   * @description: 更新列表和顶部详情数据
   * @param {type}
   * @return {type}
   * @author: 陈金金
   */
  updateAll = () => {
    this.getDetail(getQueryString("projectId"));
    this.getStaffList();
  };
  getDetail = (projectId) => {
    this.$http.getProjectInfoById({ projectId }).then((res) => {
      if (res && res.errorCode == 200) {
        console.log("getProjectInfoById --->", res);
        this.setState({
          detailData: res.data,
        });
      }
    });
  };
  /**
   * @description: 项目人员列表
   * @param {type}
   * @return:
   * @author: 陈金金
   */
  getStaffList = (page = this.state.page, pageSize = this.state.pageSize) => {
    const { projectId, searchValue, sortParam } = this.state;
    this.setState({ loading: true });
    this.$http
      .pageStaffProjectRefList({
        page,
        pageSize,
        projectId,
        ...searchValue,
        ...sortParam,
      })
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.errorCode == 200) {
          this.setState({
            StaffList: res.data.rows,
            total: res.data.total,
            page,
            pageSize,
          });
        }
      });
  };
  /**
   * @description: 项目详情
   * @return:
   */
  renderDetailKeyHTML = () => {
    const { detailData, projectId } = this.state;
    return detailKey.map((item) => {
      return (
        <Col span={item.key == "approvalCost" ? 12 : 6} key={item.key}>
          <div className="detailbox">
            <span className="label">{item.name}</span>
            <span className="value">
              {detailData[item.key] ? (
                <TooltipComponent
                  text={
                    item.before != ""
                      ? this.$common.isPositiveIncome(detailData[item.key]) +
                        item.after
                      : item.after != ""
                      ? this.$common.accMul(detailData[item.key], 1) + "%"
                      : detailData[item.key] + item.after
                  }
                  width={118}
                />
              ) : (
                "--"
              )}
            </span>
            {item.key == "approvalCost" && (
              <ApprovedAmount
                projectId={projectId}
                closeModal={() => this.getDetail(projectId)}
              />
            )}
          </div>
        </Col>
      );
    });
  };
  /**
   * @description: 人员搜索
   * @author: 陈金金
   */
  handleSearch = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState(
        {
          searchValue: values,
          StaffList: [],
          page: 1,
        },
        () => {
          this.getStaffList();
        }
      );
    });
  };
  onReset = () => {
    this.props.form.resetFields(); // 重置表单
    this.setState(
      {
        searchValue: {},
        StaffList: [],
        page: 1,
      },
      () => {
        this.getStaffList();
      }
    );
  };
  // 表格操作
  action = () => {
    return {
      title: "操作",
      width: 360,
      key: "operation",
      fixed: "right",
      render: (t, record, i) => {
        return (
          <span className="operation-wrap">
            {this.renderOptionBtn(record, i)}
          </span>
        );
      },
    };
  };
  /**
   * @description: 操作
   * @return:
   */
  /**
   * @description:
   * @param {Number} staffStatus 员工状态 1-正常 2-离职'
   * @return {type}
   * @author: 陈金金
   */
  renderOptionBtn = (r, i) => {
    const { projectId } = this.state;
    // 薪资结构跳转
    const xinzi = (
      <SalaryChange
        key={`xinzi${i}`}
        row={r}
        projectid={projectId}
        closemodal={this.updateAll}
      />
    );
    // 人员基础信息调整
    const database = (
      <BasicInfoChange
        key={`database${i}`}
        row={r}
        projectid={projectId}
        closemodal={this.updateAll}
      />
    );
    // 人员转项目
    const transfer = (
      <StaffTransfer
        key={`transfer${i}`}
        row={r}
        projectid={projectId}
        closemodal={this.updateAll}
      />
    );
    let optionBtn = [];

    if (r.staffStatus == 1) {
      optionBtn.push(xinzi, database, transfer);
    }

    return optionBtn;
  };
  /**
   * @description: 人员搜索dom
   * @author: 陈金金
   */
  renderSearchHtml = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="inline">
        <Row>
          <Col span={12}>
            <Form.Item label={"人员搜索"}>
              {getFieldDecorator("keyword")(
                <Input
                  style={{ width: "270px" }}
                  placeholder="请输入工号/姓名"
                />
              )}
            </Form.Item>
            <div className="Space8">
              <Button type="primary" onClick={this.handleSearch}>
                {" "}
                搜 索
              </Button>
              <Button onClick={this.onReset}>重 置</Button>
            </div>
          </Col>
          <Col span={12}></Col>
        </Row>
      </Form>
    );
  };
  onSelectChange = (selectedRowKeys, selectedRows) => {
    const { StaffList } = this.state;

    this.setState({ selectedRowKeys }, () => {
      const arr = [];
      StaffList.map((item) => {
        selectedRowKeys.map((v) => {
          if (v == item.staffId && item.staffStatus == 1) {
            arr.push(item);
          }
          return v;
        });
        return item;
      });
      this.setState({
        selectedRowList: arr,
      });
    });
  };
  /**
   * @description: 排序
   * @param {type}   1.ascend 正序 2.descend 倒序
   * @author: 陈金金
   */
  handleChange = (p, f, s) => {
    if (s && s.order && s.order == "ascend") s.order = "ASC";
    if (s && s.order && s.order == "descend") s.order = "DESC";
    const obj = {};
    obj[`${s.columnKey}`] = s.order;
    this.setState(
      {
        sortParam: obj,
      },
      () => {
        this.getStaffList();
      }
    );
  };
  render() {
    const {
      total,
      page,
      pageSize,
      selectedRowKeys,
      selectedRowList,
      loading,
      isCard,
      StaffList,
      projectId,
      detailData,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: (e) => this.onSelectChange(e),
      // getCheckboxProps: (r) => ({
      //     disabled:true
      // }),
    };
    console.log("detailData --->", detailData);
    return (
      <div className="ProjectEditBox">
        <div className="card">
          <div className="pjDetail">
            <div className="title">项目详情</div>
            <div className="btn Space8">
              <ProjectInfo
                key="ProjectInfo"
                projectId={projectId}
                closeModal={() => this.getDetail(projectId)}
              />
              <ProjectTransfer
                key="ProjectTransfer"
                projectId={projectId}
                closeModal={() => this.getDetail(projectId)}
              />
              <EndProject
                key="EndProject"
                dataList={selectedRowList}
                detailInfo={detailData}
                projectId={projectId}
                closeModal={() => this.getDetail(projectId)}
              />
            </div>
          </div>
          <Row gutter={20} justify="start">
            {this.renderDetailKeyHTML()}
          </Row>
        </div>
        <div className="card">
          <div className="searchdetail">{this.renderSearchHtml()}</div>
          <div className="table" style={{ paddingBottom: isCard ? 0 : "" }}>
            <div className="Space8">
              <AddStaff
                closeModal={() => this.updateAll()}
                projectId={projectId}
                detailInfo={detailData}
              />
              <TemplateDownload
                templateHref={"/bpo/staffRoster/downStaffRosterModel"}
              />
              <FileImport
                style={{ marginRight: "8px" }}
                projectId={projectId}
                onSuccess={() => this.updateAll()}
              />
              <StaffTurnover
                closeModal={() => {
                  this.updateAll();
                  // 此处确认提交后修改按钮为禁用
                  this.setState({
                    selectedRowList: [],
                  });
                }}
                projectId={projectId}
                dataList={selectedRowList}
              />
            </div>
            <Table
              keyName={"staffId"}
              rowSelection={rowSelection}
              columns={[...detailColumns, this.action()]}
              dataSource={StaffList}
              scroll={{ x: "100%" }}
              loading={loading}
              total={total}
              current={page}
              rowClassName={(r) => (r.staffStatus == 2 ? "hideselect" : "")}
              pageSize={pageSize}
              isPagination={true}
              onChange={(p, f, s, e) => this.handleChange(p, f, s, e)}
              handleChangePage={this.getStaffList}
              handleChangePageSize={this.getStaffList}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(ProjectEdit);
