/*
 * @Description: 部门毛利率统计
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-17 10:24:24
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-13 18:05:04
 */

import React, { Component } from "react";
import { Button, message, Card } from "antd";
import moment from "moment";

import Search from "../../../components/searchForm";
import Table from "../../../components/table/index";
import { isPositiveIncome } from "../../../utils/common";
import { searchFormDataApply, columns, statisticsText } from "./data";

import "../StatisticalReport.less";
class DepartmentIncomeRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      loading: false,
      isExportShow: false,
      pageNo: 1,
      pageSize: 10,
      searchData: searchFormDataApply,
      type: true,
      searchValue: {},
      dataSource: [],
      statisticsText: statisticsText,
      statisticsData: [],
    };
  }

  componentDidMount() {
    this.queryTableList();
    this.queryDeptAndSub();
  }

  /**
   * @description: 查询列表数据
   * @param {type}
   * @return {type}
   * @author: 文倩
   * @LastEditors: 赵娟
   * @description: 增加汇总统计statisticsData，对统计时间默认值以及重置初始化
   */
  queryTableList = (
    page = this.state.pageNo,
    pageSize = this.state.pageSize,
    type = this.state.type
  ) => {
    const { searchValue } = this.state;
    if (type) {
      const myDate = new Date();
      const tYear = myDate.getFullYear();
      const tMonth = myDate.getMonth() + 1;
      searchValue.month = tYear + "-" + tMonth;
    }
    searchValue.month =
      searchValue && searchValue.month
        ? moment(searchValue.month).format("YYYY-MM")
        : null;
    const query = {
      page,
      pageSize,
      ...searchValue,
    };
    this.setState({
      loading: true,
    });
    this.$http.getDeparmentList(query).then((res) => {
      if (res && res.errorCode == 200) {
        let statisticsData =
          res.data.rows.length !== 0 ? res.data.rows.pop() : [];
        this.setState({
          dataSource: res.data.rows,
          statisticsData: statisticsData,
          total: res.data.total,
          pageNo: page,
          pageSize,
        });
      }
      if (res) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  /**
   * @description: 部门数据请求
   * @author: 赵娟
   */

  queryDeptAndSub = async () => {
    const res = await this.$http.getDeptAndSub();
    if (res && res.errorCode == 200) {
      searchFormDataApply[1].selectData = res.data;
      this.setState({
        searchData: searchFormDataApply,
      });
    }
  };

  /**
   * @description: 查询搜索列表数据
   * @param {type}
   * @return {type}
   * @author: 文倩
   * @LastEditors: 赵娟
   * @description: 添加参数type，根据搜素对象返回搜素，重置功能
   */
  querySearch = (v) => {
    if (v && v.month) v.month = v.month.format("YYYY-MM");
    this.setState(
      {
        searchValue: v ? v : {},
        dataSource: [],
        pageNo: 1,
        type: v ? false : true,
      },
      () => {
        this.queryTableList();
      }
    );
  };

  /**
   * @description: 导出部门列表
   * @param {type}
   * @return {type}
   * @author: 文倩
   */
  exportExcel = () => {
    const { searchValue } = this.state;
    const query = {
      ...searchValue,
    };
    this.setState({
      isExportShow: true,
    });
    this.$http.exportDeparment(query).then((res) => {
      if (res && res.request && res.request.responseType == "blob") {
        var reader = new FileReader();
        reader.onload = () => {
          try {
            var content = JSON.parse(reader.result); //内容就在这里
            if (content && content.code != 200) {
              message.warning(content.message);
            }
          } catch {
            this.$common.getExcel(res);
          }
        };
        reader.readAsText(res.data);
      }
      if (res) {
        this.setState({
          isExportShow: false,
        });
      }
    });
  };

  render() {
    const {
      total,
      pageNo,
      pageSize,
      searchData,
      loading,
      dataSource,
      isExportShow,
    } = this.state;
    return (
      <div className="ProjectListBox StatisticalReport">
        <div className="account">
          <div className="searchbox">
            <Search
              tabList={[{ value: "部门毛利率统计" }]}
              FormData={searchData}
              handleSubmit={(values) => {
                this.querySearch(values);
              }}
            />
          </div>

          <div className="operation-table">
            <div className="CardBox">
              {statisticsText.map((item, index) => {
                return (
                  <div key={index} className="card">
                    <Card className="cardStyle">
                      {this.state.statisticsData.length !== 0 ? (
                        <span className="cardValue">
                          {item.key === "grossProfitRate"
                            ? this.state.statisticsData[item.key] + "%"
                            : isPositiveIncome(
                                this.state.statisticsData[item.key]
                              )}
                        </span>
                      ) : (
                        <span className="cardValue">-</span>
                      )}
                      <span className="cardLabel">{item.title}</span>
                    </Card>
                  </div>
                );
              })}
            </div>
            {this.$common.isArray(dataSource) && (
              <div className="Space8">
                <Button
                  className="btnColor"
                  loading={isExportShow}
                  onClick={this.exportExcel}
                >
                  导出列表
                </Button>
              </div>
            )}
            <Table
              keyName="grossProfitMarginStatisticsId"
              columns={columns}
              dataSource={dataSource}
              scroll={{ x: "100%" }}
              loading={loading}
              total={total}
              current={pageNo}
              pageSize={pageSize}
              isPagination={true}
              handleChangePage={this.queryTableList}
              handleChangePageSize={this.queryTableList}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DepartmentIncomeRate;
