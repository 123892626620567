/*
 * @文档描述: 导入按钮
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-08-07 17:14:00
 * @最后修改人: 文倩
 */
import React, { Component, Fragment } from "react";
import { Button, message, Upload, Modal, Icon } from "antd";

import ModalAlert from "./importAlert";
import "./index.less";

/*
 * @Description: 组件所需传入参数
 * @String:title        //弹窗标题
 * @String:text         //按钮文本
 * @function:importOk           ()=>{}  //导入成功后刷新列表
 * @function:importFile         ()=>{}  //导入请求接口  ps:(v)=>{this.download(v)}
 * @function:downLoadError      ()=>{}   //导入失败时，下载原因的回调  只需传入方法和参数   ps:(v)=>{this.download(v)}
 */

class ImportBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isImportLoading: false,
      visible: false,
      isFileNameShow: false,
      alertVisible: false, //导出结果弹窗
      file: "",
      importType: "",
      successNum: "",
      errorNum: "",
      importId: "",
    };
  }
  /**
   * @函数描述: 导入
   * @param {String} file  文件名
   */
  handleUpload = (file) => {
    this.setState({
      file,
      isFileNameShow: true,
    });
  };

  /**
   * @description: 上传成功
   * @param {type}
   * @return:
   * @author: 文倩
   */
  handleOk = async () => {
    const { file } = this.state;
    if (file != "") {
      let fileData = new FormData(); //创建文件流

      if (this.$common.limitUploadxlsx(file.file)) {
        fileData.append("file", file.file);

        this.setState({
          isImportLoading: true,
        });

        const res = await this.props.importFile(fileData);

        if (res && res.request && res.request.responseType == "blob") {
          var reader = new FileReader();
          reader.onload = () => {
            var content = JSON.parse(reader.result); //内容就在这里
            this.setState({
              isImportLoading: false,
            });
            try {
              if (content && content.errorCode == 200) {
                if (content.data.success) {
                  this.setState({
                    importType: "imported",
                    alertVisible: true,
                    visible: false,
                    isFileNameShow: false,
                    file: "",
                    successNum: content.data.successNum,
                    errorNum: content.data.errorNum,
                  });
                } else {
                  this.setState({
                    importType: "importerror",
                    alertVisible: true,
                    visible: false,
                    isFileNameShow: false,
                    file: "",
                    importId: content.data.importId,
                    successNum: content.data.successNum,
                    errorNum: content.data.errorNum,
                  });
                }
                this.props.importOk();
              } else {
                message.error(content.message);
              }
            } catch {
              console.log("导入失败");
              this.setState({
                isImportLoading: false,
                visible: false,
                isFileNameShow: false,
                file: "",
              });
            }
          };
          reader.readAsText(res.data);
        } else {
          this.setState({
            isImportLoading: false,
          });
        }
      }
    } else {
      message.warning("请先上传文件");
    }
  };

  /**
   * @description: 弹窗显隐，true为显示，false为隐藏
   * @param {type}
   * @return:
   * @author: 文倩
   */
  handleOpen = () => {
    this.setState({
      visible: true,
    });
  };

  /**
   * @description: 弹窗显隐，true为显示，false为隐藏
   * @param {type}
   * @return:
   * @author: 文倩
   */
  handleCancel = () => {
    this.setState({
      visible: false,
      isFileNameShow: false,
      file: "",
    });
  };

  /**
   * @description: 删除文件
   * @param {type}
   * @return:
   * @author: 文倩
   */
  handleRemove = () => {
    this.setState({
      isFileNameShow: false,
      file: "",
    });
  };

  /**
   * @description: 导入结果弹窗隐藏
   * @param {type}
   * @return:
   * @author: 文倩
   */
  onCancel = () => {
    this.setState({
      alertVisible: false,
    });
  };

  render() {
    const {
      isImportLoading,
      visible,
      alertVisible,
      file,
      isFileNameShow,
      importType,
      successNum,
      errorNum,
      importId,
    } = this.state;
    const { text, title } = this.props;
    const params = {
      accept: ".xlsx,.xls",
      multiple: this.props.multiple,
      customRequest: this.handleUpload,
    };
    return (
      <Fragment>
        <Button onClick={this.handleOpen}>{text}</Button>
        <Modal
          title={title}
          visible={visible}
          wrapClassName="importBtn"
          confirmLoading={isImportLoading}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="importFile">
            <h6>选择要上传的文件:</h6>
            {isFileNameShow ? (
              <span className="fileName">
                <font>{file.file && file.file.name}</font>
                <Icon type="close" onClick={this.handleRemove} />
              </span>
            ) : (
              <Upload {...params}>
                <Button type="dashed">
                  <Icon type="plus" />
                  添加文件
                </Button>
              </Upload>
            )}
          </div>
        </Modal>

        <ModalAlert
          visible={alertVisible}
          type={importType}
          successNum={successNum}
          errorNum={errorNum}
          importId={importId}
          downLoadError={(val) => this.props.downLoadError(val)}
          onCancel={this.onCancel}
          onOk={this.onCancel}
        />
      </Fragment>
    );
  }
}
export default ImportBtn;
