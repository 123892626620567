/*
 * @Description: 城市页面
 * @Autor: 文倩
 * @Date: 2020-07-13 14:25:33
 * @LastEditors: 文倩
 * @LastEditTime: 2020-08-10 15:01:38
 */

import React, { Component } from "react";
import { Button } from 'antd';

import Table from '../../../../components/tableSmall';
import { CityColums } from '../../contants';

import AddCity from './addCity'; //新增城市
import RemoveCity from './removeCity'; //删除城市

import '../index.less';

class City extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 10,
            total: 0,
            tableList: [],
            loading: false,   //加载动画
            selectedRowKeys: []  //复选框选中的值
        }
    }


    componentDidMount() {
        this.queryTableList();
    }


    /**
     * @description: 查询城市数据
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    queryTableList = (page=this.state.page,pageSize=10) => {
        const query = {
            page,
            pageSize
        }
        this.setState({
            loading: true
        });
        this.$http.getCityList(query).then(res => {
            if (res && res.errorCode == 200) {
                this.setState({
                    tableList: res.data.rows,
                    page,
                    pageSize,
                    total: res.data.total,
                    selectedRowKeys:[]
                });
            }
            if (res) {
                this.setState({
                    loading: false
                });
            }
        })
    }


    /**
     * @description: 修改城市
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    updateCity = (values) => {
        
        const query = {
            cityId: values.cityId,
            cityName: values.cityName
        }
        return this.$http.updateCity(query);
    }

    /**
     * @description: 选中复选框的回调
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    onSelectChange = keys => {
        this.setState({
            selectedRowKeys: keys
        });
        console.log(keys, '----------复选');
    }


    render() {
        let { tableList, loading, selectedRowKeys,page,pageSize,total } = this.state;


        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange
        }

        return (
            <div className="operation-tableSmall">
                <AddCity
                    handleOk={this.queryTableList} />
                <RemoveCity
                    rowkeys={selectedRowKeys}
                    handleOk={()=>{
                        if (page > 1 && selectedRowKeys.length > 0
                            && selectedRowKeys.length <= 10 &&
                            tableList.length <= 10 ) {
                                page = page - 1;
                        }
                        this.queryTableList(page);
                    }} />
                <Table
                    rowKey="cityId"
                    showHeader={true}
                    bordered={false}
                    loading={loading}
                    operateClassName="null"
                    width={120}
                    current={page}
                    pageSize={pageSize}
                    total={total}
                    isPagination={true}
                    rowSelection={rowSelection}
                    columns={CityColums}
                    dataSource={tableList}
                    handleSubmit={(val) => this.updateCity(val)}
                    handleOk={this.queryTableList}
                    handleChangePage={this.queryTableList}
                    handleChangePageSize={this.queryTableList} />
            </div>
        );
    }
}

export default City;