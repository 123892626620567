/*
 * @文档描述: 社保公积金成本 --- 新增
 * @author: 文倩
 * @Date: 2020-06-03 20:08:26
 * @LastEditTime: 2020-08-11 18:34:07
 * @最后修改人: 文倩
 */
import React, { Component, Fragment } from 'react';
import { Modal, Form, Button,Select, message } from 'antd';


import NumberInput from '../../../../../components/baseInput/number';

import { baseInput4 } from '../../../../../utils/validate';


const FormItem = Form.Item;
const Option=Select.Option;

class AddSocial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false, // 编辑弹框显隐
            citySelectList: []  //城市下拉数据
        };
    }

    

    /**
     * @description: 查询城市列表下拉数据
     * @param {type} 
     * @return: 
     * @author: 文倩
     */
    getCityNameList=()=>{
        this.$http.getAllCityList().then(res=>{
            if(res && res.errorCode == 200){
                this.setState({
                    citySelectList:res.data
                });
            }
        })
    }

    /**
     * @函数描述: 点击显示编辑弹框
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return:
     */
    handlePassBtnShow = () => {
        this.setState({
            visible: true
        },()=>{
            this.getCityNameList()
        });
    };
    
    /**
     * @函数描述: 取消弹框显示
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦
     */
    handlePassBtnCancle = () => {
        this.setState(
            {
                visible: false
            },
            () => {
                this.props.form.resetFields();
            }
        );
    };
    /**
     * @函数描述: 确定编辑成功
     * @param {String} passMessage  审批意见
     * @param {String} id
     * @param {Boolean} visible  设置弹框显隐 true：显示  false：隐藏
     * @return: 记得重置表单呦，重新表格当前页数据
     */
    handleAddSure = e => {
        e.preventDefault();
        const { validateFieldsAndScroll } = this.props.form;


        validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                const query={
                    ...values
                }
                this.$http.addSocial(query).then(res=>{
                    if(res && res.errorCode == 200){
                        this.handlePassBtnCancle();
                        this.props.handleOk();
                    }
                })
            }
        });
    };






    render() {
        const { form } = this.props;
        const { visible, citySelectList } = this.state;

        return (
            <Fragment key="passBtn">
                <Button onClick={this.handlePassBtnShow} type="primary">
                    新增
                    </Button>
                <Modal
                    className="reviseModal"
                    title="新增"
                    visible={visible}
                    width={518}
                    onCancel={this.handlePassBtnCancle}
                    onOk={this.handleAddSure}
                    maskClosable={false}
                    okText="提交"
                >
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }}>
                        <FormItem label='城市名称'>
                            {this.props.form.getFieldDecorator('cityId', {
                                // initialValue: formData && formData.type,
                                rules: [{
                                    required: true,
                                    message: "请选择城市名称"
                                }]
                            })(
                                <Select
                                    placeholder='请选择城市名称'
                                    allowClear
                                >
                                    {citySelectList.map(res => {
                                        return <Option key={res.cityId}>{res.cityName}</Option>;
                                    })}
                                </Select>
                            )}
                        </FormItem>
                        <NumberInput
                            form={form}
                            label="社保公积金成本"
                            field='socialSecurityFundCost'
                            required={true}
                            message={"长度为10，包括小数点和两位小数"}
                            placeholder='请输入公积金成本'
                            // initialValue={formData && formData.roleName}
                            validatorName={baseInput4}
                            maxLength={10}
                            min={0}
                            max={9999999.99}
                            step={0.01}
                        />
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}
export default Form.create()(AddSocial);
