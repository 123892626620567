/*
 * @Description: 人员离职处理
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:18:49
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-12-02 15:29:31
 */

import React, { Component } from "react";
import { Button, Select, Form, Modal, DatePicker, message } from "antd";
import "./Components.less";
import moment from "moment";
import Table from "../../../../../components/table/index";
import TooltipComponent from "../../../../../components/TooltipComponent"; // 文本溢出提示
const { Option } = Select;
class StaffTurnover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 3,
      visible: false,
      loading: false,
      pageNo: 1,
      pageSize: 10,
      isCard: false,
      searchValue: {},
      detailData: {},
      selectedRowKeys: [],
      statusList: {},
      btnLoading: false,
    };
  }
  /**
   * @description: 人员离职
   * @author: 陈金金
   */
  handleAddSure = () => {
    const { projectId } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const param = {
          resigningTime: values.resigningTime.format("YYYY-MM-DD"),
          projectId,
          remarks: values.remarks,
          resigningStaffList: values.resigningStaffList.map((v) => {
            return v;
          }),
        };
        this.setState({ btnLoading: true });
        this.$http.addStaffResigning(param).then((res) => {
          this.setState({ btnLoading: false });
          if (res && res.errorCode == 200) {
            message.destroy();
            message.success(res.message);
            this.props.closeModal();
            this.setState({
              visible: false,
            });
          }
        });
      }
    });
  };

  handleReturnStockBtnCancle = () => {
    this.props.form.resetFields();
    this.setState({ visible: false });
  };

  openModal = () => {
    //
    const { row } = this.props;
    this.setState({ visible: true }, () => {
      this.setState({
        detailData: row,
      });
      this.getDicCacheList();
    });
  };
  /**
   * @description: 获取下拉枚举值
   * @param {type}
   * @return:
   * @author: 陈金金
   */
  getDicCacheList = () => {
    this.$http.queryDicCacheList(["LEAVE_OFFICE"]).then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          statusList: res.data,
        });
      }
    });
  };
  render() {
    const { visible, statusList, btnLoading } = this.state;
    const { dataList } = this.props;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "序号",
        width: 100,
        render: (text, record, index) => `${index + 1}`,
      },
      {
        title: "工号",
        dataIndex: "staffCode",
        key: "staffCode",
        width: 120,
        render: (t) => (t ? <TooltipComponent width={100} text={t} /> : "-"),
      },
      {
        title: "姓名",
        dataIndex: "staffName",
        key: "staffName",
        width: 120,
        render: (t) => (t ? <TooltipComponent width={100} text={t} /> : "-"),
      },
      {
        title: "入职时间",
        dataIndex: "onBoardingTime",
        key: "onBoardingTime",
        width: 120,
        render: (t, r, i) => {
          return (
            <>
              {
                (getFieldDecorator(`resigningStaffList[${i}].staffId`, {
                  initialValue: dataList[i].staffId || undefined,
                }),
                getFieldDecorator(`resigningStaffList[${i}].staffName`, {
                  initialValue: dataList[i].staffName || undefined,
                }),
                getFieldDecorator(`resigningStaffList[${i}].staffCode`, {
                  initialValue: dataList[i].staffCode || undefined,
                }),
                getFieldDecorator(`resigningStaffList[${i}].onBoardingTime`, {
                  initialValue: dataList[i].onBoardingTime || undefined,
                }))
              }
              {t ? <TooltipComponent width={100} text={t} /> : "-"}
            </>
          );
        },
      },
    ];
    return (
      <>
        <Button
          className="btnColor"
          disabled={dataList.length == 0}
          onClick={() => this.openModal()}
        >
          人员离职
        </Button>
        {visible && (
          <Modal
            className="returnStockModal"
            title="人员离职处理"
            visible={visible}
            width={480}
            footer={[
              <div key="StaffTurnoverModal">
                <Button
                  key="cancle"
                  onClick={() => this.handleReturnStockBtnCancle()}
                >
                  取消
                </Button>
                <Button
                  key="sure"
                  onClick={this.handleAddSure}
                  loading={btnLoading}
                  type="primary"
                >
                  提交{" "}
                </Button>
              </div>,
            ]}
            onCancel={this.handleReturnStockBtnCancle}
            maskClosable={false}
            okText="提交"
          >
            <Form>
              <Form.Item label="离职时间">
                {getFieldDecorator("resigningTime", {
                  rules: [
                    {
                      type: "object",
                      required: true,
                      message: "请选择离职时间",
                    },
                  ],
                })(
                  <DatePicker
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    disabledDate={(e) => {
                      return (
                        e &&
                        e <
                          moment()
                            .subtract(1, "months")
                            .startOf("month")
                      );
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label="离职原因说明">
                {getFieldDecorator("remarks", {
                  rules: [{ required: true, message: "请选择离职原因说明" }],
                })(
                  <Select placeholder="请选择" allowClear>
                    {this.isArray(statusList["LEAVE_OFFICE"]) &&
                      statusList["LEAVE_OFFICE"].map((v) => {
                        return (
                          <Option key={v.key} value={v.key}>
                            {v.value}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Form>
            <Table
              rowKey="StaffTurnover"
              className="StaffTurnover"
              pagination={false}
              columns={[...columns]}
              dataSource={dataList}
            />
          </Modal>
        )}
      </>
    );
  }
}

export default Form.create()(StaffTurnover);
