/*
 * @Author: 陈金金
 * @Date: 2020-07-14 18:32:55
 * @LastEditTime: 2020-08-26 11:14:53
 * @LastEditors: 陈金金
 * @Description: 项目看板详情
 */
import React, { Component } from "react";
import { Modal, Row, Col, Form, Button, Select } from "antd";
import "./ModalData.less";
import Table from "../../../../../components/table/index";
import TooltipComponent from "../../../../../components/TooltipComponent"; // 文本溢出提示
import { userInfoKey, changeColumns, ApprovedColumns } from "./data";
const { Option } = Select;
class ModalData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 3,
      loading: false,
      page: 1,
      pageSize: 10,
      isCard: false,
      detailData: {},
      dataSource: [],
      searchValue: {},
      ChangeFieldList: [],
    };
  }
  /**
   * @description:
   * @param {type} type 1,核准金额历史数据；2.历史变更记录 3.人员信息详情
   * @return {type}
   * @author: 陈金金
   */
  componentDidMount() {
    const { type } = this.props;
    if (type == 1) this.getApprovalList();
    if (type == 2) {
      this.getChangeFieldList(); //变更项数据
      this.getProjectChangeDetail(); //历史变更记录
    }
    if (type == 3) this.getDetail();
  }
  getDetail = () => {
    const { ModalDetail } = this.props;
    this.setState({ loading: true });
    this.$http
      .getProjectStaffRosterById({ staffId: ModalDetail.staffId })
      .then((res) => {
        console.log("res --->", res);
        this.setState({ loading: false });
        if (res && res.errorCode == 200) {
          this.setState({
            detailData: res.data,
          });
        }
      });
  };
  /**
   * @description: 获取变更项数据
   * @param {type}
   * @return {type}
   * @author: 陈金金
   */
  getChangeFieldList = () => {
    this.$http.queryChangeField().then((res) => {
      console.log("queryChangeField ---> ", res);
      if (res && res.errorCode == 200) {
        this.setState({
          ChangeFieldList: res.data,
        });
      }
    });
  };
  /**
   * @description: 变更项查询
   * @author: 陈金金
   */
  renderSearchHtml = () => {
    const { getFieldDecorator } = this.props.form;
    const { ChangeFieldList } = this.state;
    return (
      <Form layout="inline">
        <Row justify="start">
          <Col span={12}>
            <Form.Item label={"变更项"}>
              {getFieldDecorator("changeField")(
                <Select
                  placeholder="请选择变更项"
                  allowClear
                  style={{ width: "140px" }}
                >
                  {Object.keys(ChangeFieldList || {}).map((item) => {
                    return <Option key={item}>{ChangeFieldList[item]}</Option>;
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className="Space8">
              <Button type="primary" onClick={this.handleSearch}>
                {" "}
                搜 索
              </Button>
              <Button onClick={this.onReset}>重 置</Button>
            </div>
          </Col>
        </Row>
      </Form>
    );
  };
  /**
   * @description: 人员搜索
   * @author: 陈金金
   */
  handleSearch = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      console.log(values);
      this.setState(
        {
          searchValue: values,
          dataSource: [],
          page: 1,
        },
        () => {
          this.getProjectChangeDetail();
        }
      );
    });
  };
  onReset = () => {
    this.props.form.resetFields(); // 重置表单
    this.setState(
      {
        searchValue: {},
      },
      () => {
        this.getProjectChangeDetail();
      }
    );
  };

  /**
   * @description: 获取核准金额列表数据
   * @param {type} projectId
   * @param {type} page
   * @param {number} pageSize
   * @return:
   * @author: 陈金金
   */

  getApprovalList = (
    page = this.state.page,
    pageSize = this.state.pageSize
  ) => {
    const { projectId } = this.props;
    this.setState({ loading: true });
    this.$http
      .pageApprovalDetailByCondition({ page, pageSize, projectId })
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.errorCode == 200) {
          this.setState({
            dataSource: res.data.rows,
            total: res.data.total,
            page,
            pageSize,
          });
        }
      });
  };

  /**
   * @description: 获取历史变更记录列表数据
   * @param {String} projectId
   * @param {number} page
   * @param {number} pageSize
   * @return:
   * @author: 陈金金
   */

  getProjectChangeDetail = (
    page = this.state.page,
    pageSize = this.state.pageSize
  ) => {
    const { projectId } = this.props;
    const { searchValue } = this.state;
    this.setState({ loading: true });
    this.$http
      .pageProjectChangeDetailByCondition({
        page,
        pageSize,
        projectId,
        ...searchValue,
      })
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.errorCode == 200) {
          this.setState({
            dataSource: res.data.rows,
            total: res.data.total,
            page,
            pageSize,
          });
        }
      });
  };
  /**
   * @description: 人员基本详情
   * @return:
   */
  userInfoDetailKeyHTML = () => {
    const { detailData } = this.state;
    return userInfoKey.map((item) => {
      return (
        <Col span={8} key={item.key}>
          <div className="detailbox">
            <span className="label">{item.name}</span>
            <div className="value">
              {detailData[item.key] ? (
                <TooltipComponent
                  text={
                    item.before != ""
                      ? this.$common.isPositiveIncome(detailData[item.key]) +
                        item.after
                      : item.after != ""
                      ? this.$common.accMul(detailData[item.key], 1) + "%"
                      : detailData[item.key] + item.after
                  }
                  width={100}
                />
              ) : (
                "--"
              )}
            </div>
          </div>
        </Col>
      );
    });
  };

  render() {
    const { total, page, pageSize, dataSource } = this.state;
    const { visible, title, type, loading } = this.props;
    return (
      <div className="ModalDataBox">
        <Modal
          visible={visible}
          title={title}
          className="ModalData"
          width={type == 3 ? 730 : 600}
          onCancel={() => this.props.closeModal()}
          footer={null}
        >
          {type == 2 && this.renderSearchHtml()}
          {type != 3 ? (
            <Table
              keyName={type == 1 ? "approvalDetailId" : "projectId"}
              columns={type == 1 ? [...ApprovedColumns] : [...changeColumns]}
              dataSource={dataSource}
              scroll={{ x: "100%" }}
              loading={loading}
              total={total}
              current={page}
              pageSize={pageSize}
              isPagination={true}
              handleChangePage={
                type == 1
                  ? this.getApprovalList
                  : type == 2
                  ? this.getProjectChangeDetail
                  : ""
              }
              handleChangePageSize={
                type == 1
                  ? this.getApprovalList
                  : type == 2
                  ? this.getProjectChangeDetail
                  : ""
              }
            />
          ) : (
            <Row>{this.userInfoDetailKeyHTML()}</Row>
          )}
        </Modal>
      </div>
    );
  }
}

export default Form.create()(ModalData);
