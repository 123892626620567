import { message } from 'antd';

// 判断数组有没有值
export const isArray = (arr) => {
    if (Array.isArray(arr) && arr.length > 0) {
        return true;
    } else {
        return false;
    }
}
//判断是不是正数
export const isPositiveNum = (x) => {
    if (isRealNum(x)) {
        if (x > 0) {
            return true;
        } else if (x < 0) {
            return false;
        }
    }

}
// 判断是不是数字
export const isRealNum = (val) => {
    // isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，

    if (val == "" || val == null || val == undefined) {
        return false;
    }
    if (!isNaN(val)) {
        //对于空数组和只有一个数值成员的数组或全是数字组成的字符串，isNaN返回false，例如：'123'、[]、[2]、['123'],isNaN返回false,
        //所以如果不需要val包含这些特殊情况，则这个判断改写为if(!isNaN(val) && typeof val === 'number' )
        return true;
    } else {
        return false;
    }
}
// 判断金额是不是负数
export const isPositiveIncome = (x) => {
    if (isRealNum(x)) {
        if (x > 0 || x == 0) {
            return '¥' + toQfw(x);
        } else if (x < 0) {
            return '-¥' + toQfw(Math.abs(x));
        }
    }
}
// 千分位转换
export const Thousandth = (x) => {
   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
// 小数转百分比
export const accMul = (arg1, arg2) => {
    let m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
        m += s1.split('.')[1].length;
    }
    catch (e) {

    }
    try {
        m += s2.split('.')[1].length;
    }
    catch (e) {

    }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)

}


//转化千分位
export const toQfw = (number) => {
        number = (number + '').replace(/[^0-9+-Ee.]/g, '');
        let decimals = 2; //默认保留2位
        let dec_point =  "."; //默认是'.';
        let thousands_sep =  ","; //默认是',';
        let round_tag = "round"; //默认是四舍五入
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
     
                var k = Math.pow(10, prec);
                console.log();
     
                return '' + parseFloat(Math[round_tag](parseFloat((n * k).toFixed(prec*2))).toFixed(prec*2)) / k;
            };
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        var re = /(-?\d+)(\d{3})/;
        while (re.test(s[0])) {
            s[0] = s[0].replace(re, "$1" + sep + "$2");
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
}

//导出
export const getExcel = (res, time) => {
    const url = window.URL.createObjectURL(new Blob([res.data],
        // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                                                   
        { type: 'application/vnd.ms-excel;charset=UTF-8' }));

    const link = document.createElement('a');
    link.href = url;
    // 从header中获取服务端命名的文件名
    const content = res && res.headers['content-disposition'] && res.headers['content-disposition'].split(';')[1];
    const contentName = time ? content.split('.')[0] + time + '.' + content.split('.')[1] : content;
    const str = contentName && contentName.match(/=(\S*)/)[1];
    const fileName = decodeURI(str);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}

//返回url最后一个斜杠后面的内容
export const getParamsJson = (str) => {
    const urlStr = "{" + str.match(/{(\S*)/)[1];
    return JSON.parse(urlStr);
}

//获取yyyy-mm/yyyy-mm-dd/yyyy-mm-dd hh:mm
export const getFullDateYear = (type, dateTime) => {
    if (dateTime !== "" && dateTime !== null && dateTime !== undefined) {
        const date = dateTime._d;
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
        hour = hour < 10 ? "0" + hour : hour;
        minute = minute < 10 ? "0" + minute : minute;
        const newMonth = year + "-" + month;
        const newDate = year + "-" + month + "-" + day;
        const newTime = year + "-" + month + "-" + day + " " + hour + ":" + minute;
        if (type === "date") {
            return newDate;
        } else if (type === "month") {
            return newMonth;
        } else if (type === "time") {
            return newTime;
        }
    }
}

//上传文件限制
export const limitUpload = (file) => {
    const imgMasSize = 1024 * 1024 * 10; // 10MB
    // 检查文件类型
    if (['doc', 'docx', 'xlsx', 'msg', 'pdf', 'rar', 'zip', '7z', 'png', 'jpeg', 'jpg'].indexOf(file.name.split(".").slice(-1).toString()) < 0) {
        message.info("该文件为不支持的文件类型");
        return false;
    }
    // 文件大小限制
    if (file.size > imgMasSize) {
        message.info("文件大小不能超过10MB！");
        return false;
    }
    return true;
}
export const limitUploadxlsx = (file) => {
    // 检查文件类型
    if (['xlsx', 'xls'].indexOf(file.name.split(".").slice(-1).toString()) < 0) {
        message.warn("不支持的该文件上传");
        return false;
    }

    return true;
}

//将一维数组=>二维数组
export const getTwoDArray = (list) => {
    let len = list.length;
    let n = 8; //假设每行显示6个
    let lineNum = len % 8 === 0 ? len / 8 : Math.floor((len / 8) + 1);
    let result = [];
    for (let i = 0; i < lineNum; i++) {
        let temp = list.slice(i * n, i * n + n);
        result.push(JSON.parse(JSON.stringify(temp)));
    }
    return result;
}
//获取部门数据
export const getDepartmentData = (list) => {
    list.map(item => {
        item.title = item.organizationName;
        item.value = item.organizationCode;
        if (item.children && item.children.length > 0) {
            getDepartmentData(item.children)
        }
        return item;
    })
    return list;
}
//过滤对象中空值
export const filterObjNull = (obj) => {
    if (Object.keys(obj).length !== 0) {
        for (const key in obj) {
            if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
                delete obj[key];
            }
        }
    }
    return obj;
}
//截取url中的参数
export const getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}
export const getUrlString = (name, url) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = url.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}
// 删除某行数据
export const arrMove = (arr, key) => {
    var temp = [];
    for (var i = 0; i < arr.length; i++) {
        if (i !== key) {
            temp.push(arr[i]);
        }
    }
    return temp;
}
// url截取
export const searchUrl = (search) => {
    let obj = {}
    if (search.indexOf('?') > -1) {
        let param = search.split('?')[1]

        if (search.indexOf('&') > -1) {
            param.split('&').map(v => {
                return obj[v.split('=')[0]] = v.split('=')[1]
            })
        } else {
            search.split('?')[1].map(v => {
                return obj[v.split('=')[0]] = v.split('=')[1]
            })
        }

    }
    return obj
}