/*
 * @Description: 薪资结构调整
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:18:49
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-12-04 17:27:25
 */

import React, { Component } from "react";
import { Button, Select, Row, Col, Form, Modal, message } from "antd";
import "./Components.less";
import TooltipComponent from "../../../../../components/TooltipComponent"; // 文本溢出提示
import TextFieldInput from "../../../../../components/sceneInput/TextAreaInput";
import InputNumber from "../../../../../components/baseInput/number";
import ChangeKeyValue from "./ChangeKeyValue";
import { baseInput4 } from "../../../../../utils/validate.js";
import { isArray } from "../../../../../utils/common";
import { forEach, join, cloneDeep } from "lodash";
const { Option } = Select;
class SalaryChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 3,
      visible: false,
      loading: false,
      pageNo: 1,
      pageSize: 10,
      isCard: false,
      searchValue: {},
      detailData: {},
      selectedRowKeys: [],
      SALARY_ADJUST: "",
      statusList: {},
      btnLoading: false,
    };
  }
  /**
   * @description: 薪资结构调整发起
   * @author: 陈金金
   */
  handleAddSure = () => {
    const { row, projectid } = this.props;
    const { SALARY_ADJUST, detailData } = this.state;
    const { validateFieldsAndScroll } = this.props.form;
    const diffValue = this.nonSystematicWageChangesDiff();
    console.log("diffValue --->", diffValue);
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("values --- >", values);
        let { adjustType, remarks, adjustAfter, adjustBefore } = values;
        if (SALARY_ADJUST == "PERFORMANCE_STANDARD") {
          if (diffValue.adjustAfter != ",,") {
            const {
              adjustAfter: diffAdjustAfter,
              adjustBefore: diffAdjustBefore,
            } = diffValue;
            adjustAfter = diffAdjustAfter;
            adjustBefore = diffAdjustBefore;
          } else {
            message.warning("非系统工资变动至少修改一项");
            return;
          }
        }
        if (SALARY_ADJUST == "PAY_TYPE") {
          adjustAfter = detailData.payType == "PAY" ? "NON_PAY" : "PAY";
        }
        const param = {
          staffId: row.staffId,
          staffCode: row.staffCode,
          staffName: row.staffName,
          projectId: projectid,
          adjustAfter,
          adjustBefore,
          adjustType,
          remarks,
        };
        this.setState({ btnLoading: true });
        this.$http.addSalaryAdjust(param).then((res) => {
          this.setState({ btnLoading: false });
          if (res && res.errorCode == 200) {
            message.destroy();
            message.success(res.message);
            this.props.closemodal();
            this.handleReturnStockBtnCancle();
          }
        });
      }
    });
  };

  /**
   *@method 非系统工资变动值对比
   *@returns { Object } { adjustAfter:String, adjustBefore:String }
   */
  nonSystematicWageChangesDiff = () => {
    const { getFieldsValue } = this.props.form;
    console.log("getFieldsValue --->", getFieldsValue());
    const {
      nonSystemFixedSalaryIncrease,
      nonSystemFixedSalaryIncreaseBefore,
      personalPerformanceBenchmarks,
      personalPerformanceBenchmarksBefore,
      projectPerformancePoolBenchmarks,
      projectPerformancePoolBenchmarksBefore,
    } = getFieldsValue();
    // const {
    //   nonSystemFixedSalaryIncrease,
    //   nonSystemFixedSalaryIncreaseBefore,
    //   personalPerformanceBenchmarks,
    //   personalPerformanceBenchmarksBefore,
    //   projectPerformancePoolBenchmarks,
    //   projectPerformancePoolBenchmarksBefore,
    // } = values;
    // 调整之后['1','3','5']
    let afterOption = [
      nonSystemFixedSalaryIncrease,
      personalPerformanceBenchmarks,
      projectPerformancePoolBenchmarks,
    ];
    // 调整之前['2','6','8']
    let beforeOption = [
      nonSystemFixedSalaryIncreaseBefore,
      personalPerformanceBenchmarksBefore,
      projectPerformancePoolBenchmarksBefore,
    ];
    let copyBeforeOption = cloneDeep(beforeOption);
    let copyAfterOption = cloneDeep(afterOption);
    forEach(copyBeforeOption, (value, index) => {
      if (Number(value) == Number(copyAfterOption[index])) {
        delete beforeOption[index];
        delete afterOption[index];
      }
    });
    console.log("afterOption --->", afterOption);
    console.log("beforeOption --->", beforeOption);
    let adjustAfter = join(afterOption, ",");
    let adjustBefore = join(beforeOption, ",");
    return {
      adjustAfter,
      adjustBefore,
    };
  };

  handleReturnStockBtnCancle = () => {
    this.props.form.resetFields();
    this.setState({ visible: false });
  };
  /**
   * @description: 自动回显数据
   * @return:
   */
  renderDetailKeyHTML = () => {
    const { detailData } = this.state;
    return [
      { name: "调整人姓名：", key: "staffName" },
      { name: "调整人工号：", key: "staffCode" },
    ].map((item) => {
      return (
        <Col span={24} key={item.key}>
          <div className="detailbox">
            <span className="label">{item.name}</span>
            <div className="value">
              {detailData[item.key] ? (
                <TooltipComponent text={detailData[item.key]} width={200} />
              ) : (
                "--"
              )}
            </div>
          </div>
        </Col>
      );
    });
  };

  /**
   * @description: 点击弹窗获取父组件行数据
   * @param {type}
   * @return {type}
   * @author: 陈金金
   */
  openModal = () => {
    //
    const { row } = this.props;
    console.log("detailData --- >", row);
    this.setState({ visible: true }, () => {
      this.setState({
        detailData: row,
      });
      this.getDicCacheList();
    });
  };
  /**
   * @description: 获取下拉枚举值
   * @param {type}
   * @return:
   * @author: 陈金金
   */
  getDicCacheList = () => {
    this.$http.queryDicCacheList(["SALARY_ADJUST", "PAY_TYPE"]).then((res) => {
      console.log("获取下拉枚举值 --->", res);
      if (res && res.errorCode == 200) {
        this.setState({
          statusList: res.data,
        });
      }
    });
  };

  handleChange = (e) => {
    this.setState(
      {
        SALARY_ADJUST: e,
      },
      () => {
        if (e != "PAY_TYPE") {
          this.props.form.setFieldsValue({
            [`adjustAfter`]: undefined,
          });
        }
      }
    );
  };

  render() {
    const {
      visible,
      SALARY_ADJUST,
      detailData,
      statusList,
      btnLoading,
    } = this.state;
    const { form, key } = this.props;
    const {
      nonSystemFixedSalaryIncrease,
      personalPerformanceBenchmarks,
      projectPerformancePoolBenchmarks,
    } = detailData;
    console.log("detailData --->", detailData);
    const { getFieldDecorator } = this.props.form;
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    return (
      <>
        <span className="span" key={key} onClick={() => this.openModal()}>
          薪资结构调整
        </span>
        {visible && (
          <Modal
            className="BasicInfoChangeModal"
            title="薪资结构调整"
            visible={visible}
            width={480}
            footer={[
              <div key="BasicInfoChangeModal">
                <Button
                  key="BasicInfoChangeModalcancle"
                  onClick={() => this.handleReturnStockBtnCancle()}
                >
                  取消
                </Button>
                <Button
                  key="BasicInfoChangeModalsure"
                  onClick={this.handleAddSure}
                  loading={btnLoading}
                  type="primary"
                >
                  提交
                </Button>
              </div>,
            ]}
            onCancel={this.handleReturnStockBtnCancle}
            maskClosable={false}
          >
            <Row>{this.renderDetailKeyHTML()}</Row>
            <Form>
              <Form.Item label="调整人类型">
                {getFieldDecorator("adjustType", {
                  rules: [{ required: true, message: "请选择调整人类型" }],
                })(
                  <Select
                    placeholder="请选择"
                    allowClear
                    onChange={(e) => this.handleChange(e)}
                  >
                    {isArray(statusList["SALARY_ADJUST"]) &&
                      statusList["SALARY_ADJUST"].map((v) => {
                        return (
                          <Option key={v.key} value={v.key}>
                            {v.value}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
              {SALARY_ADJUST == "PAY_TYPE" && (
                <div>
                  <Row gutter={20}>
                    <Col span={12}>
                      <div className="flexstart detailbox">
                        <div className="label">原付费类型：</div>
                        <div className="value">
                          {detailData.payType == "PAY" ? "付费" : "不付费"}
                        </div>
                        {getFieldDecorator("adjustBefore", {
                          initialValue: detailData.payType,
                        })}
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="flexend detailbox">
                        <div className="label">调整后：</div>
                        <div className="value">
                          {detailData.payType == "PAY" ? "不付费" : "付费"}
                        </div>
                        {getFieldDecorator("adjustAfter", {
                          initialValue:
                            detailData.payType == "PAY" ? "NON_PAY" : "PAY",
                        })}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              {SALARY_ADJUST == "SALARY_ADJUST" && (
                <div>
                  <Row gutter={20}>
                    <Col span={12}>
                      <div className="flexstart detailbox">
                        <div className="label">原系统薪资：</div>
                        <div className="value">¥{detailData.turnSalary}</div>
                        {getFieldDecorator("adjustBefore", {
                          initialValue: detailData.turnSalary,
                        })}
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="flexend detailbox">
                        <div className="label">调整后：</div>
                        <InputNumber
                          form={form}
                          required={true}
                          allowClear
                          placeholder="请输入"
                          {...tailFormItemLayout}
                          validatorName={baseInput4}
                          field="adjustAfter"
                          maxLength={10}
                          min={0}
                          step={0.01}
                          max={9999999.99}
                          style={{ width: 130 }}
                          message="数字"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              {SALARY_ADJUST == "PERFORMANCE_STANDARD" && (
                <div>
                  {/* <Row gutter={20}>
                    <Col span={12}>
                      <div className="flexstart detailbox">
                        <div className="label">原绩效考核基准值：</div>
                        <div className="value">
                          ¥{detailData.performanceStandard}
                        </div>
                        {getFieldDecorator("adjustBefore", {
                          initialValue: detailData.performanceStandard,
                        })}
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="flexend detailbox">
                        <div className="label">调整后：</div>
                        <InputNumber
                          form={form}
                          required={true}
                          allowClear
                          placeholder="请输入"
                          {...tailFormItemLayout}
                          validatorName={baseInput4}
                          field="adjustAfter"
                          maxLength={10}
                          min={0}
                          step={0.01}
                          max={9999999.99}
                          style={{ width: 130 }}
                          message="请输入数字"
                        />
                      </div>
                    </Col>
                  </Row> */}
                  <ChangeKeyValue
                    form={form}
                    keyLabel={"原非系统固定涨薪"}
                    previousValues={nonSystemFixedSalaryIncrease}
                    keyFieldName={"nonSystemFixedSalaryIncreaseBefore"}
                    valueFieldName={"nonSystemFixedSalaryIncrease"}
                    {...tailFormItemLayout}
                  />
                  <ChangeKeyValue
                    form={form}
                    keyLabel={"原个人绩效基准值"}
                    previousValues={personalPerformanceBenchmarks}
                    keyFieldName={"personalPerformanceBenchmarksBefore"}
                    valueFieldName={"personalPerformanceBenchmarks"}
                    {...tailFormItemLayout}
                  />
                  <ChangeKeyValue
                    form={form}
                    keyLabel={"原项目绩效池基准值"}
                    previousValues={projectPerformancePoolBenchmarks}
                    keyFieldName={"projectPerformancePoolBenchmarksBefore"}
                    valueFieldName={"projectPerformancePoolBenchmarks"}
                    {...tailFormItemLayout}
                  />
                </div>
              )}
              <TextFieldInput
                form={this.props.form}
                showLength={true}
                label="调整原因说明"
                placeholder="请输入"
                required={true}
                allowClear
                field="remarks"
                maxLength={500}
                autoSize={{ minRows: 4, maxRows: 5 }}
              />
            </Form>
          </Modal>
        )}
      </>
    );
  }
}

export default Form.create()(SalaryChange);
